import classNames from 'classnames';

export interface SettingsConfig {
    value: string;
    text: string | JSX.Element;
    callback?: () => void;
    transparent?: boolean;
}

export interface SettingsProps {
    options: SettingsConfig[];
}

const SettingsButton = ({ options }: SettingsProps) => {
    return (
        <div className="settings-button-wrapper">
            {options.map((option) => (
                <button
                    id={option.value + '_settings_button'}
                    className={classNames({
                        'settings-buttons': true,
                        'settings-buttons--transparent': !!option.transparent,
                    })}
                    onClick={option.callback}
                >
                    <div className="settings-button--title">{option.value}</div>
                    <div className="">{option.text}</div>
                </button>
            ))}
        </div>
    );
};

export default SettingsButton;
