import { motion, PanInfo } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Check } from '../assets/icons/circleCheck.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/CloseIcon.svg';
import { ReactComponent as GrabberIcon } from '../assets/icons/Grabber.svg';
import { colors } from '../global/variables';
import { formatToDoubleDigit, numberToDollarFormat } from '../utils/utility';
import { useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { logToNative } from '../utils/webview/messages';
const completeSessionMutation = loader(
    '../containers/HomePage/clearCompletedSession.gql'
);

interface ModalProps {
    onClose: () => void;
    chargieId: string;
    kwh: number;
    cost: number;
    duration: number;
}

const SessionCompleteModal: React.FC<ModalProps> = ({
    onClose,
    chargieId,
    kwh,
    cost,
    duration,
}) => {
    const [size, setSize] = useState(0);
    const [draggingUp, setDraggingUp] = useState<boolean>(false);
    const [draggingDown, setDraggingDown] = useState<boolean>(false);
    const [position, setPosition] = useState(0);
    const elementRef = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState(0);
    const [completeSession, { data: completeSessionData }] = useMutation(
        completeSessionMutation
    );
    const handleStart = () => {
        if (elementRef.current) {
            const { height } = elementRef.current.getBoundingClientRect();
            setMaxHeight(height);
        }
        if (size === 0) {
            setDraggingUp(true);
            setDraggingDown(false);
        } else {
            setDraggingUp(false);
            setDraggingDown(true);
        }
    };

    const handleTouchStart = (event: React.PointerEvent<HTMLDivElement>) => {
        setPosition(event.clientY);
        handleStart();
    };

    const handleDragStart = (
        _: MouseEvent | TouchEvent | PointerEvent,
        info: PanInfo
    ) => {
        setPosition(info.point.y);
        handleStart();
    };

    const handleMove = (y: number) => {
        if (draggingUp || draggingDown) {
            if (draggingUp && position > y) setSize(position - y);
            if (draggingDown && position < y)
                setSize(
                    maxHeight - (y - position) > 0
                        ? maxHeight - (y - position)
                        : 0
                );
        }
    };

    const handleTouchMove = (event: React.PointerEvent<HTMLDivElement>) => {
        handleMove(event.clientY);
    };

    const handleDragMove = (
        _: MouseEvent | TouchEvent | PointerEvent,
        info: PanInfo
    ) => {
        handleMove(info.point.y);
    };

    const handleEnd = (y: number) => {
        setDraggingUp(false);
        setDraggingDown(false);
        if (position > y) setSize(10000);
        else if (position < y) setSize(0);
    };

    const handleTouchEnd = (event: React.PointerEvent<HTMLDivElement>) => {
        handleEnd(event.clientY);
    };

    const handleDragEnd = (
        _: MouseEvent | TouchEvent | PointerEvent,
        info: PanInfo
    ) => {
        handleEnd(info.point.y);
    };

    const toggleExpand = () => {
        if (size === 0) {
            setSize(1000);
        } else {
            setSize(0);
        }
    };

    useEffect(() => {
        const attemptComplete = async () => {
            try {
                await completeSession();
            } catch (error) {
                console.error('Error', error);
            }
        };
        //Clears it right away if it can
        attemptComplete();
        //Attempts to clear it again just in case it was too early
        setTimeout(() => attemptComplete(), 5000);
    }, []);

    return (
        <>
            <div className="modal" id="session_complete_modal">
                <div
                    className="modal__background"
                    onClick={onClose}
                    id="session_complete_modal_background"
                ></div>
                <motion.div
                    initial={{ top: '100%' }}
                    animate={{ top: 0 }}
                    className="modal__content-error"
                    onPointerDown={handleTouchStart}
                    onPointerMove={handleTouchMove}
                    onPointerUp={handleTouchEnd}
                    onPanStart={handleDragStart}
                    onPan={handleDragMove}
                    onPanEnd={handleDragEnd}
                    onClick={toggleExpand}
                    id="session_complete"
                >
                    <GrabberIcon
                        className="modal__grabber"
                        id="session_complete_grabber"
                    />

                    <div
                        className="modal__close-container"
                        id="session_complete_close_container"
                    >
                        <div
                            className="modal__close"
                            onClick={onClose}
                            style={{ marginTop: 10 }}
                            id="session_complete_close"
                        >
                            <CloseIcon
                                fill={colors.inactiveWhite}
                                id="session_complete_close_icon"
                            />
                        </div>
                    </div>
                    <Check className="modal__icon" id="session_complete_icon" />
                    <p className="modal__title" id="session_complete_title">
                        Session complete
                    </p>
                    <p
                        className="modal__subtitle-highlighted"
                        id="session_complete_subtitle"
                    >
                        STATION {chargieId}
                    </p>
                    {/* {size} kwh{JSON.stringify(kwh)} cost{JSON.stringify(cost)} dur{JSON.stringify(duration)} */}

                    <div
                        ref={elementRef}
                        style={{ maxHeight: size }}
                        id="session_complete_content"
                        className={
                            draggingUp || draggingDown
                                ? 'modal__extra-content-hidden'
                                : 'modal__extra-content-hidden modal__has-animation'
                        }
                    >
                        <div className="modal__session">
                            <div className="modal__session--details-title">
                                {kwh}
                            </div>
                            <div className="modal__details-bar--kwh">kWh</div>

                            <div className="modal__session--details-title">
                                {numberToDollarFormat(cost)}
                            </div>
                            <div className="modal__details-bar--cost">
                                TOTAL
                            </div>

                            <div className="modal__session--details-title">
                                {Math.floor(duration / 60) > 0
                                    ? Math.floor(duration / 60)
                                    : '00'}
                                :
                                {formatToDoubleDigit(Math.floor(duration % 60))}
                            </div>
                            <div className="modal__details-bar--time">
                                HRS/MIN
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </>
    );
};

export default SessionCompleteModal;
