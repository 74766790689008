import { ReactNode, useState } from 'react';
import { ReactComponent as Arrow } from '../assets/icons/accordionArrow.svg';

interface AccordionProps {
    leftTitle: string;
    rightTitle?: string;
    children: ReactNode;
    id?: string;
}

const Accordion: React.FC<AccordionProps> = ({
    children,
    leftTitle,
    rightTitle,
    id,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            className={isOpen ? 'accordion accordion-open' : 'accordion'}
            onClick={toggleAccordion}
            id={id + '_accordion_container'}
        >
            <div className="accordion-title" id={id + '_accordion_wrapper'}>
                <div>{leftTitle}</div>

                <div className="accordion-action" id={id + '_accordion_title'}>
                    <div id={id + '_accordion_subtext'}>{rightTitle}</div>
                    <Arrow
                        className="accordion-arrow"
                        id={id + '_accordion_icon'}
                    />
                </div>
            </div>
            <div className="accordion-content" id={id + '_accordion_children'}>
                {children}
            </div>
        </div>
    );
};

export default Accordion;
