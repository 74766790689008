import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/RightArrow.svg';
import {
    dismissLowSignalModal,
    hardResetApp,
    setStatusBarLight,
} from '../../utils/webview/messages';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectEmail,
    selectFirstName,
    selectLastName,
    selectRefreshToken,
    setCurrentPage,
    setShowNavigator,
} from '../../appSlice';
import PrimaryButton from '../../components/PrimaryButton';
import { logout } from '../../utils/webview/messages';

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const refreshToken = useAppSelector(selectRefreshToken);
    const firstName = useAppSelector(selectFirstName);
    const lastName = useAppSelector(selectLastName);
    const email = useAppSelector(selectEmail);

    const navigate = useNavigate();

    // Fallback option to reset the app
    const [resetCounter, setResetCounter] = useState(0);
    useEffect(() => {
        if (resetCounter === 10) {
            hardResetApp();
        }
    }, [navigate, resetCounter]);

    useEffect(() => {
        dispatch(setCurrentPage('settings'));
        dispatch(setShowNavigator(true));
        setStatusBarLight(true);
        dismissLowSignalModal();
    }, []);

    const navigateTo = (path: string) => {
        navigate(path, { state: { slide: true } });
    };

    const logoutUser = () => {
        logout();
        navigate('/', { replace: true });
    };

    const loginUser = () => {
        navigate('/login');
    };

    return (
        <div className="container" id="settings_page">
            <div
                id="settings_page_header"
                className="header"
                onClick={() => setResetCounter(resetCounter + 1)}
            >
                <div className="header__content">PROFILE</div>
            </div>
            <div
                className="content"
                style={{ display: 'flex', flexDirection: 'column' }}
                id="settings_page_content"
            >
                {!refreshToken ? (
                    <div
                        className="loginContainer"
                        id="settings_page_login_container"
                    >
                        <PrimaryButton
                            title="Log in"
                            onPress={loginUser}
                            id="settings_page_login_button"
                        />
                        <div className="accountContainer--signup">
                            No account?{' '}
                            <Link
                                id="settings_page_signup_link"
                                className="link"
                                style={{ fontSize: 16 }}
                                to="/signup"
                            >
                                Sign up.
                            </Link>
                        </div>
                    </div>
                ) : (
                    <div className="accountContainer">
                        {(firstName || lastName) && (
                            <div
                                className="accountContainer--name"
                                id="settings_page_name_container"
                            >
                                {firstName} {lastName}
                            </div>
                        )}
                        {email && (
                            <div
                                className="accountContainer--email"
                                id="settings_page_email_container"
                            >
                                {email}
                            </div>
                        )}
                    </div>
                )}
                {refreshToken && (
                    <>
                        <button
                            id="settings_page_account_button"
                            className="settings__button"
                            onClick={() => navigateTo('/account')}
                        >
                            <div id="settings_page_account_text">Account</div>
                            <Arrow id="settings_page_account_icon" />
                        </button>
                        <button
                            id="settings_page_payment_button"
                            className="settings__button"
                            onClick={() => {
                                navigateTo('/payment');
                            }}
                        >
                            <div id="settings_page_payment_text">Payment</div>
                            <Arrow id="settings_page_payment_arrow" />
                        </button>
                        <button
                            id="settings_page_charginghistory_button"
                            className="settings__button"
                            onClick={() => navigateTo('/charginghistory')}
                        >
                            <div id="settings_page_charginghistory_text">
                                Charging history
                            </div>
                            <Arrow id="settings_page_charginghistory_arrow" />
                        </button>
                    </>
                )}
                <button
                    id="settings_privacy_button"
                    className="settings__button"
                    onClick={() => navigateTo('/privacy/security')}
                >
                    Privacy & Security
                    <Arrow id="settings_page_charginghistory_arrow" />
                </button>
                <button
                    className="settings__button"
                    onClick={() => navigateTo('/support')}
                    id="settings_page_support_button"
                >
                    Support
                    <Arrow id="settings_page_support_arrow" />
                </button>
                {refreshToken && (
                    <button
                        className="settings__button"
                        onClick={logoutUser}
                        id="settings_page_logout_button"
                    >
                        Log out
                    </button>
                )}
                {process.env.REACT_APP_ENVIRONMENT === 'production' && (
                    <small className="settings__environment">
                        <div className="footer">
                            <div>Version 4.0.0</div>
                            <div>
                                <a
                                    id="settings_page_terms_link"
                                    className="error-boundary-text"
                                    href={
                                        'https://www.chargie.com/terms-and-conditions'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms and Conditions
                                </a>{' '}
                                |{' '}
                                <a
                                    id="settings_page_privacy_link"
                                    className="error-boundary-text"
                                    href={
                                        'https://www.chargie.com/privacy-policy'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                    </small>
                )}
                {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                    <small className="settings__environment">
                        <div className="footer">
                            <div>
                                Environment: {process.env.REACT_APP_ENVIRONMENT}
                                <br />
                                Host: {window.location.host}
                                <br />
                                Hash: {process.env.REACT_APP_GIT_COMMIT}
                            </div>
                            <div>
                                <a
                                    id="settings_page_terms_link"
                                    className="error-boundary-text"
                                    href={
                                        'https://www.chargie.com/terms-and-conditions'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms and Conditions
                                </a>{' '}
                                |{' '}
                                <a
                                    id="settings_page_privacy_link"
                                    className="error-boundary-text"
                                    href={
                                        'https://www.chargie.com/privacy-policy'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                    </small>
                )}
            </div>
        </div>
    );
};

export default App;
