import React, { useState } from 'react';
import { ReactComponent as Success } from '../../assets/ChargeSuccess.svg';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import PrimaryButton from '../PrimaryButton';

const GuestSuccess: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate('/', { replace: true });
    };

    return (
        <div className="guest-pricing-content" id="guest_success">
            <div className="pricing-details-success">
                <Success id="guest_success_icon" />
                <div
                    className="title"
                    style={{ margin: 20 }}
                    id="guest_success_title"
                >
                    Your session has started
                </div>
                <div id="guest_success_subtext">
                    We'll send you text updates
                </div>
            </div>
            <div className="modal__button">
                <PrimaryButton
                    title={'Got it'}
                    onPress={handleSubmit}
                    id="guest_success_button"
                />
                <button
                    className="payment__back-button hidden"
                    id="guest_success_back_button"
                >
                    Back
                </button>
            </div>
        </div>
    );
};

export default GuestSuccess;
