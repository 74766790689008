import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectActiveChargieId,
    selectChargeAmp,
    selectChargeCost,
    selectChargeDuration,
    selectChargeKw,
    selectChargeKwh,
    selectChargeStatus,
    setActiveChargieId,
    setChargeAmp,
    setChargeCost,
    setChargeDuration,
    setChargeKw,
    setChargeKwh,
    setChargeStartTime,
    setChargeStatus,
    setCurrentPage,
    setFinishedTransaction,
    setInactiveChargieId,
    setPenaltyStation,
    setQueuedChargieId,
    setRequestedChargieId,
    setShowNavigator,
} from '../../appSlice';
import { ReactComponent as FinishCircle } from '../../assets/finishedCircle.svg';
import { ReactComponent as IdleCircle } from '../../assets/idleCircleDark.svg';

import { ReactComponent as Arrow } from '../../assets/icons/accordionArrow.svg';
import { ReactComponent as ChargingIcon } from '../../assets/icons/charging.svg';
import { ReactComponent as IdleIcon } from '../../assets/icons/charging_pause.svg';
import { ReactComponent as QueuedIcon } from '../../assets/icons/QueuedIcon.svg';
import { ReactComponent as RocStat } from '../../assets/icons/roc.svg';
import { ReactComponent as Tooltip } from '../../assets/icons/tooltip.svg';
import { ReactComponent as TouCoins } from '../../assets/icons/touCoins.svg';
import { saveData } from '../../utils/webview/messages';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from '../../components/SecondaryButton';
import { colors } from '../../global/variables';
import { extractStationDetails, StartData } from '../../models/Station';
import { ChargeStatus, RemoteType, SecureStorageKeys } from '../../utils/enums';
import { getTouParsed, TouPricing } from '../../utils/tou';
import { numberToDollarFormat } from '../../utils/utility';
import {
    cancelQueuedStart,
    notifyLowSignalQueue,
    queueRemote,
    setStatusBarLight,
} from '../../utils/webview/messages';
import { differenceInMinutes, parseISO } from 'date-fns';
import { store } from '../../app/store';

const getStationPricing = loader('../../gql/getStationPricing.graphql');
const remoteStopMutation = loader('../../gql/remoteStop.graphql');
const getRecentTransactionsForUserQuery = loader(
    '../ChargingHistoryPage/getRecentTransactionsForUser.graphql'
);
const completeSessionMutation = loader('../HomePage/clearCompletedSession.gql');
const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const [currentPrice, setCurrentPrice] = useState(0);
    const [pricePolicy, setPricePolicy] = useState('kWh');
    const [startDelay, setStartDelay] = useState(0);
    const [penaltyRate, setPenaltyRate] = useState(0);
    const [penaltyDelay, setPenaltyDelay] = useState(0);
    const [accordionIsOpen, setAccordionIsOpen] = useState(false);
    const [touIsOpen, setTouIsOpen] = useState(false);
    const [touData, setTouData] = useState<TouPricing[]>([]);
    const [stationId, setStationId] = useState('');
    const [clusterCount, setClusterCount] = useState(4);
    const [timeDisplay, setTimeDisplay] = useState('00:00:00');
    const [discountString, setDiscountString] = useState<string | null>(null);
    const [stopped, setStopped] = useState(false);
    const [queuedStop, setQueuedStop] = useState(false);
    const navigate = useNavigate();

    const activeChargieId = useAppSelector(selectActiveChargieId);
    const chargeAmp = useAppSelector(selectChargeAmp);
    const chargeKwh = useAppSelector(selectChargeKwh);
    const chargeKw = useAppSelector(selectChargeKw);
    const chargeCost = useAppSelector(selectChargeCost);
    const chargeStatus = useAppSelector(selectChargeStatus);
    const chargeDuration = useAppSelector(selectChargeDuration);

    const [completeSession, { data: completeSessionData }] = useMutation(
        completeSessionMutation
    );

    const { error: getStationPricingError, data: getStationPricingdata } =
        useQuery(getStationPricing, {
            variables: { qrCode: activeChargieId },
            skip: !activeChargieId,
            fetchPolicy: 'network-only',
        });

    const [
        remoteStop,
        {
            data: remoteStopData,
            error: remoteStopError,
            loading: remoteStopLoading,
        },
    ] = useMutation(remoteStopMutation);

    const [getRecentTransactionsForUser, { data: getFinishedTransactionData }] =
        useLazyQuery(getRecentTransactionsForUserQuery, {
            fetchPolicy: 'no-cache',
        });

    useEffect(() => {
        dispatch(setCurrentPage('session'));
        dispatch(setShowNavigator(true));
        setStatusBarLight(true);
    }, []);

    useEffect(() => {
        if (chargeDuration && chargeDuration > 0)
            setTimeDisplay(
                `${formatTimer(Math.floor(chargeDuration / 3600))}:${formatTimer(Math.floor((chargeDuration % 3600) / 60))}:${formatTimer(chargeDuration % 60)}`
            );
        else {
            setTimeDisplay(`00:00:00`);
        }
    }, [chargeDuration]);

    const formatTimer = (time: number) => {
        if (time < 10) {
            return '0' + time.toString();
        }
        return time.toString();
    };

    const getLiveCharge = () => {
        if (!chargeDuration || chargeDuration <= 30) {
            return (
                <div className="session__accordion-title">
                    <RocStat className="session__icon" />
                    <div
                        className="loader"
                        style={{
                            marginRight: 10,
                            height: 14,
                            width: 14,
                        }}
                    />{' '}
                    A &#8226;
                    <div
                        className="loader"
                        style={{
                            marginLeft: 10,
                            marginRight: 10,
                            height: 14,
                            width: 14,
                        }}
                    />{' '}
                    kW
                </div>
            );
        } else {
            return (
                <div className="session__accordion-title">
                    <RocStat className="session__icon" />
                    {chargeAmp} A &#8226; {chargeKw} kW
                </div>
            );
        }
    };

    useEffect(() => {
        if (getStationPricingdata) {
            const startData = extractStationDetails(getStationPricingdata);

            if (!startData) {
                if (!activeChargieId) {
                    navigate('/', { replace: true });
                }
                return;
            }
            setStationId(activeChargieId || '');
            setClusterCount(startData.station.clusterCount || 4);
            if (
                startData.station &&
                startData.station.currentTransactions[0] &&
                startData.station.currentTransactions[0].user
            ) {
                const userData = startData.station.currentTransactions[0].user;
                if (userData.guestUser) {
                    setDiscountString('Guest pass 100% OFF');
                } else if (userData.groupDiscount) {
                    setDiscountString(
                        `${userData.groupDiscount.domainName} ${userData.groupDiscount.discountRate * 100}% OFF`
                    );
                }
            }
            const pricing =
                startData.station.pricing ||
                startData.station.inheritedPricingFrom;
            if (startData.station && pricing) {
                setCurrentPrice(pricing.rate || 0);
                setStartDelay(pricing.startDelay || 0);
                setPenaltyRate(pricing.penaltyRate || 0);
                setPenaltyDelay(pricing.penaltyDelay || 0);
                if (pricing.policy) {
                    if (pricing.policy.toLowerCase() === 'tou') {
                        if (pricing.jsonData) {
                            const touPrices = getTouParsed(
                                startData.todayTouSeason,
                                pricing.jsonData
                            );
                            setTouData(touPrices);
                            const currentPrice = touPrices.filter(
                                (item) => item.active
                            )[0];
                            if (currentPrice)
                                setCurrentPrice(currentPrice.price);
                        }
                    } else if (pricing.policy === 'HOUR')
                        setPricePolicy('Hour');
                }
            }
        } else if (!activeChargieId) {
            navigate('/', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeChargieId, getStationPricingdata, getStationPricingError]);

    const handleAccordionClicked = () => {
        setAccordionIsOpen(!accordionIsOpen);
    };

    const handleTouClicked = () => {
        setTouIsOpen(!touIsOpen);
    };

    const activitySpinner = () => {
        if (chargeStatus === ChargeStatus.CHARGING)
            return <div className="charging" />;
        else if (
            chargeStatus === ChargeStatus.FINISHING ||
            chargeStatus === ChargeStatus.FINISHED
        )
            return <FinishCircle className="session__spinner-style" />;
        else return <IdleCircle className="session__spinner-style" />;
    };

    const statusIcon = () => {
        if (chargeStatus) {
            if (chargeStatus === ChargeStatus.CHARGING)
                return <ChargingIcon className="session__icon--home" />;
            else if (
                chargeStatus === ChargeStatus.SUSPENDEDEV ||
                chargeStatus === ChargeStatus.SUSPENDEDEVSE ||
                chargeStatus === ChargeStatus.IDLE
            )
                return (
                    <IdleIcon
                        fill={colors.activeWhite}
                        stroke={colors.activeWhite}
                        className="session__icon--session"
                        style={{ marginTop: -1 }}
                    />
                );
            else if (chargeStatus === ChargeStatus.PREPARING)
                return <div className="loader" style={{ marginRight: 5 }} />;
            else if (chargeStatus === ChargeStatus.QUEUED)
                return (
                    <QueuedIcon
                        stroke={colors.activeWhite}
                        className="session__icon--home"
                    />
                );
            else return <></>;
        }
    };

    const handleStop = async () => {
        const status = chargeStatus.toUpperCase() as ChargeStatus;

        if (
            stopped ||
            remoteStopLoading ||
            [
                ChargeStatus.FINISHED,
                ChargeStatus.FINISHING,
                ChargeStatus.COMPLETE,
            ].includes(status)
        )
            return;
        try {
            const stationId = activeChargieId;
            if (
                [
                    ChargeStatus.PREPARING,
                    ChargeStatus.PENDING,
                    ChargeStatus.QUEUED,
                ].includes(status)
            ) {
                dispatch(setActiveChargieId(null));
                dispatch(setQueuedChargieId(null));
                if (status === ChargeStatus.QUEUED) {
                    cancelQueuedStart();
                }
                navigate('/', { replace: true });
            } else {
                setStopped(true);
                const result = await remoteStop({
                    variables: {
                        qrCode: stationId,
                    },
                });
                setTimeout(async () => {
                    if (
                        result &&
                        [
                            ChargeStatus.CHARGING,
                            ChargeStatus.SUSPENDEDEV,
                            ChargeStatus.SUSPENDEDEVSE,
                            ChargeStatus.IDLE,
                        ].includes(
                            store
                                .getState()
                                .app.chargeStatus.toUpperCase() as ChargeStatus
                        )
                    ) {
                        if (
                            activeChargieId &&
                            penaltyRate === 0 &&
                            penaltyDelay === 0
                        ) {
                            dispatch(setInactiveChargieId(activeChargieId));
                            const transactionDetails =
                                await getRecentTransactionsForUser();
                            const now = new Date();

                            if (
                                transactionDetails.data
                                    ?.recentTransactionsForUser
                            ) {
                                const lastTransactionForStation =
                                    transactionDetails.data.recentTransactionsForUser.find(
                                        (t: any) =>
                                            t.station?.qrCode ===
                                            activeChargieId
                                    );
                                if (
                                    lastTransactionForStation &&
                                    lastTransactionForStation.startedAt &&
                                    now.toISOString()
                                ) {
                                    const dt1 = parseISO(
                                        lastTransactionForStation.startedAt
                                    );
                                    const dt2 = parseISO(now.toISOString());
                                    const duration = differenceInMinutes(
                                        dt2,
                                        dt1
                                    );
                                    dispatch(
                                        setFinishedTransaction({
                                            kwh:
                                                lastTransactionForStation.wh /
                                                1000,
                                            duration,
                                            cost: lastTransactionForStation.amount,
                                        })
                                    );
                                    await completeSession();
                                }
                            }
                            dispatch(setRequestedChargieId(null));
                            dispatch(setActiveChargieId(null));
                            dispatch(setQueuedChargieId(null));
                            saveData(SecureStorageKeys.ACTIVE_CHARGIE_ID, '');
                            dispatch(setChargeDuration(null));
                            dispatch(setChargeStartTime(null));
                            dispatch(setChargeAmp(0));
                            dispatch(setChargeKwh(0));
                            dispatch(setChargeKw(0));
                            dispatch(setChargeCost(0));
                            dispatch(setChargeStatus(ChargeStatus.LOADING));
                            // dispatch(setPenaltyStation(undefined));
                            navigate('/', { replace: true });
                        } else {
                            dispatch(setChargeStatus(ChargeStatus.FINISHING));
                        }
                    }
                }, 10000);
            }
        } catch (e) {
            const { graphQLErrors } = e as any;
            if (graphQLErrors && graphQLErrors.length === 0) {
                dispatch(setQueuedChargieId(activeChargieId));
                if (activeChargieId && !queuedStop) {
                    setQueuedStop(true);
                    dispatch(setActiveChargieId(null));
                    queueRemote(activeChargieId, RemoteType.REMOTE_STOP);
                } else {
                    setStopped(false);
                }
                return;
            }
        }
    };

    return (
        <div className="container" id="session_page">
            <div className="inset__header" />
            <div className="content" id="session_page_content">
                <div className="session">
                    <div
                        className="session__min-breakdown"
                        id="session_page_status"
                    >
                        {statusIcon()}
                        {chargeStatus}
                    </div>
                    <p id="session_page_station">STATION {activeChargieId}</p>
                    <div className="session__spinner-container">
                        {activitySpinner()}
                        <div
                            className="session__spinner-details"
                            id="session_page_details"
                        >
                            <h1 className="session__kwh">{chargeKwh}</h1>
                            <h3 className="session__kwh_title">kWh</h3>
                            <p>{timeDisplay}</p>
                        </div>
                    </div>
                    <div
                        className="session__accordion"
                        onClick={handleAccordionClicked}
                        id="session_page_loadshare"
                    >
                        <div
                            className="session__accordion-title-container"
                            id="session_page_loadshare_text"
                        >
                            {getLiveCharge()}
                            <Arrow
                                id="session_page_loadshare_icon"
                                className={`session__icon ${accordionIsOpen ? 'session__accordion-icon-opened' : ''}`}
                            />
                        </div>
                        <div
                            id="session_page_loadshare_accordion_content"
                            className={`session__accordion-content ${accordionIsOpen ? '' : 'session__accordion-content-closed'}`}
                        >
                            <div className="session__accordion-content-container">
                                <p className="session__loadshare">
                                    Load Share {clusterCount}:1
                                </p>
                                <p>
                                    Your charging rate will depend on the number
                                    of cars sharing power, the specifications of
                                    your vehicle, and your battery status.
                                </p>
                            </div>
                        </div>
                    </div>
                    {touData.length > 0 && (
                        <div
                            id="session_page_tou_accordion"
                            className="session__accordion"
                            onClick={handleTouClicked}
                        >
                            <div className="session__accordion-title-container">
                                <div className="session__accordion-title">
                                    <TouCoins
                                        id="session_page_tou_coins_icon"
                                        className="session__icon"
                                        stroke={colors.activeWhite}
                                    />
                                    Time-of-use rates
                                </div>
                                <Arrow
                                    id="session_page_tou_arrow"
                                    className={`session__icon ${touIsOpen ? 'session__accordion-icon-opened' : ''}`}
                                />
                            </div>
                            <div
                                className={`session__accordion-content ${touIsOpen ? '' : 'session__accordion-content-closed'}`}
                            >
                                <div className="session__accordion-content-container">
                                    <div className="session__accordion-tou-container">
                                        <div className="session__accordion-tou-times">
                                            {touData.map((item) => {
                                                if (item.active)
                                                    return (
                                                        <p className="pricing__tou-detail-highlight">
                                                            {item.times}
                                                        </p>
                                                    );
                                                else
                                                    return (
                                                        <p className="modal__tou-detail">
                                                            {item.times}
                                                        </p>
                                                    );
                                            })}
                                        </div>
                                        <div className="session__accordion-tou-prices">
                                            {touData.map((item) => {
                                                if (item.active)
                                                    return (
                                                        <p className="pricing__tou-detail-highlight">
                                                            {numberToDollarFormat(
                                                                item.price
                                                            )}
                                                            /kWh
                                                        </p>
                                                    );
                                                else
                                                    return (
                                                        <p className="modal__tou-detail">
                                                            {numberToDollarFormat(
                                                                item.price
                                                            )}
                                                            /kWh
                                                        </p>
                                                    );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="session__toolbox">
                        <div className="session__cost-container">
                            <div className="session__price-container">
                                <p className="session__price-title">
                                    Current price
                                </p>
                                <p className="session__price">
                                    {numberToDollarFormat(currentPrice)}/
                                    {pricePolicy}
                                </p>
                                <div className="session__penalty">
                                    {penaltyRate > 0 && penaltyDelay > 0 && (
                                        <button className="session__penalty-container">
                                            <div className="session__price-title">
                                                {Math.floor(
                                                    (penaltyDelay / 60) * 100
                                                ) / 100}{' '}
                                                hr
                                                {penaltyDelay / 60 > 1 &&
                                                    's'}{' '}
                                                max, penalty fees of{' '}
                                                {numberToDollarFormat(
                                                    penaltyRate
                                                )}
                                                /hr thereafter
                                                <Tooltip
                                                    className="session__icon-small"
                                                    fill={colors.inactiveWhite}
                                                    stroke={
                                                        colors.inactiveWhite
                                                    }
                                                />
                                            </div>
                                        </button>
                                    )}
                                </div>
                                {discountString && (
                                    <div className="session__price-title">
                                        {discountString}
                                    </div>
                                )}
                            </div>
                            <p className="session__current-cost">
                                {numberToDollarFormat(chargeCost)}
                            </p>
                        </div>
                        {chargeStatus !== ChargeStatus.FINISHING &&
                            chargeStatus !== ChargeStatus.FINISHED &&
                            chargeStatus !== ChargeStatus.COMPLETE && (
                                <SecondaryButton
                                    disabled={
                                        stopped ||
                                        remoteStopLoading ||
                                        !!remoteStopData ||
                                        chargeStatus ===
                                            ChargeStatus.FINISHING ||
                                        chargeStatus === ChargeStatus.COMPLETE
                                    }
                                    onPress={handleStop}
                                    title={
                                        remoteStopLoading || stopped
                                            ? 'Stopping'
                                            : 'Stop'
                                    }
                                    loading={
                                        remoteStopLoading ||
                                        !!remoteStopData ||
                                        chargeStatus ===
                                            ChargeStatus.FINISHING ||
                                        chargeStatus === ChargeStatus.COMPLETE
                                    }
                                />
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
