import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectRefreshToken } from '../../appSlice';
import { ReactComponent as ConfirmEmailIcon } from '../../assets/icons/confirmEmail.svg';

const registerUserMutation = loader('../SignupPage/registerUser.graphql');

const CreateAccountEmailPage = () => {
    const refreshToken = useAppSelector(selectRefreshToken);
    const navigate = useNavigate();
    const location = useLocation();

    const [registerUser] = useMutation(registerUserMutation);

    if (refreshToken) navigate('/', { replace: true });

    return (
        <div className="page page--light" id="create_account_email">
            <div
                id="create_account_email_back"
                className="page__close page__close--black"
                onClick={() => navigate('/', { replace: true })}
            >
                <p id="create_account_email_back_icon">&times;</p>
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'center',
                    paddingBottom: 50,
                }}
            >
                <ConfirmEmailIcon width={80} height={80} />
            </div>
            <h1
                style={{ textAlign: 'center', paddingBottom: 16 }}
                id="create_account_email_title"
            >
                Check Inbox
            </h1>
            <p
                style={{ textAlign: 'center' }}
                id="create_account_email_message"
            >
                Verify email by clicking <strong>Confirm Email</strong> in the
                message sent to {location.state?.email}.
            </p>
            <p
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                }}
                id="create_account_email_text"
            >
                Didn't receive an email?&nbsp;
                <span
                    id="create_account_email_resend"
                    className="link link--blue link--initial-size"
                    onClick={() =>
                        registerUser({
                            variables: { email: location.state?.email },
                        })
                    }
                >
                    Resend
                </span>
                .
            </p>
        </div>
    );
};

export default CreateAccountEmailPage;
