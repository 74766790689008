import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectAppleAuthExists,
    selectGooglePlayServicesExists,
    selectRefreshToken,
    selectSubscriptionStarted,
    setShowNavigator,
} from '../../appSlice';
import { ReactComponent as Checkmark } from '../../assets/checkmark.svg';
import { ReactComponent as AppleIcon } from '../../assets/icons/apple.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg';
import { colors, sizes } from '../../global/variables';
import {
    appleSignIn,
    googleSignIn,
    setStatusBarLight,
} from '../../utils/webview/messages';
import { selectIsTracked, setIsTracked } from './slice';

interface SignupFormValues {
    email: string;
    isTracked?: boolean;
}

const registerUserMutation = loader('./registerUser.graphql');

const SignupPage = () => {
    const dispatch = useAppDispatch();
    const refreshToken = useAppSelector(selectRefreshToken);
    const isTracked = useAppSelector(selectIsTracked);
    const googlePlayServicesExists = useAppSelector(
        selectGooglePlayServicesExists
    );
    const appleAuthExists = useAppSelector(selectAppleAuthExists);
    const navigate = useNavigate();
    const subscriptionFlow = useAppSelector(selectSubscriptionStarted);
    const [emailError, setEmailError] = useState('');
    const [registerUser, { data, error, loading }] =
        useMutation(registerUserMutation);

    // To redirect on SSO signup, we have to observe the change on refresh token
    // Initially it should be null -> something
    // However if it's already something, it means you're already logged in
    const [initialRefreshToken] = useState(refreshToken);

    useEffect(() => {
        setStatusBarLight(false);
        dispatch(setShowNavigator(false));
    }, []);

    useEffect(() => {
        if (initialRefreshToken) {
            navigate('/', { replace: true });
        } else if (refreshToken) {
            navigate('/', { replace: true, state: { promptBiometrics: true } });
        }
    }, [initialRefreshToken, navigate, refreshToken]);

    useEffect(() => {
        if (error?.message) {
            if (
                error.message === 'User already exists' ||
                error.message === 'Invalid email'
            ) {
                setEmailError(error.message);
            } else {
                setEmailError('Unexpected error. Please try again');
            }
        }
    }, [error]);

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors: formErrors },
    } = useForm<SignupFormValues>({
        defaultValues: {
            isTracked: !!isTracked,
            email: '',
        },
    });

    const onSubmit = async (data: SignupFormValues) => {
        try {
            await registerUser({
                variables: {
                    email: data.email,
                    isTracked,
                },
            });
        } catch {
            // Errors will be caught in the Apollo errors
        }
    };

    useEffect(() => {
        if (data) {
            navigate('/create-account-email', {
                state: { email: getValues().email },
            });
        } else if (error?.message === 'User already exists') {
            navigate('/login', { state: { email: getValues().email } });
        }
    }, [data, error, getValues, navigate]);

    return (
        <div className="page page--light" id="signup_page">
            <div className="inset__header inset__header--invisible" />
            <div
                id="signup_page_back"
                className="page__close page__close--black"
                onClick={() => navigate('/', { replace: true })}
            >
                <CloseIcon fill={colors.black} id="signup_page_back_icon" />
            </div>
            <h1
                id="signup_page_title"
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'end',
                    paddingBottom: 50,
                }}
            >
                Sign up
            </h1>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <input
                        className="input"
                        type="email"
                        id="email"
                        placeholder="Email"
                        {...register('email', {
                            required: 'Email is required',
                        })}
                    />
                    {formErrors?.email?.message && (
                        <span className="form__error">
                            {formErrors.email.message}
                        </span>
                    )}
                    {error && <span className="form__error">{emailError}</span>}
                </div>
                <div>
                    <label
                        className={classNames({
                            input: true,
                            input__checkbox: true,
                            'input__checkbox--checked': isTracked,
                        })}
                        id="signup_page_tracking_checkbox"
                    >
                        <input
                            type="checkbox"
                            {...register('isTracked', {
                                onChange: (e) =>
                                    dispatch(setIsTracked(e.target.checked)),
                            })}
                        />
                        <div className="form-checkbox-button"></div>

                        {isTracked && (
                            <Checkmark className="form-checkbox-button-checked" />
                        )}
                        <div>
                            Allow Chargie to collect advanced analytics and
                            crash reports to improve our service
                        </div>
                    </label>
                </div>
                <button
                    type="submit"
                    className="button"
                    disabled={loading}
                    id="signup_page_create_button"
                >
                    Create account
                </button>
            </form>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 2,
                    justifyContent: 'space-evenly',
                }}
            >
                {(googlePlayServicesExists || appleAuthExists) &&
                    process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                        <div className="line">
                            <span>Or</span>
                        </div>
                    )}
                {(googlePlayServicesExists || appleAuthExists) &&
                    process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 10,
                            }}
                        >
                            {googlePlayServicesExists && (
                                <button
                                    className="button button--sso"
                                    type="button"
                                    onClick={googleSignIn}
                                    id="signup_page_sso_google"
                                >
                                    <GoogleIcon
                                        id="signup_page_sso_google_icon"
                                        width={sizes.medium}
                                        height={sizes.medium}
                                    />
                                </button>
                            )}
                            {appleAuthExists &&
                                process.env.REACT_APP_ENVIRONMENT !==
                                    'production' && (
                                    <button
                                        id="signup_page_sso_apple"
                                        className="button button--sso"
                                        type="button"
                                        onClick={appleSignIn}
                                    >
                                        <AppleIcon
                                            id="signup_page_sso_apple_icon"
                                            width={sizes.medium}
                                            height={sizes.medium}
                                        />
                                    </button>
                                )}
                        </div>
                    )}
                <p style={{ textAlign: 'center' }}>
                    By creating an account you are agreeing to our{' '}
                    <a
                        id="signup_page_privacy_link"
                        className="link link--blue link--initial-size"
                        href="https://www.chargie.com/privacy-policy"
                    >
                        Privacy Policy
                    </a>
                    .
                </p>
                <p style={{ textAlign: 'center' }}>
                    Already have an account?{' '}
                    <Link
                        id="signup_page_login_link"
                        className="link link--blue"
                        style={{ fontSize: 'initial' }}
                        to="/login"
                    >
                        Log in
                    </Link>
                    .
                </p>
            </div>
        </div>
    );
};

export default SignupPage;
