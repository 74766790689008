import { useState } from 'react';
import { RadioOptions } from './RadioButtons';
import { ReactComponent as Arrow } from '../assets/icons/accordionArrow.svg';
import { numberToDollarFormat } from '../utils/utility';

interface DropdownProps {
    options: RadioOptions[];
    selectedValue: string;
    onChange: (value: string) => void;
    id?: string;
}

const DropdownList: React.FC<DropdownProps> = ({
    options,
    selectedValue,
    onChange,
    id,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (value: string) => {
        setIsOpen(false);
        onChange(value);
    };

    return (
        <div className="dropdown-list" id={id}>
            <div
                className="dropdown-selected"
                onClick={() => setIsOpen(!isOpen)}
                id={id + '_dropdown_selection'}
            >
                <div id={id + '_dropdown_number'}>
                    {numberToDollarFormat(parseInt(selectedValue) / 100)}
                </div>
                <Arrow className={isOpen ? 'rotate180' : 'rotate0'} />
            </div>
            <div
                className={'dropdown-list-container'}
                id={id + '_dropdown_list'}
            >
                <div
                    id={id + '_dropdown_list_content'}
                    className={
                        isOpen
                            ? 'dropdown-list-content'
                            : 'dropdown-list-content-hidden'
                    }
                >
                    {options.map((option, index) => (
                        <label
                            id={index + '_dropdown'}
                            key={index}
                            className="radio-item"
                            onClick={() => handleChange(option.value)}
                        >
                            <div
                                id={index + '_dropdown_text'}
                                className={
                                    selectedValue === option.value
                                        ? 'dropdown-text dropdown-selected'
                                        : 'dropdown-text'
                                }
                            >
                                <div
                                    className=""
                                    id={index + '_dropdown_label'}
                                >
                                    {option.label}
                                </div>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DropdownList;
