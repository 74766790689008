import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectRefreshToken } from '../../appSlice';
import { ReactComponent as ConfirmEmailIcon } from '../../assets/icons/confirmEmail.svg';

const forgotPasswordMutation = loader(
    '../ForgotPasswordPage/forgotPassword.graphql'
);

const ForgotPasswordEmailPage = () => {
    const refreshToken = useAppSelector(selectRefreshToken);
    const navigate = useNavigate();
    const location = useLocation();

    const [forgotPassword] = useMutation(forgotPasswordMutation);

    if (refreshToken) navigate('/', { replace: true });

    return (
        <div className="page page--light" id="forgot_password_email">
            <div
                className="page__close page__close--black"
                onClick={() => navigate('/', { replace: true })}
                id="forgot_password_email_back"
            >
                <p id="forgot_password_email_back_icon">&times;</p>
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'center',
                    paddingBottom: 50,
                }}
            >
                <ConfirmEmailIcon
                    width={80}
                    height={80}
                    id="forgot_password_email_confirm_icon"
                />
            </div>
            <h1 style={{ textAlign: 'center', paddingBottom: 16 }}>
                Check Inbox
            </h1>
            <p style={{ textAlign: 'center' }}>
                We've sent you an email with a link to reset your password.
            </p>
            <p
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                Didn't receive an email?&nbsp;
                <span
                    id="forgot_password_email_link"
                    className="link link--blue link--initial-size"
                    onClick={() =>
                        forgotPassword({
                            variables: {
                                email: location.state?.email,
                                realm: location.state?.realm,
                            },
                        })
                    }
                >
                    Resend
                </span>
                .
            </p>
        </div>
    );
};

export default ForgotPasswordEmailPage;
