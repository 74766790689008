import formatNumberAsCurrency from '../../utils/formatNumberAsCurrency';

const ChargingHistoryItem = ({ ocppSession }: { ocppSession?: any }) => {
    const startAt = new Date(ocppSession.startedAt);
    const dateString = startAt.toLocaleString(undefined, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
    const timeString = startAt.toLocaleString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
    const stopAt = new Date(ocppSession.stoppedAt);
    const duration = stopAt.getTime() - startAt.getTime();
    const totalMinutes: number = Math.floor(duration / (1000 * 60));
    const hours: number = Math.floor(totalMinutes / 60);
    const minutes: number = totalMinutes % 60;
    return (
        <>
            <div
                className="chargingHistoryList"
                id="charging_history_list_item"
            >
                <div>
                    <p
                        className="chargingHistoryList__date-station"
                        id="charging_history_list_text"
                    >
                        {dateString} &#8226; Station{' '}
                        {ocppSession.station.qrCode}
                    </p>
                    <p
                        className="chargingHistoryList__time-kwh "
                        id="charging_history_list_details"
                    >
                        {timeString} &#8226; {hours > 0 ? hours + ' hr ' : ''}{' '}
                        {minutes > 0 ? minutes + ' min ' : '0 min'} &#8226;{' '}
                        {ocppSession.wh
                            ? (ocppSession.wh / 1000).toFixed(0) + ' kWh'
                            : '0 kWh'}
                    </p>
                </div>
                <div>
                    <div className="chargingHistoryList__amount">
                        {formatNumberAsCurrency(ocppSession.amount || 0)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChargingHistoryItem;
