import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectHistory,
    selectModalBackground,
    setHasPaymentMethod,
    setModalBackground,
    setShowNavigator,
} from '../../appSlice';
import { RadioOptions } from '../../components/RadioButtons';
import { Guest } from '../../utils/enums';
import {
    creditCardCheckout,
    hidePaymentModal,
    platformPayCheckout,
    setStatusBarLight,
} from '../../utils/webview/messages';

import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import PrimaryButton from '../../components/PrimaryButton';
import Toggle from '../../components/Toggle';
import {
    calculateTransactionFee,
    isiOS,
    numberToDollarFormat,
} from '../../utils/utility';

import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/Edit.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/Plus.svg';
import { ReactComponent as Arrow } from '../../assets/RightArrow.svg';
import DropdownList from '../../components/DropdownList';
import RadioButtons from '../../components/RadioButtons';
import RadioList from '../../components/RadioList';
import SettingsButton, {
    SettingsConfig,
} from '../../components/SettingsButton';
import { colors } from '../../global/variables';
import { selectPaymentMethod } from '../../guestSlice';
import { logEvent } from 'firebase/analytics';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { store } from '../../app/store';
import { analytics, firebaseSessionId } from '../../utils/firebase';

const addFundsMutation = loader('./addFunds.gql');
const updateUserReloadAmountMutation = loader('./updateReloadAmount.gql');
const addPaymentMutation = loader('./addPaymentMethod.gql');
const getUserDetails = loader('./getUserBalance.gql');

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const modalBackground = useAppSelector(selectModalBackground);
    const [balance, setBalance] = useState(0);
    const [autoReload, setAutoReload] = useState(false);
    const [isPaymentOpen, setIsPaymentOpen] = useState(false);
    const [isReloadOpen, setIsReloadOpen] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [cardDetails, setCardDetails] = useState('Add payment');
    const paymentMethod = useAppSelector(selectPaymentMethod);
    const location = useLocation();
    const history = useAppSelector(selectHistory);

    const [currentPaymentMethod, setCurrentPaymentMethod] =
        useState(paymentMethod);
    const { data: getUserData, refetch: refetchUserData } = useQuery(
        getUserDetails,
        { fetchPolicy: 'no-cache' }
    );

    const [reloadAmount, setReloadAmount] = useState('3000');
    const [addFundsAmount, setAddFundsAmount] = useState('3000');

    const reloadOptions: RadioOptions[] = [
        { label: '$20.00', value: '2000' },
        { label: '$30.00', value: '3000' },
        { label: '$40.00', value: '4000' },
        { label: '$50.00', value: '5000' },
        { label: '$60.00', value: '6000' },
        { label: '$70.00', value: '7000' },
    ];
    const handleReloadChange = async (value: string) => {
        setReloadAmount(value);
        try {
            await updateReload({
                variables: {
                    user: {
                        reloadAmount: parseInt(value),
                        reload: autoReload,
                    },
                },
            });
        } catch (e) {
            // alert(e);
        }
    };

    const handleAddFundsChange = (value: string) => {
        setAddFundsAmount(value);
    };

    const [addPayment, { error: addPaymentError, loading: addPaymentLoading }] =
        useMutation(addPaymentMutation);

    const [
        addFunds,
        { data: addFundsData, error: addFundsError, loading: addFundsLoading },
    ] = useMutation(addFundsMutation);

    const [
        updateReload,
        { data: reloadData, error: reloadError, loading: reloadLoading },
    ] = useMutation(updateUserReloadAmountMutation);

    const paymentOptions: RadioOptions[] = [
        {
            label: isiOS() ? 'Apple Pay' : 'Google Pay',
            value: Guest.PLATFORM_PAY,
            callback: () => {
                setIsPaymentOpen(false);
                platformPayCheckout();
            },
        },
        {
            label: 'Credit card',
            value: Guest.CREDIT_CARD,
            callback: () => {
                setIsPaymentOpen(false);
                creditCardCheckout();
            },
        },
    ];

    useEffect(() => {
        if (paymentMethod !== currentPaymentMethod) {
            addPayment({
                variables: {
                    paymentMethodId: paymentMethod,
                },
            })
                .then(() => {
                    setCurrentPaymentMethod(paymentMethod);
                    refetchUserData();
                    dispatch(setHasPaymentMethod(true));
                })
                .catch(() => {});
        }
    }, [addPayment, currentPaymentMethod, paymentMethod, refetchUserData]);

    const handleAddFundsSubmit = async () => {
        try {
            await addFunds({
                variables: {
                    amount: parseInt(addFundsAmount),
                },
            });
            dispatch(setHasPaymentMethod(true));
            refetchUserData();
            onClose();
        } catch (e) {
            const currentDate = new Date();
            const token = store.getState().app.accessToken;
            logEvent(analytics, 'MA_4.0_handleAddFundsSubmit_error', {
                time: currentDate.toLocaleString(),
                sessionId: firebaseSessionId,
                userId: jwtDecode<JwtPayload>(token || '').sub,
                error: e,
            });
        }
    };

    const toggleAutoReload = async () => {
        const val = autoReload;
        try {
            const result = await updateReload({
                variables: {
                    user: {
                        reload: !val,
                        reloadAmount: parseInt(reloadAmount),
                    },
                },
            });
            if (result.data) {
                setAutoReload(!val);
            }
        } catch (e) {
            const currentDate = new Date();
            const token = store.getState().app.accessToken;
            logEvent(analytics, 'MA_4.0_toggleAutoReload_error', {
                time: currentDate.toLocaleString(),
                sessionId: firebaseSessionId,
                userId: jwtDecode<JwtPayload>(token || '').sub,
                error: e,
            });
        }
    };

    const paymentSettings: SettingsConfig[] = [
        {
            value: cardDetails,
            text: addPaymentLoading ? (
                <div
                    className="loader"
                    style={{
                        height: 14,
                        width: 14,
                    }}
                />
            ) : cardDetails === 'Add payment' ? (
                <PlusIcon />
            ) : (
                <EditIcon />
            ),
            callback: () => !addPaymentLoading && setIsPaymentOpen(true),
        },
        {
            value:
                reloadAmount && parseInt(reloadAmount) > 0
                    ? `Reload amount ${numberToDollarFormat(parseInt(reloadAmount) / 100)}`
                    : `Reload amount`,
            text: <EditIcon />,
            callback: () => openReload(),
        },
    ];

    const openReload = () => {
        setIsReloadOpen(true);
    };

    const openAddFunds = () => {
        setIsAddOpen(true);
    };

    const onClose = () => {
        setIsPaymentOpen(false);
        setIsReloadOpen(false);
        setIsAddOpen(false);
        dispatch(setModalBackground(false));
        hidePaymentModal();
    };

    useEffect(() => {
        dispatch(setShowNavigator(false));
        setStatusBarLight(true);
    }, []);

    useEffect(() => {
        if (getUserData) {
            setBalance(getUserData.getStripeCustomer.balance / -100);
            setAutoReload(getUserData.loggedInUser.reload);
            setReloadAmount(
                (getUserData.loggedInUser.reloadAmount * 100).toString()
            );
            if (getUserData.loggedInUser.paymentMethod) {
                const payment = getUserData.loggedInUser.paymentMethod;

                if (getUserData.loggedInUser.paymentMethod.wallet === null) {
                    setCardDetails(
                        `x${payment.last4} Exp ${payment.exp_month}/${payment.exp_year}`
                    );
                } else if (
                    getUserData.loggedInUser.paymentMethod.wallet.type ===
                    'apple_pay'
                ) {
                    setCardDetails(`Apple Pay`);
                } else if (
                    getUserData.loggedInUser.paymentMethod.wallet.type ===
                    'google_pay'
                ) {
                    setCardDetails(`Google Pay`);
                }
            }
        }
    }, [getUserData]);

    return (
        <motion.div
            className="container--navless"
            initial={
                history.length > 0 && history[1] === '/settings'
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            id="payment_page"
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="payment_page_header">
                <div className="header__content">
                    <div
                        className="header__back"
                        onClick={() => {
                            navigate(-1);
                        }}
                        id="payment_page_back"
                    >
                        <BackIcon id="payment_page_back_icon"></BackIcon>
                    </div>
                    PAYMENT
                </div>
            </div>
            <div className="content" id="payment_page_content">
                <div className="payment-funds-container">
                    <div
                        className="payment-account-container"
                        id="payment_page_account_container"
                    >
                        <div className="payment-account">
                            <div
                                className="payment-account-title"
                                id="payment_page_account_title"
                            >
                                Account balance
                            </div>
                            <div className="payment-account-content">
                                {balance !== 0
                                    ? numberToDollarFormat(balance)
                                    : '$0.00'}
                            </div>
                        </div>
                        {cardDetails !== 'Add payment' && (
                            <button
                                className="add-funds"
                                onClick={openAddFunds}
                                id="payment_page_add_funds"
                            >
                                Add funds
                            </button>
                        )}
                    </div>
                    <div
                        className="payment-account-container"
                        style={{ marginTop: 30 }}
                    >
                        <div
                            className="payment-account-title"
                            id="payment_page_autoreload_title"
                        >
                            Autoreload
                        </div>
                        <Toggle
                            onChange={() => toggleAutoReload()}
                            value={autoReload}
                            loading={reloadLoading}
                            id="payment_page"
                        />
                    </div>
                </div>
                <div className="payment-title" style={{ marginTop: 40 }}>
                    Payment defaults
                </div>
                <SettingsButton options={paymentSettings} />
                {addPaymentError && (
                    <span className="form__error">
                        {addPaymentError.message}
                    </span>
                )}
                <button
                    className="settings__button"
                    onClick={() => navigate('/paymentHistory')}
                    style={{ marginTop: 20 }}
                    id="payment_page_button_payment_history"
                >
                    <div>Payment history</div>
                    <Arrow />
                </button>
            </div>
            {modalBackground && (
                <div className="modal">
                    <div
                        className="modal__background"
                        onClick={onClose}
                        id="payment_page_modal_background"
                    />
                </div>
            )}
            {isAddOpen && (
                <div className="modal">
                    <div
                        className="modal__background"
                        onClick={onClose}
                        id="payment_page_modal_background"
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content"
                    >
                        <div
                            className="modal__close-container"
                            id="payment_page_add_payment_modal"
                        >
                            <div
                                className="modal__close"
                                onClick={onClose}
                                id="payment_page_add_payment_close"
                            >
                                <CloseIcon
                                    fill={colors.inactiveWhite}
                                    id="payment_page_add_payment_close_icon"
                                />
                            </div>
                        </div>
                        <div
                            className="title"
                            style={{ marginBottom: 20 }}
                            id="payment_page_add_payment_title"
                        >
                            Payment
                        </div>
                        <div className="reload-container">
                            <div
                                className="reload-title"
                                id="payment_page_add_payment_choose"
                            >
                                Choose amount
                            </div>
                            <DropdownList
                                options={reloadOptions}
                                selectedValue={addFundsAmount}
                                onChange={handleAddFundsChange}
                            />

                            <div
                                className="purchase-summary"
                                id="payment_page_purchase_summary"
                            >
                                <p
                                    className="purchase-summary-title"
                                    id="payment_page_purchase_summary_title"
                                >
                                    PURCHASE SUMMARY
                                </p>
                                <div
                                    className="purchase-summary-rates"
                                    style={{ marginTop: 10 }}
                                >
                                    <div>Account funds</div>
                                    <div>
                                        {numberToDollarFormat(
                                            parseInt(addFundsAmount) / 100
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="purchase-summary-rates"
                                    style={{ marginTop: 10 }}
                                >
                                    <div>Processing fee</div>
                                    <div>
                                        {numberToDollarFormat(
                                            calculateTransactionFee(
                                                parseInt(addFundsAmount) / 100
                                            )
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="purchase-total"
                                    style={{ marginTop: 10 }}
                                >
                                    <div>Total</div>
                                    <div>
                                        {numberToDollarFormat(
                                            parseInt(addFundsAmount) / 100 +
                                                calculateTransactionFee(
                                                    parseInt(addFundsAmount) /
                                                        100
                                                )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ margin: 20 }} />
                        <PrimaryButton
                            title="Add"
                            onPress={handleAddFundsSubmit}
                            disabled={addFundsLoading}
                            id="payment_page_add_funds_button"
                        />
                    </motion.div>
                    <div className="modal__content--inset" />
                </div>
            )}
            {isPaymentOpen && (
                <div className="modal">
                    <div
                        className="modal__background"
                        onClick={onClose}
                        id="payment_page_payment_modal_background"
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content"
                        id="payment_page_payment"
                    >
                        <div className="modal__close-container">
                            <div
                                className="modal__close"
                                onClick={onClose}
                                id="payment_page_payment_close"
                            >
                                <CloseIcon
                                    fill={colors.inactiveWhite}
                                    id="payment_page_payment_close_icon"
                                />
                            </div>
                        </div>
                        <div
                            className="title"
                            style={{ marginBottom: 20 }}
                            id="payment_page_payment_title"
                        >
                            Add payment
                        </div>
                        <RadioButtons
                            options={paymentOptions}
                            selectedValue={''}
                            onChange={() => {}}
                        />
                        <div style={{ margin: 20 }} />
                    </motion.div>
                    <div className="modal__content--inset" />
                </div>
            )}
            {isReloadOpen && (
                <div className="modal" id="payment_page_reload">
                    <div
                        className="modal__background"
                        onClick={onClose}
                        id="payment_page_reload_background"
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content"
                    >
                        <div className="modal__close-container">
                            <div
                                className="modal__close"
                                onClick={onClose}
                                id="payment_page_reload_close"
                            >
                                <CloseIcon
                                    fill={colors.inactiveWhite}
                                    id="payment_page_reload_close_icon"
                                />
                            </div>
                        </div>
                        <div
                            className="title"
                            style={{ marginBottom: 20 }}
                            id="payment_page_reload_title"
                        >
                            Choose amount
                        </div>
                        <RadioList
                            options={reloadOptions}
                            onChange={handleReloadChange}
                            selectedValue={reloadAmount}
                        />
                    </motion.div>
                    <div className="modal__content--inset" />
                </div>
            )}
        </motion.div>
    );
};

export default App;
