import { ReactComponent as LogoIcon } from '../../assets/icons/logoPill.svg';
import { openAppStore } from '../../utils/webview/messages';

const ForceUpdatePage = () => {
    return (
        <div className="page page--light" id="force_update_page">
            <div
                style={{
                    flex: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
                id="force_update_page_content"
            >
                <div
                    style={{
                        width: 104,
                        height: 104,
                        borderRadius: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <LogoIcon id="force_update_page_logo" />
                </div>
                <h2 style={{ marginTop: 45, marginBottom: 15 }}>
                    Update Required
                </h2>
                <p style={{ textAlign: 'center' }}>
                    To continue using the app and experience new features,
                    update to the latest version.
                </p>
            </div>
            <div style={{ flex: 1 }}>
                <button
                    className="button"
                    onClick={openAppStore}
                    id="force_update_page_button"
                >
                    Update to Continue
                </button>
            </div>
        </div>
    );
};

export default ForceUpdatePage;
