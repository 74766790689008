import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface AppState {
    error?: string | null;
}

const initialState: AppState = {
    error: undefined,
};

export const slice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setLoginError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const { setLoginError } = slice.actions;
export const actions = slice.actions;

export const selectLoginError = (state: RootState) => state.login.error;

export default slice.reducer;
