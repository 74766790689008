import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ConfirmEmailIcon } from '../../assets/icons/confirmEmail.svg';
import withLoggedInRedirect from '../../components/HOC/withLoggedInRedirect';

const forgotPasswordMutation = loader(
    '../ForgotPasswordPage/forgotPassword.graphql'
);

const CreateAccountEmailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [resetPassword] = useMutation(forgotPasswordMutation);

    return (
        <div className="page page--light" id="password_expire_page">
            <div
                className="page__close page__close--black"
                onClick={() => navigate('/', { replace: true })}
                id="password_expire_page_back"
            >
                <p id="password_expire_page_icon">&times;</p>
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'center',
                    paddingBottom: 50,
                }}
            >
                <ConfirmEmailIcon
                    width={80}
                    height={80}
                    id="password_expire_page_confirm_icon"
                />
            </div>
            <h1
                style={{ textAlign: 'center', paddingBottom: 16 }}
                id="password_expire_page_link_expired"
            >
                Link expired
            </h1>
            <p style={{ textAlign: 'center' }}>
                Request a new password reset link below.
            </p>
            <div style={{ flex: 1, alignContent: 'center' }}>
                <button
                    id="password_expire_page_reset_button"
                    className="button"
                    onClick={() =>
                        resetPassword({
                            variables: {
                                email: location.state.email,
                                realm: location.state.realm,
                            },
                        })
                    }
                >
                    Resend link
                </button>
            </div>
            <div style={{ flex: 1 }} />
        </div>
    );
};

export default withLoggedInRedirect(CreateAccountEmailPage);
