import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import mapboxgl, { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectLatitude, selectLongitude } from '../../appSlice';
import { BoundingBoxType, lockIconSvg } from '../../containers/MapPage/MapPage';
import { extractPropertiesData } from '../../models/PropertyMap';
import { logToNative } from '../../utils/webview/messages';
import Station from '../../models/Station';

const getPropertyLocations = loader(
    '../../containers/MapPage/getProperties.graphql'
);

const Mapbox: React.FC = () => {
    const [lat, setLat] = useState(34.064251);
    const [long, setLong] = useState(-118.360565);
    const [showUserMarker, setShowUserMarker] = useState(false);
    const [boundingBox, setBoundingBox] = useState<BoundingBoxType | null>(
        null
    );
    const [mapElement, setMapElement] = useState<Map | null>(null);
    const mapContainer = useRef<HTMLDivElement>(null);
    const longitude = useAppSelector(selectLongitude);
    const latitude = useAppSelector(selectLatitude);
    const navigate = useNavigate();

    const {
        error: getPropertiesError,
        data: getPropertiesData,
        loading: getPropertiesLoading,
        refetch: refetchPropertiesData,
    } = useQuery(getPropertyLocations, {
        variables: {
            filter: {
                minLong: boundingBox?.minLong,
                maxLong: boundingBox?.maxLong,
                minLat: boundingBox?.minLat,
                maxLat: boundingBox?.maxLat,
            },
            stationFilter: {},
        },
        skip: !boundingBox,
        fetchPolicy: 'cache-and-network',
    });

    const generateNewMarker = ({
        lat,
        lng,
        stations,
    }: {
        lng: number;
        lat: number;
        stations: [Station];
    }) => {
        try {
            if (mapElement) {
                let publicTotal = 0;
                stations.map((station) =>
                    station.stationType === 'PUBLIC' ? publicTotal++ : null
                );
                const divElement = document.createElement('div');
                if (publicTotal > 0) {
                    divElement.innerHTML = publicTotal.toString();
                    divElement.className = 'map__marker-property';
                } else {
                    divElement.innerHTML = lockIconSvg;
                    divElement.className = 'map__marker-property-locked';
                }
                new mapboxgl.Marker(divElement)
                    .setLngLat([lng, lat])
                    .setDraggable(false)
                    .addTo(mapElement);
            }
        } catch {
            logToNative('could not generate location');
        }
    };

    useEffect(() => {
        mapboxgl.accessToken =
            'pk.eyJ1Ijoienpzb2Z0d2FyZWVuZ2luZWVyaW5nIiwiYSI6ImNsc2F1dmNrbTA3bngyam50azdncGVqZnQifQ.Rv-pmEPSE3qDiSyuBVS5gg';
        if (mapContainer.current) {
            if (longitude && latitude) {
                setLat(latitude);
                setLong(longitude);
                setShowUserMarker(true);
            }
            const newMap = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/zzsoftwareengineering/clu1hclp5021q01oid5uh6g4i',
                center: [longitude || long, latitude || lat],
                zoom: 13,
                maxZoom: 30,
                minZoom: 5,
            });
            setMapElement(newMap);

            return () => newMap.remove();
        }
    }, []);

    useEffect(() => {
        if (mapElement) {
            mapElement.on('load', function () {
                const divElement = document.createElement('div');
                divElement.className = 'map__marker-user';
                if (showUserMarker)
                    new mapboxgl.Marker(divElement)
                        .setLngLat([long, lat])
                        .addTo(mapElement);
                const boundingArr = mapElement.getBounds();
                const bbBox: BoundingBoxType = {
                    minLong: boundingArr._sw.lng,
                    minLat: boundingArr._sw.lat,
                    maxLong: boundingArr._ne.lng,
                    maxLat: boundingArr._ne.lat,
                };
                setBoundingBox(bbBox);
            });
            if (getPropertiesData && !getPropertiesLoading) {
                const propertyData = extractPropertiesData(getPropertiesData);
                propertyData.map((marker) => {
                    if (marker.stations.length > 0) {
                        generateNewMarker({
                            lat: marker.latitude,
                            lng: marker.longitude,
                            stations: marker.stations,
                        });
                    }
                    return 0;
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        long,
        lat,
        getPropertiesData,
        getPropertiesLoading,
        mapElement,
        showUserMarker,
    ]);

    return (
        <div className="map">
            <div
                className="map__home-overlay"
                onClick={() => navigate('/map', { state: { slide: true } })}
                id="map__home-overlay"
            />
            <div
                style={{ width: '100%', height: 150, overflow: 'hidden' }}
                ref={mapContainer}
            />
        </div>
    );
};

export default Mapbox;
