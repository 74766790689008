import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { RecentStation } from '../../models/User';

export interface AppState {
    chargingHistory?: any;
    recentStations?: [RecentStation] | undefined;
}

const initialState: AppState = {
    chargingHistory: undefined,
    recentStations: undefined,
};

export const slice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setChargingHistory: (state, action: PayloadAction<any>) => {
            state.chargingHistory = action.payload;
        },
        setRecentStations: (
            state,
            action: PayloadAction<[RecentStation] | undefined>
        ) => {
            state.recentStations = action.payload;
        },
    },
});

export const { setChargingHistory, setRecentStations } = slice.actions;
export const actions = slice.actions;

export const selectChargingHistory = (state: RootState) =>
    state.home.chargingHistory;
export const selectRecentStations = (state: RootState) =>
    state.home.recentStations;

export default slice.reducer;
