import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appReducer from '../appSlice';
import homeReducer from '../containers/HomePage/slice';
import signupReducer from '../containers/SignupPage/slice';
import guestReducer from '../guestSlice';
import loginReducer from '../containers/LoginPage/slice';
import { init } from '../utils/webview/messages';

export const store = configureStore({
    reducer: {
        app: appReducer,
        home: homeReducer,
        signup: signupReducer,
        guest: guestReducer,
        login: loginReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

init();
