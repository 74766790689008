import { useMutation } from '@apollo/client';
import axios from 'axios';
import { loader } from 'graphql.macro';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectDesiredRFID,
    selectModalBackground,
    selectRefreshToken,
    selectRfidError,
    setCurrentPage,
    setDesiredRFID,
    setModalBackground,
    setRfidError,
    setShowNavigator,
} from '../../appSlice';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { ScannerType } from '../../utils/enums';
import {
    hideScanner,
    maximizeScanner,
    minimizeScanner,
    notifyLowSignalQueue,
    notifyLowSignalScan,
    setStatusBarLight,
    showScanner,
} from '../../utils/webview/messages';

const addRfidMutation = loader('./addRfid.gql');

const ScanRfidPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const [inputValue, setInputValue] = useState(params.station);
    const [buttonDisable, setButtonDisable] = useState(true);
    // const [isFocused, setIsFocused] = useState(false);
    const desiredRFID = useAppSelector(selectDesiredRFID);
    const inputRef = useRef<HTMLInputElement>(null);
    const modalBackground = useAppSelector(selectModalBackground);
    const rfidError = useAppSelector(selectRfidError);
    const [isLowSignal, setIsLowSignal] = useState(false);
    const [notifyLowSignal, setNotifyLowSignal] = useState(false);

    const [
        addRfidToUser,
        { data: addRfidData, error: addRfidError, loading: addRfidLoading },
    ] = useMutation(addRfidMutation);
    const refreshToken = useAppSelector(selectRefreshToken);

    const inputLimit = 10;
    const navigate = useNavigate();

    // When the input box is focused, we want to minize the scanner
    const handleFocus = () => {
        // setIsFocused(true);
        minimizeScanner();
        dispatch(setShowNavigator(false));
    };

    //When input is unfocused, we want to show the full scanner and update UI
    const handleBlur = () => {
        // setIsFocused(false);
        maximizeScanner();
        dispatch(setShowNavigator(true));
        if (inputValue && !buttonDisable) addRFID(inputValue);
    };

    //Checks for low signal
    useEffect(() => {
        showScanner(ScannerType.RFID);
        dispatch(setRfidError(false));
        setStatusBarLight(true);
        dispatch(setCurrentPage('scan'));
        dispatch(setDesiredRFID(undefined));
        if (!refreshToken) return;
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://auth.chargieengine.com/health'
                );
                if (response) {
                    setIsLowSignal(false);
                    setNotifyLowSignal(false);
                }
            } catch (error: any) {
                console.log(error);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        if (/^[0-9\b]+$/.test(input) || input === '') {
            if (e.target.value.length <= inputLimit)
                setInputValue(e.target.value);
        }
        if (e.target.value.length >= inputLimit) setButtonDisable(false);
        else setButtonDisable(true);
    };

    const handleInputSubmit = async () => {
        if (inputValue) addRFID(inputValue);
    };

    useEffect(() => {
        if (desiredRFID && rfidError === false) {
            addRFID(desiredRFID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [desiredRFID]);

    const addRFID = async (desiredId: string) => {
        setButtonDisable(true);
        if (!refreshToken) {
            dispatch(setRfidError(false));
            navigate('/', { replace: true });
        } else {
            try {
                await addRfidToUser({
                    variables: {
                        input: {
                            rfid: parseInt(desiredId),
                        },
                    },
                });
                hideScanner();
            } catch (e) {
                const { graphQLErrors } = e as any;
                dispatch(setDesiredRFID(undefined));
                if (graphQLErrors && graphQLErrors.length === 0) {
                    notifyLowSignalQueue(true);
                    dispatch(setRfidError(false));
                    navigate(-1);
                    return;
                }
                setButtonDisable(false);
                dispatch(setRfidError(true));
                navigate(-1);
            }
        }
    };

    const handleDismiss = () => {
        notifyLowSignalScan(false);
        notifyLowSignalQueue(false);
        dispatch(setModalBackground(false));
    };

    useEffect(() => {
        if (addRfidData) navigate('/rfid', { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addRfidData]);

    return (
        <div className="container noNav" id="rfid_scan_page">
            <div className="header" id="rfid_scan_page_header">
                <div
                    id="rfid_scan_page_back"
                    className="header__back"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <BackIcon id="rfid_scan_page_back_icon"></BackIcon>
                </div>
                <div className="header__content">SCAN RFID</div>
            </div>
            {modalBackground && (
                <div className="modal">
                    <div
                        id="rfid_scan_page_modal"
                        className="modal__background"
                        onClick={handleDismiss}
                    />
                </div>
            )}
            <div className="content" id="rfid_scan_page_content">
                <div className="scan__manual-container">
                    <p className="scan__manual-text">Or enter RFID</p>
                    <div className="scan__manual-content">
                        <input
                            ref={inputRef}
                            className="scan__input"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder="RFID token"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            type="number"
                        />
                        <button
                            id="rfid_scan_page_scan_button"
                            className="scan__button"
                            disabled={buttonDisable}
                            onClick={handleInputSubmit}
                        >
                            {addRfidLoading ? 'Adding' : 'Add'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScanRfidPage;
