import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import withLoggedInRedirect from '../../components/HOC/withLoggedInRedirect';

interface ForgotPasswordFormValues {
    email: string;
    realm: string;
}

const forgotPasswordMutation = loader('./forgotPassword.graphql');

const ForgotPasswordPage = () => {
    const navigate = useNavigate();

    const [forgotPassword, { data, error, loading }] = useMutation(
        forgotPasswordMutation
    );

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
    } = useForm<ForgotPasswordFormValues>();

    const onSubmit = async (data: ForgotPasswordFormValues) => {
        try {
            await forgotPassword({
                variables: { ...data, realm: 'pcse' },
            });
            navigate('/forgot-password-email', {
                state: { email: data.email, realm: 'pcse' },
            });
        } catch {
            // Errors will be caught in the Apollo errors
        }
    };

    return (
        <div className="page page--light" id="forgot_password">
            <div className="inset__header inset__header--invisible" />
            <div
                className="page__close page__close--black"
                onClick={() => navigate('/', { replace: true })}
                id="forgot_password_back"
            >
                <p id="forgot_password_back_icon">&times;</p>
            </div>
            <h1
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'end',
                    paddingBottom: 50,
                }}
                id="forgot_password_header"
            >
                Forgot Password
            </h1>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <p>
                    No worries, enter your email and we'll send over password
                    reset instructions.
                </p>
                <div>
                    <input
                        className="input"
                        id="email"
                        type="email"
                        placeholder="Email address"
                        autoComplete="email"
                        required
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /^[^@]+@[^@]+\.[^@]+$/,
                                message: 'Please provide a valid email address',
                            },
                        })}
                    />
                    {formErrors?.email?.message && (
                        <span className="form__error">
                            {formErrors.email.message}
                        </span>
                    )}
                </div>
                {error?.message && (
                    <span className="form__error">{error.message}</span>
                )}
                <button
                    type="submit"
                    className="button"
                    disabled={loading}
                    id="forgot_password_button"
                >
                    Reset password
                </button>
            </form>
            <div style={{ flex: 2 }} />
        </div>
    );
};

export default withLoggedInRedirect(ForgotPasswordPage);
