import { useMutation, useQuery } from '@apollo/client';
import { motion, PanInfo } from 'framer-motion';
import { loader } from 'graphql.macro';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    selectActiveChargieId,
    selectRequestedChargieId,
    setActiveChargieId,
    setChargeStatus,
    setErrorType,
    setQueuedChargieId,
    setRequestedChargieId,
} from '../appSlice';
import { ReactComponent as GrabberIcon } from '../assets/icons/Grabber.svg';
import { extractStationDetails, StartData } from '../models/Station';
import {
    ChargeStatus,
    RemoteErrorTypes,
    RemoteType,
    SecureStorageKeys,
    StartMethod,
} from '../utils/enums';
import { getTouParsed } from '../utils/tou';
import { numberToDollarFormat } from '../utils/utility';
import {
    notifyLowSignalQueue,
    queueRemote,
    saveData,
} from '../utils/webview/messages';
import PricingBreakdown from './PricingDetails/PricingBreakdown';
import PricingTitle from './PricingDetails/PricingTitle';
import { ReactComponent as CloseIcon } from '../assets/icons/CloseIcon.svg';
import { colors } from '../global/variables';
import PrimaryButton from './PrimaryButton';

interface ModalProps {
    onClose: () => void;
    qrCode: string;
}

const remoteStartMutation = loader('../gql/remoteStart.graphql');
const getStationPricing = loader('../gql/getStationPricing.graphql');

const RecentDetailsModal: React.FC<ModalProps> = ({ onClose, qrCode }) => {
    const dispatch = useAppDispatch();
    const activeChargieId = useAppSelector(selectActiveChargieId);
    const [stationData, setStationData] = useState<StartData | null>(null);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [usable, setUsable] = useState(true);
    const [status, setStatus] = useState<string | null>(null);
    const requestedChargieId = useAppSelector(selectRequestedChargieId);
    const navigate = useNavigate();
    const [size, setSize] = useState(0);
    const [draggingUp, setDraggingUp] = useState<boolean>(false);
    const [draggingDown, setDraggingDown] = useState<boolean>(false);
    const [position, setPosition] = useState(0);
    const elementRef = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState(0);
    const [currentPrice, setCurrentPrice] = useState<number | null>(null);
    const [pricingPolicy, setPricingPolicy] = useState('kWh');
    const [notifyLowSignal, setNotifyLowSignal] = useState(false);
    const [buttonText, setButtonText] = useState('Start');
    const handleStart = () => {
        if (elementRef.current) {
            const { height } = elementRef.current.getBoundingClientRect();
            setMaxHeight(height);
        }
        if (size === 0) {
            setDraggingUp(true);
            setDraggingDown(false);
        } else {
            setDraggingUp(false);
            setDraggingDown(true);
        }
    };

    const handleTouchStart = (event: React.PointerEvent<HTMLDivElement>) => {
        setPosition(event.clientY);
        handleStart();
    };

    const handleDragStart = (
        _: MouseEvent | TouchEvent | PointerEvent,
        info: PanInfo
    ) => {
        setPosition(info.point.y);
        handleStart();
    };

    const handleMove = (y: number) => {
        if (draggingUp || draggingDown) {
            if (draggingUp && position > y) setSize(position - y);
            if (draggingDown && position < y)
                setSize(
                    maxHeight - (y - position) > 0
                        ? maxHeight - (y - position)
                        : 0
                );
        }
    };

    const handleTouchMove = (event: React.PointerEvent<HTMLDivElement>) => {
        handleMove(event.clientY);
    };

    const handleDragMove = (
        _: MouseEvent | TouchEvent | PointerEvent,
        info: PanInfo
    ) => {
        handleMove(info.point.y);
    };

    const handleEnd = (y: number) => {
        setDraggingUp(false);
        setDraggingDown(false);
        if (position > y) setSize(10000);
        else if (position < y) setSize(0);
    };

    const handleTouchEnd = (event: React.PointerEvent<HTMLDivElement>) => {
        handleEnd(event.clientY);
    };

    const handleDragEnd = (
        _: MouseEvent | TouchEvent | PointerEvent,
        info: PanInfo
    ) => {
        handleEnd(info.point.y);
    };

    const toggleExpand = () => {
        if (size === 0) {
            setSize(10000);
        } else {
            setSize(0);
        }
    };

    const [
        remoteStart,
        {
            data: remoteStartData,
            error: remoteStartError,
            loading: remoteStartLoading,
        },
    ] = useMutation(remoteStartMutation);

    const {
        error: getStationPricingError,
        data: getStationPricingdata,
        loading: getStationPricingLoading,
    } = useQuery(getStationPricing, {
        variables: { qrCode: qrCode },
        skip: !qrCode,
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (activeChargieId) setButtonDisable(true);
    }, [activeChargieId]);

    const handleSubmit = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        try {
            event.stopPropagation();
            setButtonDisable(true);
            await remoteStart({
                variables: {
                    qrCode: qrCode,
                    method: StartMethod.RECENT,
                },
            });
            dispatch(setActiveChargieId(qrCode));
            dispatch(setRequestedChargieId(undefined));
            saveData(SecureStorageKeys.ACTIVE_CHARGIE_ID, qrCode);
            navigate('/session', { replace: true });
        } catch (e) {
            const { graphQLErrors } = e as any;
            if (graphQLErrors?.length) {
                dispatch(setActiveChargieId(null));
                saveData(SecureStorageKeys.ACTIVE_CHARGIE_ID, '');
            }
            dispatch(setErrorType(graphQLErrors[0].errorType));
            setButtonDisable(false);
            onClose();
            navigate('/', { replace: true });
        }
    };

    useEffect(() => {
        if (getStationPricingdata) {
            const startData = extractStationDetails(getStationPricingdata);
            setStationData(startData);
            const status = startData.station.ocppStatus.toLowerCase();
            if (
                startData.station.pricing ||
                startData.station.inheritedPricingFrom
            ) {
                const pricing =
                    startData.station.pricing ||
                    startData.station.inheritedPricingFrom;

                setCurrentPrice(pricing.rate || 0);

                if (pricing.policy.toLowerCase() === 'tou') {
                    if (pricing.jsonData) {
                        const touPrices = getTouParsed(
                            startData.todayTouSeason,
                            pricing.jsonData
                        );
                        const currentPrice = touPrices.filter(
                            (item) => item.active
                        )[0];
                        if (currentPrice) setCurrentPrice(currentPrice.price);
                    }
                } else if (pricing.policy === 'HOUR') setPricingPolicy('Hour');
            }
            if (activeChargieId) {
                setUsable(false);
                setButtonText('Start');
                setStatus('');
                return;
            }
            if (startData.station.lastCommunication) {
                const now = new Date();
                const lastMessage = new Date(
                    startData.station.lastCommunication
                );
                const difference = Math.abs(
                    now.getTime() - lastMessage.getTime()
                );
                // checks if status is available & last ping time was less than 3 minutes
                if (
                    status === 'unavailable' ||
                    status === 'faulted' ||
                    difference > 180000
                ) {
                    setUsable(false);
                    setStatus('UNAVAILABLE');
                    setButtonText('Try another station');
                    return;
                }
            }
            if (status === 'unavailable' || status === 'faulted') {
                setUsable(false);
                setStatus('UNAVAILABLE');
                setButtonText('Try another station');
            } else if (
                status !== 'available' &&
                status !== 'preparing' &&
                status !== 'finishing'
            ) {
                setUsable(false);
                setStatus('IN-USE');
                setButtonText('Try another station');
            } else if (startData.station.isExclusive) {
                setStatus('ASSIGNED');
            } else {
                setUsable(true);
            }
        } else if (getStationPricingError) {
            if (getStationPricingError.networkError) {
                setNotifyLowSignal(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getStationPricingError, getStationPricingdata]);

    if (!getStationPricingdata && !getStationPricingError) {
        return <></>;
    }

    const queueRecentStation = () => {
        dispatch(setQueuedChargieId(qrCode));
        dispatch(setActiveChargieId(qrCode));
        notifyLowSignalQueue(true);
        queueRemote(qrCode, RemoteType.REMOTE_START);
        dispatch(setChargeStatus(ChargeStatus.QUEUED));
        onClose();
        navigate('/session', { replace: true });
    };

    return (
        <div className="modal" id="recent_details_modal">
            <div
                className="modal__background"
                onClick={onClose}
                id="recent_details_modal_background"
            ></div>
            {!notifyLowSignal && (
                <motion.div
                    id="recent_details_modal_container"
                    initial={{ top: '100%' }}
                    animate={{ top: 0 }}
                    className="modal__content"
                    onPointerDown={handleTouchStart}
                    onPointerMove={handleTouchMove}
                    onPointerUp={handleTouchEnd}
                    onPanStart={handleDragStart}
                    onPan={handleDragMove}
                    onPanEnd={handleDragEnd}
                    onClick={toggleExpand}
                >
                    <GrabberIcon
                        className="modal__grabber"
                        id="recent_details_modal_grabber"
                    />
                    <div className="modal__close-container">
                        <p
                            className="modal__close"
                            onClick={onClose}
                            id="recent_details_modal_close"
                        >
                            &times;
                        </p>
                    </div>
                    <div>
                        {!usable && status !== '' && (
                            <div className="modal__header--row">
                                <div
                                    className="modal__unavailable--header"
                                    id="recent_details_modal_status"
                                >
                                    {status}
                                </div>
                            </div>
                        )}
                        <PricingTitle station={stationData?.station} />
                        {usable && (
                            <div className="recent__button-container">
                                <button
                                    className={'recent__start'}
                                    disabled={buttonDisable}
                                    onClick={handleSubmit}
                                    id="recent_details_modal_start"
                                >
                                    {buttonText}
                                </button>
                            </div>
                        )}
                        {!usable && (
                            <div className="recent__button-container">
                                <button
                                    className={'recent__start--disabled'}
                                    id="recent_details_modal_disabled"
                                >
                                    {buttonText}
                                </button>
                            </div>
                        )}
                        <p
                            className="pricing__price-details-title"
                            id="recent_details_modal_text"
                        >
                            CURRENT PRICE
                        </p>
                        {currentPrice !== null && (
                            <p
                                className="pricing__price-title"
                                id="recent_details_price_detail"
                            >
                                {numberToDollarFormat(currentPrice)}/
                                {pricingPolicy}
                            </p>
                        )}
                        <div
                            ref={elementRef}
                            style={{ maxHeight: size }}
                            className={
                                draggingUp || draggingDown
                                    ? 'modal__extra-content-hidden'
                                    : 'modal__extra-content-hidden modal__has-animation'
                            }
                        >
                            {stationData?.station && (
                                <PricingBreakdown
                                    station={stationData.station}
                                    todayTouSeason={stationData?.todayTouSeason}
                                />
                            )}
                        </div>
                    </div>
                </motion.div>
            )}
            {notifyLowSignal && (
                <motion.div
                    initial={{ top: '100%' }}
                    animate={{ top: 0 }}
                    className="modal__content"
                    id="recent_stations_modal_low_signal"
                >
                    <div className="modal__close-container">
                        <div
                            className="modal__close"
                            onClick={onClose}
                            id="recent_stations_modal_low_signal_close"
                        >
                            <CloseIcon
                                fill={colors.inactiveWhite}
                                id="recent_stations_modal_low_signal_close_icon"
                            />
                        </div>
                    </div>

                    <p
                        className="modal__title"
                        style={{ marginBottom: 10 }}
                        id="recent_stations_modal_low_signal_title"
                    >
                        You're offline
                    </p>
                    <p
                        className="modal__message"
                        style={{ textAlign: 'left', marginBottom: 40 }}
                        id="recent_stations_modal_low_signal_text"
                    >
                        Plug in, hit start, and we'll save your request until
                        you're back online
                    </p>
                    <PrimaryButton
                        title="Start"
                        onPress={queueRecentStation}
                        id="recent_stations_modal_low_signal_start"
                    />
                </motion.div>
            )}
        </div>
    );
};

export default RecentDetailsModal;
