import React, { Component, ErrorInfo, ReactNode } from 'react';
import TabNavigator from './components/Navigator';
import { ReactComponent as LogoIcon } from './assets/logo.svg';
import { analytics, firebaseSessionId } from './utils/firebase';
import { store } from './app/store';
import { logEvent } from 'firebase/analytics';
import { JwtPayload, jwtDecode } from 'jwt-decode';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): State {
        const token = store.getState().app.accessToken;
        try {
            logEvent(analytics, 'MA_4.0_error', {
                error_message: error.message,
                error_stack: error.stack,
                sessionId: firebaseSessionId,
                userId: jwtDecode<JwtPayload>(token || '').sub,
            });
        } catch {
            logEvent(analytics, 'MA_4.0_error', {
                error_message: error.message,
                error_stack: error.stack,
                sessionId: firebaseSessionId,
            });
        }

        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    handleDismiss = () => {
        this.setState({ hasError: false });
    };

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="main">
                    <div className="inset__header" />
                    <div className="home__header" style={{ marginLeft: 30 }}>
                        <LogoIcon style={{ marginTop: 15 }} />
                    </div>
                    <div className="error-boundary">
                        <div className="error-boundary-text" style={{}}>
                            An unexpected error occured. Please try restarting
                            the app or reach out to{' '}
                            <a
                                className="error-boundary-text"
                                href={'https://chargie.com/support'}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                support
                            </a>
                            .
                        </div>
                    </div>
                    <TabNavigator
                        override={true}
                        closeOverride={this.handleDismiss}
                    />
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
