import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { setShowNavigator } from '../../appSlice';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';

import { colors } from '../../global/variables';
import SubscribePay from './SubscribePay';
import SubscribeStart from './SubscribeStart';

const SubscribePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setShowNavigator(false));
    }, [dispatch]);

    return (
        <div
            className="page page--fullscreen"
            style={{ background: '#FFF' }}
            id="subscribe_page"
        >
            <div
                id="subscribe_page_back"
                className="page__close"
                onClick={() => navigate('/', { replace: true })}
            >
                <CloseIcon
                    fill={colors.inactiveWhite}
                    id="subscribe_page_back_icon"
                />
            </div>
            <div className="payment__content--right">
                <Routes>
                    <Route path="/" element={<SubscribeStart />} />
                    <Route path="/pay" element={<SubscribePay />} />
                </Routes>
            </div>
        </div>
    );
};

export default SubscribePage;
