import { ReactComponent as Checkmark } from '../assets/checkmark.svg';
import { ReactComponent as ErrorIcon } from '../assets/icons/RedError.svg';
export interface RadioOptions {
    label: string;
    value: string;
    labelSub?: string;
    subText?: string;
    callback?: () => void;
    errorMessage?: string;
}

interface RadioProps {
    options: RadioOptions[];
    selectedValue: string;
    inverse?: boolean;
    onChange: (value: string) => void;
}

const RadioButtons: React.FC<RadioProps> = ({
    options,
    selectedValue,
    inverse,
    onChange,
}) => {
    const handleChange = (value: string) => {
        onChange(value);
    };

    return (
        <div className={inverse ? 'radio-buttons inverse' : 'radio-buttons'}>
            {options.map((option, index) => (
                <div
                    className={'radio-button-container'}
                    key={index}
                    id={option.value + '_radio_option'}
                >
                    <label
                        key={index}
                        id={option.value + '_radio_label'}
                        className={
                            option.subText
                                ? 'payment-radio' +
                                  (selectedValue === option.value
                                      ? ' selected'
                                      : '')
                                : 'payment-radio__notext' +
                                  (selectedValue === option.value
                                      ? ' selected'
                                      : '')
                        }
                        onClick={option.callback}
                    >
                        <input
                            type="radio"
                            value={option.value}
                            checked={selectedValue === option.value}
                            onChange={() => handleChange(option.value)}
                            className="payment-radio-input"
                            id={option.value + '_radio_input'}
                        />
                        <span className="payment-radio-button"></span>

                        {selectedValue === option.value && (
                            <Checkmark
                                className="payment-radio-button-checked"
                                id={option.value + '_radio_error_checkmark'}
                            />
                        )}
                        <div
                            className="radio-text"
                            id={option.value + '_radio_text'}
                        >
                            <div
                                className="radio-title"
                                id={option.value + '_radio_title'}
                            >
                                <div id={option.value + '_radio_label'}>
                                    {option.label}
                                </div>
                                <div id={option.value + '_radio_label_sub'}>
                                    {option.labelSub}
                                </div>
                            </div>
                            {option.subText && (
                                <div
                                    className="radio-subtext"
                                    id={option.value + '_radio_subtext'}
                                >
                                    {option.subText}
                                </div>
                            )}
                        </div>
                    </label>
                    {option.errorMessage && (
                        <div
                            className="radio-error"
                            id={option.value + '_radio_error_text'}
                        >
                            <ErrorIcon
                                className="radio-error-icon"
                                id={option.value + '_radio_error_icon'}
                            />
                            {option.errorMessage}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default RadioButtons;
