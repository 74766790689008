import React from 'react';
import { colors, sizes } from '../global/variables';

interface ButtonProps {
    title: string;
    onPress: () => void;
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    id?: string;
}

const SecondaryButton: React.FC<ButtonProps> = ({
    title,
    onPress,
    disabled,
    className,
    loading,
    id,
    ...props
}) => {
    const defaultButton: React.CSSProperties = {
        backgroundColor: 'rgb(0,0,0,0)',
        flex: 1,
        padding: '10px 20px',
        margin: '10px 0',
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        color: disabled ? colors.inactiveWhite : colors.activeWhite,
        fontSize: sizes.small,
        fontFamily: 'Lufga',
        border: `1px solid ${colors.activeWhite}`,
    };

    return (
        <button
            style={defaultButton}
            onClick={onPress}
            className={className}
            {...props}
            id={id}
        >
            {loading === true && (
                <div className="loader" style={{ position: 'absolute' }} />
            )}{' '}
            {title}
        </button>
    );
};

export default SecondaryButton;
