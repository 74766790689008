import classNames from 'classnames';
import { motion } from 'framer-motion';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import {
    selectAccessToken,
    selectBiometricsRefreshToken,
    selectDeviceToken,
    selectRefreshToken,
} from '../../appSlice';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { Environment } from '../../utils/enums';
import { hardResetApp, setEnvironment } from '../../utils/webview/messages';

const AdminPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = useAppSelector(selectAccessToken);
    const deviceToken = useAppSelector(selectDeviceToken);
    const refreshToken = useAppSelector(selectRefreshToken);
    const biometricsRefreshToken = useAppSelector(selectBiometricsRefreshToken);
    useEffect(() => {
        try {
            if (accessToken) {
                const decoded = jwtDecode<{ sub: string; role: string }>(
                    accessToken
                );
                if (decoded.role !== 'Admin') {
                    navigate('/account');
                }
            }
        } catch {
            navigate('/account');
        }
    }, [accessToken, navigate]);

    if (!accessToken) return null;

    const environments = [
        {
            key: Environment.LOCAL,
            disabled:
                window.location.hostname === 'localhost' ||
                window.location.hostname === '10.0.2.2',
        },
        {
            key: Environment.DEVELOP,
            disabled: window.location.hostname.includes('develop.chargie'),
        },
        {
            key: Environment.STAGING,
            disabled: window.location.hostname.includes('staging.chargie'),
        },
        {
            key: Environment.PRODUCTION,
            disabled:
                window.location.hostname.includes('static.chargie') ||
                window.location.hostname.includes('mobile.chargie'),
        },
    ];

    return (
        <motion.div
            className="container"
            initial={
                location.state && location.state.slide === true
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="header">
                <div
                    className="header__back"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <BackIcon></BackIcon>
                </div>
                <div className="header__content">ADMIN</div>
            </div>
            <div className="page">
                <h3>Switch Environments</h3>
                {environments.map((env) => (
                    <button
                        style={{ marginBottom: 5 }}
                        className={classNames({
                            button: true,
                            'button--disabled': env.disabled,
                        })}
                        disabled={env.disabled}
                        onClick={() => setEnvironment(env.key)}
                    >
                        {env.key}
                    </button>
                ))}
                <hr />
                <h3>Diagnostics</h3>
                <button
                    style={{ marginBottom: 5 }}
                    className="button"
                    onClick={() => hardResetApp()}
                >
                    Reset Data
                </button>
                <h4>Device ID</h4>
                <input
                    value={deviceToken || 'N/A'}
                    onClick={(e) => {
                        e.currentTarget.select();
                        navigator.clipboard.writeText(e.currentTarget.value);
                    }}
                />
                <h4>Access Token</h4>
                <input
                    value={accessToken || 'N/A'}
                    onClick={(e) => {
                        e.currentTarget.select();
                        navigator.clipboard.writeText(e.currentTarget.value);
                    }}
                />
                <h4>Refresh Token</h4>
                <input
                    value={refreshToken || 'N/A'}
                    onClick={(e) => {
                        e.currentTarget.select();
                        navigator.clipboard.writeText(e.currentTarget.value);
                    }}
                />
                <h4>Biometrics Refresh Token</h4>
                <input
                    value={biometricsRefreshToken || 'N/A'}
                    onClick={(e) => {
                        e.currentTarget.select();
                        navigator.clipboard.writeText(e.currentTarget.value);
                    }}
                />
            </div>
        </motion.div>
    );
};

export default AdminPage;
