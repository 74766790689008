import { useEffect, useState } from 'react';

interface ToggleProps {
    value?: boolean;
    onChange?: (value: boolean) => void;
    loading?: boolean;
    id?: string;
}
const Toggle = ({
    value = false,
    onChange,
    loading = false,
    id,
}: ToggleProps) => {
    const [checked, setChecked] = useState<boolean>(!!value);

    useEffect(() => setChecked(!!value), [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (loading) return;
        const newValue = event.target.checked;
        let result = onChange ? onChange(newValue) : newValue;
        if (typeof result === 'boolean') {
            setChecked(result);
        }
    };

    return (
        <label className="toggle" id={id + '_toggle'}>
            <input
                type="checkbox"
                className="toggle-input"
                checked={checked}
                onChange={handleChange}
            />
            <div
                id={id}
                className={checked ? 'toggle-slider-checked' : 'toggle-slider'}
            >
                <div className="toggle-ball" />
            </div>
        </label>
    );
};

export default Toggle;
