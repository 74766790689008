import React, { useEffect, useState } from 'react';
import {
    NavigationType,
    useLocation,
    useNavigate,
    useNavigation,
    useNavigationType,
} from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/RightArrow.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { motion } from 'framer-motion';
import { selectHistory, setShowNavigator } from '../../appSlice';
import { store } from '../../app/store';
import { useAppSelector } from '../../app/hooks';

const App: React.FC = () => {
    const navigate = useNavigate();
    const history = useAppSelector(selectHistory);

    useEffect(() => {
        store.dispatch(setShowNavigator(false));
        if (window && window.$zoho && window.$zoho.salesiq.floatbutton) {
            window.$zoho.salesiq.floatbutton.visible('hide');
            window.$zoho.salesiq.floatwindow.visible('hide');
        }
    }, []);

    return (
        <motion.div
            id="support_page"
            className="container"
            initial={
                history.length > 0 && history[1] === '/settings'
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="support_page_header">
                <div className="header__content">
                    <div
                        id="support_page_back"
                        className="header__back"
                        onClick={() => {
                            navigate('/settings');
                        }}
                    >
                        <BackIcon id="support_page_back_icon"></BackIcon>
                    </div>
                    SUPPORT
                </div>
            </div>
            <div className="page">
                <button
                    className="settings__button"
                    id="support_page_faqs_button"
                    style={{ marginTop: 10 }}
                    onClick={() =>
                        navigate('/faqs', { state: { slide: true } })
                    }
                >
                    <div>FAQs</div>
                    <Arrow />
                </button>
                <button
                    className="settings__button"
                    id="support_page_contact_button"
                    onClick={() =>
                        navigate('/contact', { state: { slide: true } })
                    }
                >
                    <div>Contact us</div>
                    <Arrow />
                </button>
            </div>
        </motion.div>
    );
};

export default App;
