import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';

import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import {
    formatDuration,
    formatToDoubleDigit,
    numberToDollarFormat,
    roundDown,
} from '../../utils/utility';
import { StripeTransactionTypes } from '../../utils/enums';

const getStripeTransaction = loader('./getStripeTransaction.graphql');

const App: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [accountBalance, setAccountBalance] = useState(0);
    const [amount, setAmount] = useState(0);
    const [title, setTitle] = useState('');
    const [duration, setDuration] = useState('');
    const [startingBalance, setStartingBalance] = useState<number | null>(null);
    const [endingBalance, setEndingBalance] = useState<number | null>(null);
    const [total, setTotal] = useState(0);
    const [totalkWh, setTotalkWh] = useState(0);
    const [date, setDate] = useState('');
    const [ccFee, setCCFee] = useState<number | null>(null);
    const [minFee, setMinFee] = useState<number | null>(null);
    const [baseCost, setBaseCost] = useState<number | null>(null);
    const [sessionTotal, setSessionTotal] = useState(0);
    const [getData, { loading, error, data }] =
        useLazyQuery(getStripeTransaction);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const queryParams: Record<string, string> = {};
        params.forEach((value, key) => {
            queryParams[key] = value;
        });
        setTitle(queryParams?.type || '');
        if (queryParams?.type === StripeTransactionTypes.SESSION) {
            getData({ variables: { filter: queryParams?.tId } });
        }
        setTotal(parseInt(queryParams?.total));
        if (queryParams?.eBalance) {
            setEndingBalance(parseInt(queryParams?.eBalance));
            setStartingBalance(
                parseInt(queryParams?.total) - parseInt(queryParams?.eBalance)
            );
        }
        if (queryParams?.date) {
            setDate(queryParams.date);
        }
        if (queryParams?.base) {
            setBaseCost(parseInt(queryParams.base) / 100);
        }
        if (queryParams?.fee) {
            setCCFee(parseInt(queryParams?.fee) / 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data && data.ocppSession) {
            const endTime = new Date(data.ocppSession.disconnectedAt);
            const startTime = new Date(data.ocppSession.connectedAt);
            setDuration(
                formatDuration(endTime.getTime() - startTime.getTime())
            );
            if (
                data.ocppSession.ocppSubSessions &&
                data.ocppSession.pricingPolicy.policy === 'TOU'
            ) {
                setTotalkWh(
                    data.ocppSession.ocppSubSessions.reduce(
                        (acc: number, item: any) => acc + item.totalWh,
                        0
                    )
                );
            } else setTotalkWh(data.ocppSession.wh);
            let calculatedTotal = 0;
            if (data.ocppSession.amount) {
                calculatedTotal = data.ocppSession.amount;
                setAmount(data.ocppSession.amount);
            }
            if (
                data.ocppSession.adjustmentProcessingFee &&
                data.ocppSession.adjustmentProcessingFee.amount
            ) {
                setCCFee(data.ocppSession.adjustmentProcessingFee.amount);
            }
            if (
                data.ocppSession.adjustmentMinFee &&
                data.ocppSession.adjustmentMinFee.amount
            ) {
                setMinFee(data.ocppSession.adjustmentMinFee.amount);
            }
            if (
                data.ocppSession.adjustmentDiscount &&
                data.ocppSession.adjustmentDiscount.amount
            ) {
                calculatedTotal += data.ocppSession.adjustmentDiscount.amount;
            }
            if (
                data.ocppSession.adjustmentPenalty &&
                data.ocppSession.adjustmentPenalty.amount
            ) {
                calculatedTotal += data.ocppSession.adjustmentPenalty.amount;
            }
            setSessionTotal(calculatedTotal);
        }
    }, [data]);

    const createLineItem = () => {
        if (data && data.ocppSession) {
            if (data.ocppSession.pricingPolicy.policy === 'TOU') {
                return (
                    <div>
                        {data.ocppSession.ocppSubSessions.map((item: any) => {
                            return (
                                <div className="pricing-detail-item">
                                    <div
                                        className="pricing-detail-item-header"
                                        id="payment_detail_tou_header"
                                    >
                                        <div id="payment_detail_tou_header_kwh">
                                            {roundDown(item.totalWh / 1000)}kWh
                                            @{' '}
                                            {numberToDollarFormat(
                                                item.rate / 100
                                            )}
                                            /kWh
                                        </div>
                                        <div id="payment_detail_tou_cost">
                                            {numberToDollarFormat(
                                                item.cost / 100
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="pricing-detail-item-subheader"
                                        id="payment_detail_tou_subheader"
                                    >
                                        TOU Rates
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            } else if (data.ocppSession.pricingPolicy.policy === 'HOUR') {
                return (
                    <div className="pricing-detail-item">
                        <div
                            className="pricing-detail-item-header"
                            id="payment_detail_hour_header"
                        >
                            <div id="payment_detail_header_hour_cost">
                                {duration} @{' '}
                                {numberToDollarFormat(
                                    data.ocppSession.pricingPolicy.rate
                                )}
                                /hr
                            </div>
                            <div id="payment_detail_hour_cost">
                                {numberToDollarFormat(data.ocppSession.amount)}
                            </div>
                        </div>
                        <div
                            className="pricing-detail-item-subheader"
                            id="payment_detail_hour_subheader"
                        >
                            Fixed pricing
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="pricing-detail-item">
                        <div
                            className="pricing-detail-item-header"
                            id="payment_detail_kwh_header"
                        >
                            <div id="payment_detail_kwh_kwh">
                                {roundDown(data.ocppSession.wh / 1000)}kWh @{' '}
                                {numberToDollarFormat(
                                    data.ocppSession.pricingPolicy.rate
                                )}
                                /kWh
                            </div>
                            <div id="payment_detail_kwh_cost">
                                {numberToDollarFormat(data.ocppSession.amount)}
                            </div>
                        </div>
                        <div
                            className="pricing-detail-item-subheader"
                            id="payment_detail_kwh_subheader"
                        >
                            Fixed pricing
                        </div>
                    </div>
                );
            }
        }
        return <></>;
    };

    const createDiscountItem = () => {
        if (data && data.ocppSession && data.ocppSession.adjustmentDiscount) {
            if (data.ocppSession.guestSession) {
                return <></>;
            } else if (data.ocppSession.groupDiscount) {
                return (
                    <div
                        className="pricing-detail-item-header pricing-detail-border"
                        style={{ paddingTop: 10 }}
                        id="payment_detail_discount"
                    >
                        <div id="payment_detail_discount_header">
                            {data.ocppSession.groupDiscount.domainName}{' '}
                            {data.ocppSession.groupDiscount.discountRate * 100}%
                            OFF
                        </div>
                        <div id="payment_detail_discount_cost">
                            (
                            {numberToDollarFormat(
                                data.ocppSession.adjustmentDiscount.amount
                            )}
                            )
                        </div>
                    </div>
                );
            }
        }
    };

    const createDelayItem = () => {
        if (
            data &&
            data.ocppSession &&
            data.ocppSession.pricingPolicy.startDelay &&
            data.ocppSession.pricingPolicy.startDelay > 0
        ) {
            return (
                <div className="pricing-detail-item" id="payment_detail_delay">
                    <div
                        className="pricing-detail-item-header"
                        id="payment_detail_delay_header"
                    >
                        <div id="payment_detail_delay_kwh">
                            {data.ocppSession.delayedMeterStart -
                                data.ocppSession.meterStart || 0}
                            kWh @ Free
                        </div>
                        <div id="payment_detail_delay_cost">
                            {numberToDollarFormat(0)}
                        </div>
                    </div>
                    <div
                        className="pricing-detail-item-subheader"
                        id="payment_detail_subheader"
                    >
                        {roundDown(
                            data.ocppSession.pricingPolicy.startDelay / 60
                        )}
                        hr free
                    </div>
                </div>
            );
        }
        return <></>;
    };

    const createPenaltyItem = () => {
        if (data && data.ocppSession && data.ocppSession.adjustmentPenalty) {
            const adjustment = data.ocppSession.adjustmentPenalty.amount;
            const rate = data.ocppSession.pricingPolicy.penaltyRate | 1;
            const delay = data.ocppSession.pricingPolicy.penaltyDelay | 0;

            return (
                <div
                    className="pricing-detail-item"
                    id="payment_detail_penalty"
                >
                    <div
                        className="pricing-detail-item-header"
                        id="payment_detail_penalty_header"
                    >
                        <div id="payment_detail_penalty_kwh">
                            {formatToDoubleDigit(Math.ceil(adjustment / rate))}
                            :00 @{' '}
                            {numberToDollarFormat(
                                data.ocppSession.pricingPolicy.penaltyRate
                            )}
                            /hr
                        </div>
                        <div id="payment_detail_penalty_cost">
                            {numberToDollarFormat(adjustment)}
                        </div>
                    </div>
                    <div
                        className="pricing-detail-item-subheader"
                        id="payment_detail_penalty_subheader"
                    >
                        Penalty fee{' '}
                        {numberToDollarFormat(
                            data.ocppSession.pricingPolicy.penaltyRate
                        )}
                        /hr, after {roundDown(delay / 60)}{' '}
                        {delay / 60 > 1 ? 'hrs' : 'hr'}
                    </div>
                </div>
            );
        }
        return <></>;
    };

    const createSummaryDetails = () => {
        if (
            title === StripeTransactionTypes.AUTO ||
            title === StripeTransactionTypes.RELOAD
        ) {
            return (
                <div>
                    <div
                        className="pricing-detail-item"
                        id="payment_detail_reload"
                    >
                        <div
                            className="pricing-detail-item-header"
                            id="payment_detail_reload_header"
                        >
                            <div id="payment_detail_reload_title">
                                Account reload
                            </div>
                            <div id="payment_detail_reload_cost">
                                {numberToDollarFormat(total / -100)}
                            </div>
                        </div>
                    </div>
                    {ccFee && ccFee > 0 ? (
                        <div className="pricing-detail-item pricing-detail-border">
                            <div className="pricing-detail-item-header">
                                <div id="payment_detail_reload_fee">
                                    Processing fee
                                </div>

                                <div id="payment_detail_reload_fee_amount">
                                    {numberToDollarFormat(ccFee)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <div className="pricing-detail-item pricing-detail-border">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_reload_cost">
                                Amount due
                            </div>
                            <div id="payment_detail_reload_cost_amount">
                                {numberToDollarFormat(
                                    total / -100 + (ccFee || 0)
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (
            [
                StripeTransactionTypes.DEBIT,
                StripeTransactionTypes.CREDIT,
                StripeTransactionTypes.REFUND,
            ].includes(title as StripeTransactionTypes)
        ) {
            return (
                <div>
                    <div
                        className="pricing-detail-item"
                        id="payment_detail_refund"
                    >
                        <div
                            className="pricing-detail-item-header"
                            id="payment_detail_refund_header"
                        >
                            <div id="payment_detail_refund_title">
                                {title === StripeTransactionTypes.REFUND
                                    ? 'Amount refunded'
                                    : title}
                            </div>
                            <div id="payment_detail_refund_amount">
                                {numberToDollarFormat(Math.abs(total / 100))}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (
            startingBalance &&
            endingBalance &&
            endingBalance / -100 > 0
        ) {
            return (
                <div>
                    <div className="pricing-detail-item">
                        <div
                            className="pricing-detail-item-header"
                            id="payment_detail_balance"
                        >
                            <div id="payment_detail_balance_title">
                                Starting balance
                            </div>
                            <div id="payment_detail_balance_amount">
                                {numberToDollarFormat(startingBalance / 100)}
                            </div>
                        </div>
                    </div>
                    <div className="pricing-detail-item">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_balance_session_total">
                                Session total
                            </div>
                            <div id="payment_detail_balance_session_total_amount">
                                {numberToDollarFormat(sessionTotal)}
                            </div>
                        </div>
                    </div>
                    <div className="pricing-detail-item pricing-detail-border">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_ending">Ending balance</div>
                            <div id="payment_detail_ending_amount">
                                {numberToDollarFormat(endingBalance / -100)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (startingBalance && endingBalance) {
            return (
                <div>
                    <div className="pricing-detail-item">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_start">
                                Starting balance
                            </div>
                            <div id="payment_detail_start_amount">
                                {numberToDollarFormat(startingBalance / 100)}
                            </div>
                        </div>
                    </div>
                    <div className="pricing-detail-item">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_sess_total">
                                Session total
                            </div>
                            <div id="payment_detail_sess_total_amount">
                                {numberToDollarFormat(sessionTotal)}
                            </div>
                        </div>
                    </div>
                    <div className="pricing-detail-item pricing-detail-border">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_outstanding">
                                Outstanding balance
                            </div>
                            <div id="payment_detail_outstanding_amount">
                                {endingBalance / -100 === 0
                                    ? numberToDollarFormat(0)
                                    : numberToDollarFormat(
                                          endingBalance / -100
                                      )}
                            </div>
                        </div>
                    </div>
                    {ccFee && ccFee > 0 ? (
                        <div className="pricing-detail-item">
                            <div className="pricing-detail-item-header">
                                <div id="payment_detail_fee">
                                    Processing fee
                                </div>
                                <div id="payment_detail_fee_amount">
                                    {numberToDollarFormat(ccFee * -1)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {minFee && minFee > 0 ? (
                        <div className="pricing-detail-item">
                            <div className="pricing-detail-item-header">
                                <div id="payment_detail_min_fee">
                                    $0.50 minimum purchase
                                </div>
                                <div id="payment_detail_min_fee_amount">
                                    {numberToDollarFormat(minFee * -1)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <div className="pricing-detail-item pricing-detail-border">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_due">Amount due</div>
                            <div id="payment_detail_due_amount">
                                {endingBalance / -100 -
                                    (ccFee || 0) -
                                    (minFee || 0) ===
                                0
                                    ? numberToDollarFormat(0)
                                    : numberToDollarFormat(
                                          endingBalance / -100 -
                                              (ccFee || 0) -
                                              (minFee || 0)
                                      )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="pricing-detail-item">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_ses_total">
                                Session total
                            </div>
                            <div id="payment_detail_ses_total_amount">
                                {numberToDollarFormat(baseCost || total / 100)}
                            </div>
                        </div>
                    </div>
                    <div className="pricing-detail-item">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_process_fee">
                                Processing fee
                            </div>
                            <div id="payment_detail_process_fee_amount">
                                {numberToDollarFormat(ccFee || 0)}
                            </div>
                        </div>
                    </div>
                    {minFee && (
                        <div className="pricing-detail-item">
                            <div className="pricing-detail-item-header">
                                <div id="payment_detail_minfee">
                                    $0.50 minimum purchase
                                </div>
                                <div id="payment_detail_minfee_amount">
                                    {numberToDollarFormat(minFee)}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="pricing-detail-item pricing-detail-border">
                        <div className="pricing-detail-item-header">
                            <div id="payment_detail_due">Amount due</div>
                            <div id="payment_detail_due_amount">
                                {numberToDollarFormat(
                                    total / 100 + (minFee || 0) + (ccFee || 0)
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <motion.div
            className="container"
            initial={
                location.state && location.state.slide === true
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
            id="payment_detail"
        >
            <div className="header">
                <div className="header__content">
                    <div
                        className="header__back"
                        onClick={() => {
                            navigate(-1);
                        }}
                        id="payment_detail_back"
                    >
                        <BackIcon id="payment_detail_icon"></BackIcon>
                    </div>
                    {title && title.toUpperCase()}
                </div>
            </div>
            <div className="content" id="payment_detail_content">
                {data && (
                    <div className="payment-detail-station">
                        <div className="payment-detail-station-header">
                            <div id="payment_detail_station">
                                Station {data.ocppSession.station.qrCode}
                            </div>
                            <div id="payment_detail_total_kwh">
                                {roundDown(totalkWh / 1000)} kWh
                            </div>
                        </div>
                        <div className="payment-detail-station-subheader">
                            {data.ocppSession.pricingPolicy.policy === 'TOU' ? (
                                <div>Time-of-use rates</div>
                            ) : (
                                <div></div>
                            )}
                            <div>{duration}</div>
                        </div>
                        {createDelayItem()}
                        {createLineItem()}
                        {createDiscountItem()}
                        {createPenaltyItem()}
                        <div
                            className="pricing-detail-item-header pricing-detail-border"
                            style={{ paddingTop: 20, marginTop: 20 }}
                        >
                            <div>Session total</div>
                            <div>{numberToDollarFormat(sessionTotal)}</div>
                        </div>
                    </div>
                )}
                {!data && !loading && !error && (
                    <div className="payment-detail-station">
                        {[
                            StripeTransactionTypes.DEBIT,
                            StripeTransactionTypes.CREDIT,
                            StripeTransactionTypes.REFUND,
                        ].includes(title as StripeTransactionTypes) && (
                            <div className="payment-detail-station">
                                <div className="payment-detail-station-header">
                                    <div>{title}</div>
                                    <div>
                                        {numberToDollarFormat(
                                            Math.abs(total / 100)
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {(title.toUpperCase() === 'AUTO-RELOAD' ||
                            title.toUpperCase() === 'RELOAD') && (
                            <div>
                                <div
                                    className="payment-detail-station-header"
                                    style={{ marginBottom: 30 }}
                                >
                                    <div>Added funds</div>
                                    <div>
                                        {numberToDollarFormat(total / -100)}
                                    </div>
                                </div>
                                <div className="pricing-detail-item">
                                    <div className="pricing-detail-item-header">
                                        <div>Starting balance</div>
                                        <div>
                                            {numberToDollarFormat(
                                                (startingBalance || 0) / 100
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="pricing-detail-item ">
                                    <div className="pricing-detail-item-header">
                                        <div>Reload amount</div>
                                        <div>
                                            {numberToDollarFormat(total / -100)}
                                        </div>
                                    </div>
                                </div>
                                <div className="pricing-detail-item pricing-detail-border">
                                    <div className="pricing-detail-item-header">
                                        <div>Ending balance</div>
                                        <div>
                                            {(endingBalance || 0) / -100 === 0
                                                ? numberToDollarFormat(0)
                                                : numberToDollarFormat(
                                                      (endingBalance || 0) /
                                                          -100
                                                  )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="payment-detail-bar"></div>
                <div className="payment-detail-station-header">
                    Transaction details
                </div>
                <div className="payment-detail-station-subheader">{date}</div>
                {createSummaryDetails()}
            </div>
        </motion.div>
    );
};

export default App;
