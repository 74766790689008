import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface SignupState {
    isTracked?: boolean | null;
}

const initialState: SignupState = {
    isTracked: true,
};
export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        setIsTracked: (
            state,
            action: PayloadAction<boolean | null | undefined>
        ) => {
            state.isTracked = !!action.payload;
        },
    },
});

export const { setIsTracked } = signupSlice.actions;
export const actions = signupSlice.actions;

export const selectIsTracked = (state: RootState) => state.signup.isTracked;

export default signupSlice.reducer;
