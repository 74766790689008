import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../PrimaryButton';
import RadioButtons, { RadioOptions } from '../RadioButtons';

const GuestCheckout: React.FC = () => {
    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] =
        useState<string>('guestCheckout');
    const options: RadioOptions[] = [
        {
            label: 'Guest Checkout',
            subText: 'No account required.',
            value: 'guestCheckout',
        },
        {
            label: 'Create an account',
            subText: 'Save payment details. Checkout faster in the future.',
            value: 'createAccount',
        },
    ];
    const handlePaymentChange = (value: string) => {
        setSelectedOption(value);
    };

    const handleSubmit = () => {
        if (selectedOption === 'guestCheckout')
            navigate('/guest/contact', { state: { slide: true } });
        else navigate('/signup', { state: { slide: true } });
    };

    const goBack = () => {
        navigate('/guest/pricing');
    };

    return (
        <div className="guest-pricing-content">
            <div className="pricing-details-main-content">
                <div className="pricing__current-price">
                    <p className="pricing__price-title">Checkout</p>
                </div>
                <RadioButtons
                    options={options}
                    selectedValue={selectedOption}
                    onChange={handlePaymentChange}
                />
            </div>
            <div className="modal__button" id="guest_checkout_modal__button">
                <PrimaryButton title={'Continue'} onPress={handleSubmit} />
                <button
                    className="payment__back-button"
                    id="guest_checkout_back__button"
                    onClick={() => goBack()}
                >
                    Back
                </button>
            </div>
        </div>
    );
};

export default GuestCheckout;
