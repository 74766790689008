import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectFirstName,
    selectWelcomeMessage,
    setWelcomeMessage,
} from '../../appSlice';

interface WelcomeHeaderProps {
    loggedIn?: boolean;
}

const WelcomeHeader: React.FC<WelcomeHeaderProps> = ({ loggedIn }) => {
    const dispatch = useAppDispatch();
    const welcomeMessage = useAppSelector(selectWelcomeMessage);
    const name = useAppSelector(selectFirstName) || '';
    const [seenWelcome, setSeenWelcome] = useState(
        welcomeMessage !== 'Welcome'
    );

    useEffect(() => {
        if (!seenWelcome && loggedIn) {
            dispatch(setWelcomeMessage(`Welcome`));
        } else if (seenWelcome) {
            const selection = Math.floor(Math.random() * 2);
            switch (selection) {
                case 0:
                    dispatch(
                        setWelcomeMessage(
                            loggedIn
                                ? name
                                    ? `Nice to see you, ${name}`
                                    : `Nice to see you`
                                : `Get charging`
                        )
                    );
                    break;
                case 1:
                    const now = new Date();
                    if (now.getHours() >= 3 && now.getHours() < 12)
                        dispatch(
                            setWelcomeMessage(
                                name ? `Good morning, ${name}` : 'Good morning'
                            )
                        );
                    else if (now.getHours() >= 12 && now.getHours() < 17)
                        dispatch(
                            setWelcomeMessage(
                                name
                                    ? `Good afternoon, ${name}`
                                    : 'Good afternoon'
                            )
                        );
                    else
                        dispatch(
                            setWelcomeMessage(
                                name ? `Good evening, ${name}` : 'Good evening'
                            )
                        );
                    break;
            }
        }
        setSeenWelcome(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (welcomeMessage === 'Welcome' && name) {
            dispatch(setWelcomeMessage(`Welcome ${name}`));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    return (
        <div className="home__welcome" id="home__welcome">
            {welcomeMessage}
        </div>
    );
};

export default WelcomeHeader;
