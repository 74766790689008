import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    selectActiveChargieId,
    selectChargeAmp,
    selectChargeKw,
    selectChargeKwh,
    selectChargeStatus,
    selectRefreshToken,
} from '../appSlice';
import { ReactComponent as ChargingIcon } from '../assets/icons/charging-lightning.svg';
import { ReactComponent as IdleIcon } from '../assets/icons/idle-pause.svg';
import { ReactComponent as QueuedIcon } from '../assets/icons/QueuedIcon.svg';
import animation from '../assets/session-charging.gif';

import { useNavigate } from 'react-router-dom';
import { ChargeStatus, SecureStorageKeys } from '../utils/enums';

const SessionBox: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const refreshToken = useAppSelector(selectRefreshToken);
    const activeChargieId = useAppSelector(selectActiveChargieId);
    const chargeAmp = useAppSelector(selectChargeAmp);
    const chargeKwh = useAppSelector(selectChargeKwh);
    const chargeKw = useAppSelector(selectChargeKw);
    const chargeStatus = useAppSelector(selectChargeStatus);

    const iconStatus = () => {
        if (activeChargieId && chargeStatus) {
            if (chargeStatus.toUpperCase() === ChargeStatus.CHARGING)
                return (
                    <ChargingIcon
                        className="session__icon--home"
                        id="session_min_charging_icon"
                    />
                );
            else if (chargeStatus.toUpperCase() === ChargeStatus.QUEUED)
                return (
                    <QueuedIcon
                        className="session__icon--home"
                        id="session_min_queued_icon"
                    />
                );
            else if (chargeStatus === ChargeStatus.PREPARING)
                return (
                    <div
                        className="loader"
                        style={{ marginRight: 5 }}
                        id="session_min_loading_icon"
                    />
                );
            else
                return (
                    <IdleIcon
                        className="session__icon--home"
                        style={{ marginTop: -1 }}
                        id="session_min_idle_icon"
                    />
                );
        } else return <></>;
    };

    const activityAnimation = () => {
        if (activeChargieId && chargeStatus) {
            if (chargeStatus === ChargeStatus.CHARGING)
                return (
                    <img
                        src={animation}
                        className="session__animation-style"
                        alt=""
                        id="session_charging_animation"
                    />
                );
            else if (
                chargeStatus === 'FINISHED' ||
                chargeStatus === 'FINISHING'
            )
                return <div className="session__bar-style-finished" />;
            else return <div className="session__bar-style-idle" />;
        }
    };

    if (activeChargieId && refreshToken)
        return (
            <div className="session--box" onClick={() => navigate('/session')}>
                <div className="session__station_id">
                    STATION {activeChargieId}
                </div>
                <div
                    className="session__min-breakdown"
                    id="session_bar_breakdown"
                >
                    {iconStatus()}
                    {chargeStatus} {chargeAmp || 0} A{' '}
                    <div className="session__min-dot">&#8226;</div>
                    {chargeKw || 0} kW
                </div>
                {activityAnimation()}
                <div className="session__min-kwh">{chargeKwh} kWh</div>
            </div>
        );
    else return <></>;
};

export default SessionBox;
