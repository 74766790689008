import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import {
    selectDeviceToken,
    selectRefreshToken,
    selectSubscriptionStarted,
} from '../../appSlice';
import { ReactComponent as HidePasswordIcon } from '../../assets/icons/hidePassword.svg';
import { ReactComponent as ShowPasswordIcon } from '../../assets/icons/showPassword.svg';
import { sizes } from '../../global/variables';
import { axios } from '../../utils/axios';
import { SecureStorageKeys } from '../../utils/enums';
import { getSignupSubscription, saveData } from '../../utils/webview/messages';

interface CreateAccountFormValues {
    name: string;
    password: string;
    confirmPassword: string;
}

const createPasswordMutation = loader('./createPassword.graphql');

const CreatePasswordPage = () => {
    const refreshToken = useAppSelector(selectRefreshToken);
    const deviceToken = useAppSelector(selectDeviceToken);
    const subscriptionFlow = useAppSelector(selectSubscriptionStarted);

    const navigate = useNavigate();
    const params = useParams();
    let [searchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [createPassword, { data, error, loading }] = useMutation(
        createPasswordMutation
    );

    const email = params.email || searchParams.get('email');
    const token = params.token || searchParams.get('token');
    const realm =
        searchParams.get('realm') && searchParams.get('realm') !== 'undefined'
            ? searchParams.get('realm')
            : 'pcse';
    const skip = params.skip || searchParams.get('skip');

    // To redirect on SSO signup, we have to observe the change on refresh token
    // Initially it should be null -> something
    // However if it's already something, it means you're already logged in
    const [initialRefreshToken] = useState(refreshToken);
    useEffect(() => {
        if (skip === 'true') {
            navigate('/', { replace: true });
        }
        if (initialRefreshToken) {
            if (subscriptionFlow) {
                navigate('/subscribe/pay', { replace: true });
            } else {
                navigate('/', { replace: true });
            }
        } else if (refreshToken) {
            if (subscriptionFlow) {
                navigate('/subscribe/pay', {
                    replace: true,
                    state: { promptBiometrics: true },
                });
            } else {
                navigate('/', {
                    replace: true,
                    state: { promptBiometrics: true },
                });
            }
        }
    }, [initialRefreshToken, navigate, refreshToken]);

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        getValues,
    } = useForm<CreateAccountFormValues>();

    useEffect(() => {
        getSignupSubscription();
    }, [subscriptionFlow]);

    const onSubmit = async (data: CreateAccountFormValues) => {
        try {
            await createPassword({
                variables: {
                    email,
                    token,
                    realm,
                    name: data.name,
                    password: data.password,
                },
            });
        } catch {
            // Errors will be caught in the Apollo errors
        }
    };

    useEffect(() => {
        if (data) {
            axios
                .post(`${process.env.REACT_APP_AUTH_ENDPOINT}/login`, {
                    email,
                    password: getValues().password,
                    device_token: deviceToken || undefined,
                    realm,
                })
                .then((result) => {
                    if (result.data.access_token)
                        saveData(
                            SecureStorageKeys.ACCESS_TOKEN,
                            result.data.access_token
                        );
                    if (result.data.refresh_token)
                        saveData(
                            SecureStorageKeys.REFRESH_TOKEN,
                            result.data.refresh_token
                        );
                })
                .catch(() => {});
        }
    }, [data, email, error, getValues, realm]);

    return (
        <div className="page page--light" id="create_password_page">
            <div className="inset__header inset__header--invisible" />
            <div
                id="create_password_page_back"
                className="page__close page__close--black"
                onClick={() => navigate('/', { replace: true })}
            >
                <p id="create_password_page_back_icon">&times;</p>
            </div>
            <h1
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'end',
                    paddingBottom: 50,
                }}
                id="create_password_page_header"
            >
                Create account
            </h1>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <input
                        className="input"
                        id="name"
                        placeholder="Name"
                        autoComplete="name"
                        {...register('name')}
                    />
                    {formErrors?.name?.message && (
                        <span className="form__error">
                            {formErrors.name.message}
                        </span>
                    )}
                </div>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <input
                        className="input"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        placeholder="Password"
                        {...register('password', {
                            required: 'Password is required',
                            minLength: {
                                value: 8,
                                message: 'Must have minimum 8 characters',
                            },
                            pattern: {
                                value: /[A-Z]/,
                                message: 'Must have upper case letter',
                            },
                        })}
                    />
                    <div
                        className="input__icon"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <HidePasswordIcon
                                width={sizes.medium}
                                height={sizes.medium}
                            />
                        ) : (
                            <ShowPasswordIcon
                                width={sizes.medium}
                                height={sizes.medium}
                            />
                        )}
                    </div>
                </div>
                {formErrors?.password?.message && (
                    <span className="form__error">
                        {formErrors.password.message}
                    </span>
                )}
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <input
                        className="input"
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="password"
                        placeholder="Confirm Password"
                        {...register('confirmPassword', {
                            required: 'Password is required',
                            minLength: {
                                value: 8,
                                message: 'Must have minimum 8 characters',
                            },
                            pattern: {
                                value: /[A-Z]/,
                                message: 'Must have upper case letter',
                            },
                            validate: (value, values) =>
                                value === values.password ||
                                "Passwords don't match",
                        })}
                    />
                    <div
                        className="input__icon"
                        onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                        }
                    >
                        {showConfirmPassword ? (
                            <HidePasswordIcon
                                width={sizes.medium}
                                height={sizes.medium}
                            />
                        ) : (
                            <ShowPasswordIcon
                                width={sizes.medium}
                                height={sizes.medium}
                            />
                        )}
                    </div>
                </div>
                {formErrors?.confirmPassword?.message && (
                    <span className="form__error">
                        {formErrors.confirmPassword.message}
                    </span>
                )}
                {error?.message && (
                    <span className="form__error">{error.message}</span>
                )}
                <button
                    type="submit"
                    className="button"
                    disabled={loading}
                    id="create_password_save"
                >
                    Save
                </button>
            </form>
            <div style={{ flex: 2 }} />
        </div>
    );
};

export default CreatePasswordPage;
