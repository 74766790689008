import React, { useEffect, useState } from 'react';
import SettingsButton, {
    SettingsConfig,
} from '../../components/SettingsButton';
import {
    openAppSettings,
    requestBiometricsLogin,
} from '../../utils/webview/messages';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/RightArrow.svg';

const Biometrics: React.FC = () => {
    const navigate = useNavigate();

    const goToAppSettings = () => {
        openAppSettings();
    };

    const privacyOptions: SettingsConfig[] = [
        {
            value: 'Location',
            text: 'Manage',
            callback: () => goToAppSettings(),
        },
        {
            value: 'Notifications',
            text: 'Manage',
            callback: () => goToAppSettings(),
        },
        {
            value: 'Camera',
            text: 'Manage',
            callback: () => goToAppSettings(),
        },
    ];

    return (
        <div id="biometrics_page">
            <button
                id="biometrics_page_manage"
                className="settings__button"
                style={{ marginTop: 10 }}
                onClick={() => navigate('/privacy/biometrics')}
            >
                <div>Face ID Permissions disabled</div>
                <div>Manage</div>
            </button>{' '}
            <button
                id="biometrics_page_setup"
                className="settings__button"
                onClick={() => requestBiometricsLogin()}
            >
                Setup Face ID
            </button>
        </div>
    );
};

export default Biometrics;
