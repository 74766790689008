import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import {
    selectActiveChargieId,
    selectCurrentPage,
    selectRefreshToken,
    selectShowNavigator,
    setScrollPosition,
} from '../appSlice';
import { ReactComponent as ChargingIcon } from '../assets/Charging.svg';
import { ReactComponent as HomeIcon } from '../assets/Home.svg';
import { ReactComponent as Tooltip } from '../assets/icons/tooltip.svg';
import { ReactComponent as ProfileIcon } from '../assets/Profile.svg';
import { colors, sizes } from '../global/variables';
import { hideScanner } from '../utils/webview/messages';
import { store } from '../app/store';

interface TabNavigatorProps {
    override?: boolean;
    closeOverride?: () => void;
}

const TabNavigator: React.FC<TabNavigatorProps> = ({
    override = false,
    closeOverride,
}) => {
    const navigate = useNavigate();
    const refreshToken = useAppSelector(selectRefreshToken);
    const activeChargieId = useAppSelector(selectActiveChargieId);
    const [notifyLowSignal, setNotifyLowSignal] = useState<any>(false);
    const [isLowSignal, setIsLowSignal] = useState<any>(false);
    const [failedNetworkAttempts, setFailedNetworkAttempts] = useState(0);
    const showNavigator = useAppSelector(selectShowNavigator);
    const currentPage = useAppSelector(selectCurrentPage);

    const navigateTo = (path: string) => {
        if (override && closeOverride) {
            closeOverride();
        }
        store.dispatch(setScrollPosition(0));
        if (path === '/scan' && activeChargieId) {
            if (currentPage !== 'session' && refreshToken)
                navigate('/session', { replace: true });
            else if (path === '/scan' && currentPage === 'session') {
                return;
            } else {
                navigate('/scan', { replace: true });
            }
        } else {
            if (path !== '/scan') hideScanner();
            navigate(path, { replace: true });
        }
    };

    const handleDismiss = () => {
        setNotifyLowSignal(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://auth.chargieengine.com/health',
                    {
                        timeout: 2000,
                    }
                );
                if (response) {
                    setIsLowSignal(false);
                    setNotifyLowSignal(false);
                    setFailedNetworkAttempts(0);
                }
            } catch (error: any) {
                const attemptCheck = failedNetworkAttempts + 1;
                setFailedNetworkAttempts(attemptCheck);
                if (attemptCheck > 1) {
                    if (!notifyLowSignal || isLowSignal) {
                        setIsLowSignal(true);
                    }
                }
            }
        };
        const timer = setInterval(() => fetchData(), 2000);
        return () => clearInterval(timer);
    });

    if (showNavigator === false && override === false) {
        return <></>;
    }

    return (
        <div className="navigator" id="navigator">
            <div className="navigator__container">
                <button
                    className="navigator__button"
                    onClick={() => navigateTo('/')}
                    id="tab_navigator__home__button"
                >
                    <HomeIcon
                        fill={
                            currentPage === 'home'
                                ? colors.activeWhite
                                : colors.inactiveWhite
                        }
                        id="tab_navigator__home"
                        width={sizes.large}
                        height={sizes.large}
                    />
                </button>
                <button
                    className="navigator__button"
                    onClick={() => navigateTo('/scan')}
                    id="tab_navigator__charge__button"
                >
                    <ChargingIcon
                        fill={
                            currentPage === 'scan' || currentPage === 'session'
                                ? colors.activeWhite
                                : colors.inactiveWhite
                        }
                        id="tab_navigator__charge"
                        width={sizes.large}
                        height={sizes.large}
                    />
                </button>
                <button
                    className="navigator__button"
                    onClick={() => navigateTo('/settings')}
                    id="tab_navigator__profile__button"
                >
                    <ProfileIcon
                        fill={
                            currentPage === 'settings'
                                ? colors.activeWhite
                                : colors.inactiveWhite
                        }
                        id="tab_navigator__profile"
                        width={sizes.large}
                        height={sizes.large}
                    />
                </button>
            </div>

            {isLowSignal && !notifyLowSignal && (
                <div
                    className="noSignal"
                    onClick={handleDismiss}
                    id="low_signal_banner"
                >
                    <div className="noSignal__content">
                        <Tooltip
                            id="low_signal_banner__icon"
                            className="session__icon-medium"
                            fill={colors.activeWhite}
                            stroke={colors.activeWhite}
                        />
                        <span
                            className="noSignal__text"
                            id="low_signal_banner__text"
                        >
                            No Internet Connection.
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TabNavigator;
