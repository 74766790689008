import Pricing from './Pricing';
import Property from './Property';
import TouSeason from './TouSeason';

const DEFAULT_TZ = 'America/Los_Angeles';
export default interface Station {
    __typename: 'Station';
    id: string;
    organizationId: string;
    propertyId: string;
    property: Property;
    serialNumber?: string;
    qrCode?: string;
    floor?: string;
    parkingSpace?: string;
    make?: string;
    model?: string;
    firmwareVersion?: string;
    installedAt?: Date;
    isExclusive?: Number;
    createdAt: Date;
    registeredAt?: Date;
    itemStatus: string;
    isSubscription: boolean;
    isFree?: boolean;
    warrantyDate?: Date;
    stationLevel?: Number | string | null;
    isTesting?: boolean;
    macAddress?: string;
    pricing: Pricing;
    inheritedPricingFrom: Pricing;
    lastCommunication: string;
    currentTransactions: any;
    clusterId: string;
    connectorCount: number;
    latitude: string;
    longitude: string;
    clusterCount?: number;
    stationType: string;
    ocppStatus: string;
}

export interface Transaction {
    connector: number;
    ampHistoryWithStatus: [number, string][];
    guestUser: boolean;
    groupDiscount: any;
    isOtc: boolean;
    chargeStatus: string;
    chargeStartTime: string;
    totalCost: number;
    totalWH: number | null;
    liveWatts: number;
}

export function sessionTransaction(obj: any): obj is Transaction {
    return (
        'totalCost' in obj &&
        'ampHistoryWithStatus' in obj &&
        'chargeStatus' in obj &&
        'totalWH' in obj &&
        'liveWatts' in obj
    );
}

type GetTransactionDataResponse = {
    totalCost: number;
    ampHistoryWithStatus: [number, string][];
    chargeStatus: string;
    totalWH: number | null;
    liveWatts: number;
    chargeStartTime: string;
};

export const extractTransactionForSession = (
    data: GetTransactionDataResponse
) => {
    return data;
};

export type StartData = {
    station: Station;
    todayTouSeason: TouSeason;
};

type GetStationDetailsDataResponse = {
    getStationPricing: StartData;
};

export const extractStationDetails = (data: GetStationDetailsDataResponse) => {
    return data && data.getStationPricing;
};

export const calculateTimeZone = (station: Station) => {
    return DEFAULT_TZ;
};
