import { accessToken } from 'mapbox-gl';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectRefreshToken } from '../../appSlice';

const withLoggedInRedirect = <P extends object>(
    WrappedComponent: React.ComponentType<P>
): React.FC<P> => {
    const LoggedInRedirect: React.FC<P> = (props) => {
        const navigate = useNavigate();
        const refreshToken = useAppSelector(selectRefreshToken);
        const [initialRefreshToken] = useState(refreshToken);
        useEffect(() => {
            if (initialRefreshToken) {
                navigate('/', { replace: true });
            } else if (refreshToken) {
                navigate('/', {
                    replace: true,
                    state: { promptBiometrics: true },
                });
            }
        }, [initialRefreshToken, navigate, refreshToken]);

        // Use accessToken as the key to force rerender
        return <WrappedComponent key={accessToken} {...props} />;
    };

    return LoggedInRedirect;
};

export default withLoggedInRedirect;
