import { useQuery } from '@apollo/client';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { motion } from 'framer-motion';
import { loader } from 'graphql.macro';
import mapboxgl, { Map, Marker } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { store } from '../../app/store';
import {
    selectLatitude,
    selectLongitude,
    setAllowedLocationData,
    setScrollPosition,
    setShowNavigator,
} from '../../appSlice';
import { ReactComponent as BackButton } from '../../assets/BackButton.svg';
import { ReactComponent as FilterButton } from '../../assets/FilterButton.svg';
import { ReactComponent as RecenterButton } from '../../assets/RecenterButton.svg';
import { ReactComponent as Lock } from '../../assets/icons/Lock.svg';

import { extractPropertiesData } from '../../models/PropertyMap';
import { setStatusBarLight } from '../../utils/webview/messages';
import MapFilter from './MapFilter';
import MapModal from './MapModal';
import Station from '../../models/Station';
import { logEvent } from 'firebase/analytics';
import { analytics, firebaseSessionId } from '../../utils/firebase';
import { jwtDecode, JwtPayload } from 'jwt-decode';

const getPropertyLocations = loader('./getProperties.graphql');
export const lockIconSvg = `<svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.57143 12.8571H1.42857C0.639286 12.8571 0 12.2179 0 11.4286V6.42857C0 5.63929 0.639286 5 1.42857 5H8.57143C9.36071 5 10 5.63929 10 6.42857V11.4286C10 12.2179 9.36071 12.8571 8.57143 12.8571Z" fill="#DBDFE4"/>
<mask id="path-2-inside-1_10022_3780" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.14285 5V2.85714C2.14285 1.27929 3.42214 0 5 0C6.57785 0 7.85714 1.27929 7.85714 2.85714V5"/>
</mask>
<path d="M0.642853 5C0.642853 5.82843 1.31443 6.5 2.14285 6.5C2.97128 6.5 3.64285 5.82843 3.64285 5H0.642853ZM6.35714 5C6.35714 5.82843 7.02871 6.5 7.85714 6.5C8.68556 6.5 9.35714 5.82843 9.35714 5H6.35714ZM3.64285 5V2.85714H0.642853V5H3.64285ZM3.64285 2.85714C3.64285 2.10771 4.25057 1.5 5 1.5V-1.5C2.59371 -1.5 0.642853 0.450859 0.642853 2.85714H3.64285ZM5 1.5C5.74942 1.5 6.35714 2.10771 6.35714 2.85714H9.35714C9.35714 0.450859 7.40628 -1.5 5 -1.5V1.5ZM6.35714 2.85714V5H9.35714V2.85714H6.35714Z" fill="#DBDFE4" mask="url(#path-2-inside-1_10022_3780)"/>
</svg>`;

export type BoundingBoxType = {
    minLong: number;
    maxLong: number;
    minLat: number;
    maxLat: number;
};

const MapPage: React.FC = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [lat, setLat] = useState(34.064251);
    const [long, setLong] = useState(-118.360565);
    const [boundingBox, setBoundingBox] = useState<BoundingBoxType | null>(
        null
    );
    const [mapElement, setMapElement] = useState<Map | null>(null);
    const mapContainer = useRef<HTMLDivElement>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState<string | null>(
        null
    );
    const [hasLocation, setHasLocation] = useState(false);
    const [showRedo, setShowRedo] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [allowPublic, setAllowPublic] = useState(true);
    const [allowPrivate, setAllowPrivate] = useState(true);
    const [allowLvl2, setAllowLvl2] = useState(true);
    const [allowLvl3, setAllowLvl3] = useState(true);
    const [markers, setMarkers] = useState<Marker[]>([]);
    const navigate = useNavigate();
    const markerRef = useRef<HTMLDivElement | null>(null);
    const longitude = useAppSelector(selectLongitude);
    const latitude = useAppSelector(selectLatitude);

    const {
        error: getPropertiesError,
        data: getPropertiesData,
        loading: getPropertiesLoading,
        refetch: refetchPropertiesData,
    } = useQuery(getPropertyLocations, {
        variables: {
            filter: {
                minLong: boundingBox?.minLong,
                maxLong: boundingBox?.maxLong,
                minLat: boundingBox?.minLat,
                maxLat: boundingBox?.maxLat,
            },
            stationFilter: {
                allowPublic: allowPublic,
                allowPrivate: allowPrivate,
                allowLvl2: allowLvl2,
                allowLvl3: allowLvl3,
            },
        },
        skip: !boundingBox,
        fetchPolicy: 'cache-and-network',
    });

    const displayPropertyDetails = (
        id: string,
        element: HTMLDivElement,
        locked: boolean = false
    ) => {
        if (locked && markerRef.current) {
            markerRef.current.classList.remove(
                'map__marker-property-selected-locked'
            );
        } else if (markerRef.current)
            markerRef.current.classList.remove('map__marker-property-selected');
        setSelectedProperty(null);
        setSelectedProperty(id);
        setModalOpen(true);
        setFilterOpen(false);
        if (
            locked &&
            !element.className.includes('map__marker-property-selected-locked')
        )
            element.className += ' map__marker-property-selected-locked';
        else if (!element.className.includes('map__marker-property-selected'))
            element.className += ' map__marker-property-selected';
        markerRef.current = element;
    };

    const generateNewMarker = ({
        lat,
        lng,
        id,
        stations,
    }: {
        lng: number;
        lat: number;
        id: string;
        stations: Station[];
    }) => {
        try {
            if (mapElement) {
                let publicTotal = 0;
                stations.map((station) =>
                    station.stationType === 'PUBLIC' ? publicTotal++ : null
                );

                const divElement = document.createElement('div');

                if (publicTotal > 0) {
                    divElement.onclick = () =>
                        displayPropertyDetails(id, divElement);
                    divElement.innerHTML = publicTotal.toString();
                    divElement.className = 'map__marker-property';
                } else {
                    divElement.innerHTML = lockIconSvg;
                    divElement.onclick = () =>
                        displayPropertyDetails(id, divElement, true);
                    divElement.className = 'map__marker-property-locked';
                }

                const mark = new mapboxgl.Marker(divElement)
                    .setLngLat([lng, lat])
                    .setDraggable(false)
                    .addTo(mapElement);
                return mark;
            }
        } catch {
            const currentDate = new Date();
            const token = store.getState().app.accessToken;
            if (token) {
                logEvent(analytics, 'MA_4.0_map_marker_error', {
                    time: currentDate.toLocaleString(),
                    sessionId: firebaseSessionId,
                    userId: jwtDecode<JwtPayload>(token || '').sub,
                    marker_id: id,
                    marker_lat: lat,
                    marker_long: long,
                });
            } else {
                logEvent(analytics, 'MA_4.0_map_marker_error', {
                    time: currentDate.toLocaleString(),
                    sessionId: firebaseSessionId,
                    marker_id: id,
                    marker_lat: lat,
                    marker_long: long,
                });
            }
        }
    };

    const handleOptionChange = (option: string) => {
        if (option === 'publicAccess') setAllowPublic(!allowPublic);
        if (option === 'privateAccess') setAllowPrivate(!allowPrivate);
        if (option === 'lvl2') setAllowLvl2(!allowLvl2);
        if (option === 'lvl3') setAllowLvl3(!allowLvl3);
    };

    useEffect(() => {
        dispatch(setShowNavigator(false));
        setStatusBarLight(false);
        if (longitude && latitude) {
            setLat(latitude);
            setLong(longitude);
        }
        mapboxgl.accessToken =
            'pk.eyJ1Ijoienpzb2Z0d2FyZWVuZ2luZWVyaW5nIiwiYSI6ImNsc2F1dmNrbTA3bngyam50azdncGVqZnQifQ.Rv-pmEPSE3qDiSyuBVS5gg';
        try {
            if (mapContainer.current) {
                const newMap = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: 'mapbox://styles/zzsoftwareengineering/clu1hclp5021q01oid5uh6g4i',
                    center: [long, lat],
                    zoom: 13,
                    maxZoom: 30,
                    minZoom: 5,
                });
                setMapElement(newMap);
                setHasLocation(true);
                newMap.jumpTo({ center: [long, lat] });
                dispatch(setAllowedLocationData(true));

                return () => newMap.remove();
            }
        } catch (e) {
            const currentDate = new Date();
            logEvent(analytics, 'MA_4.0_map_loading_error', {
                error: e,
                sessionId: firebaseSessionId,
                time: currentDate.toLocaleString(),
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [long, lat]);

    useEffect(() => {
        try {
            if (mapContainer.current) {
                markers.map((item) => {
                    item.getElement().remove();
                    item.setLngLat([0, 0]);
                    item.remove();
                    return 0;
                });
                refetchPropertiesData({
                    filter: {
                        minLong: boundingBox?.minLong,
                        maxLong: boundingBox?.maxLong,
                        minLat: boundingBox?.minLat,
                        maxLat: boundingBox?.maxLat,
                    },
                    stationFilter: {
                        allowPublic: allowPublic,
                        allowPrivate: allowPrivate,
                        allowLvl2: allowLvl2,
                        allowLvl3: allowLvl3,
                    },
                });
            }
        } catch {
            console.log('Map error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        allowPublic,
        allowPrivate,
        allowLvl2,
        allowLvl3,
        refetchPropertiesData,
        boundingBox,
    ]);

    useEffect(() => {
        if (mapElement) {
            mapElement.on('load', function () {
                mapElement.addControl(
                    new MapboxGeocoder({
                        accessToken: mapboxgl.accessToken,
                        placeholder: ' ',
                        countries: 'US',
                    })
                );
                mapElement.on('moveend', ({ originalEvent }) => {
                    if (originalEvent) {
                        mapElement.fire('usermoveend');
                        setShowRedo(true);
                    } else {
                        mapElement.fire('flyend');
                        const boundingArr = mapElement.getBounds();
                        const bbBox: BoundingBoxType = {
                            minLong: boundingArr._sw.lng,
                            minLat: boundingArr._sw.lat,
                            maxLong: boundingArr._ne.lng,
                            maxLat: boundingArr._ne.lat,
                        };
                        setBoundingBox(bbBox);
                    }
                });

                const divElement = document.createElement('div');
                divElement.className = 'map__marker-user';
                new mapboxgl.Marker(divElement)
                    .setLngLat([long, lat])
                    .addTo(mapElement);
                const boundingArr = mapElement.getBounds();
                const bbBox: BoundingBoxType = {
                    minLong: boundingArr._sw.lng,
                    minLat: boundingArr._sw.lat,
                    maxLong: boundingArr._ne.lng,
                    maxLat: boundingArr._ne.lat,
                };
                setBoundingBox(bbBox);
            });
            if (getPropertiesData) {
                const propertyData = extractPropertiesData(getPropertiesData);
                const markersTempArr: Marker[] = [];
                propertyData.map((marker) => {
                    if (marker.stations.length > 0) {
                        const mark = generateNewMarker({
                            lat: marker.latitude,
                            lng: marker.longitude,
                            id: marker.id,
                            stations: marker.stations,
                        });
                        if (mark) markersTempArr.push(mark);
                    }
                    return 0;
                });
                setMarkers(markersTempArr);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [long, lat, getPropertiesData, mapElement]);

    const flyToCenter = () => {
        if (mapElement) {
            mapElement.flyTo({ center: [long, lat] });
        }
    };

    const toggleFilter = () => {
        setModalOpen(false);
        setFilterOpen(!filterOpen);
    };

    const toggleModal = () => {
        setFilterOpen(false);
        if (modalOpen && markerRef.current) {
            markerRef.current.classList.remove('map__marker-property-selected');
            markerRef.current.classList.remove(
                'map__marker-property-selected-locked'
            );
        }
        setModalOpen(!modalOpen);
    };

    const redoSearch = () => {
        if (mapElement) {
            const boundingArr = mapElement.getBounds();
            const bbBox: BoundingBoxType = {
                minLong: boundingArr._sw.lng,
                minLat: boundingArr._sw.lat,
                maxLong: boundingArr._ne.lng,
                maxLat: boundingArr._ne.lat,
            };
            setBoundingBox(bbBox);
        }
        setShowRedo(false);
    };

    return (
        <div
            className="container--full"
            style={{ paddingTop: 0, paddingBottom: 0 }}
            id="map_page"
        >
            <motion.div
                style={{ position: 'relative' }}
                initial={
                    location.state && location.state.slide === true
                        ? {
                              marginLeft: '100%',
                              width: '100%',
                              transformOrigin: 'left',
                          }
                        : {}
                }
                animate={{ marginLeft: 0 }}
            >
                <div
                    className="map__back-button"
                    id="map_page_back"
                    onClick={() => {
                        store.dispatch(setScrollPosition(0));
                        navigate(-1);
                    }}
                >
                    <BackButton id="map_page_back_icon" />
                </div>
                <div
                    className="map__filter-button"
                    id="map_page_filter_button"
                    onClick={() => toggleFilter()}
                >
                    <FilterButton id="map_page_filter_button_icon" />
                </div>
                {showRedo && (
                    <button
                        className="map__redo-button"
                        onClick={() => redoSearch()}
                        id="map_page_redo_button"
                    >
                        Search this area
                    </button>
                )}
            </motion.div>

            {hasLocation && (
                <div
                    className="map__recenter-button"
                    onClick={() => flyToCenter()}
                    id="map_page_fly_button"
                >
                    <RecenterButton />
                </div>
            )}
            <div className="inset__header--invisible" />
            <div className="map__full" ref={mapContainer}>
                {selectedProperty && (
                    <MapModal
                        isOpen={modalOpen}
                        onClose={() => toggleModal()}
                        propertyId={selectedProperty}
                    />
                )}
                <MapFilter
                    isOpen={filterOpen}
                    onClose={() => setFilterOpen(false)}
                    publicFilter={allowPublic}
                    privateFilter={allowPrivate}
                    lvl2Filter={allowLvl2}
                    lvl3Filter={allowLvl3}
                    onOptionChange={handleOptionChange}
                />
            </div>
        </div>
    );
};

export default MapPage;
