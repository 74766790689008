import React, { useEffect, useState } from 'react';
import { Time } from '../../utils/enums';

interface ModalProps {
    onClose: (selectedOption: Time) => void;
    defaultSelectedOption: Time;
}

const Modal: React.FC<ModalProps> = ({ onClose, defaultSelectedOption }) => {
    const [selectedOption, setSelectedOption] = useState<Time>(
        defaultSelectedOption
    );

    useEffect(() => {
        setSelectedOption(defaultSelectedOption);
    }, [defaultSelectedOption]);

    const handleOptionChange = (value: Time) => {
        setSelectedOption(value);
        onClose(value);
    };

    return (
        <>
            <div className="modal" id="charging_history_filter_modal">
                <div
                    id="charging_history_filter_modal_background"
                    className="modal__background"
                    onClick={() => onClose(selectedOption)}
                ></div>
                <div
                    className="modal__content"
                    id="charging_history_filter_modal_content"
                >
                    <div className="modal__close-container">
                        <p
                            id="charging_history_filter_modal_close"
                            className="modal__close"
                            onClick={() => onClose(selectedOption)}
                        >
                            &times;
                        </p>
                    </div>
                    <p
                        className="modal__title"
                        id="charging_history_filter_title"
                    >
                        Filter
                    </p>

                    <div>
                        <ul className="modal__radio">
                            <li className="modal__radio-li">
                                <input
                                    type="radio"
                                    className="checkbox-input"
                                    id="mostRecent"
                                    value={Time.MOSTRECENT}
                                    checked={selectedOption === Time.MOSTRECENT}
                                    onChange={() =>
                                        handleOptionChange(Time.MOSTRECENT)
                                    }
                                />
                                <span
                                    className="checkbox-custom"
                                    id="charging_history_filter_most_recent"
                                ></span>
                                <label
                                    className="modal__radio-label"
                                    htmlFor="mostRecent"
                                    id="charging_history_filter_most_recent_label"
                                >
                                    Most recent
                                </label>
                            </li>
                            <li className="modal__radio-li">
                                <input
                                    type="radio"
                                    className="checkbox-input"
                                    id="thirtyDays"
                                    value={Time.THIRTYDAYS}
                                    checked={selectedOption === Time.THIRTYDAYS}
                                    onChange={() =>
                                        handleOptionChange(Time.THIRTYDAYS)
                                    }
                                />
                                <span
                                    className="checkbox-custom"
                                    id="charging_history_filter_30"
                                ></span>
                                <label
                                    className="modal__radio-label"
                                    htmlFor="thirtyDays"
                                    id="charging_history_filter_30_label"
                                >
                                    Last 30 days
                                </label>
                            </li>
                            <li className="modal__radio-li">
                                <input
                                    type="radio"
                                    className="checkbox-input"
                                    id="sixMonths"
                                    value={Time.SIXMONTHS}
                                    checked={selectedOption === Time.SIXMONTHS}
                                    onChange={() =>
                                        handleOptionChange(Time.SIXMONTHS)
                                    }
                                />
                                <span
                                    className="checkbox-custom"
                                    id="charging_history_filter_6mo"
                                ></span>
                                <label
                                    id="charging_history_filter_6mo_text"
                                    className="modal__radio-label"
                                    htmlFor="sixMonths"
                                >
                                    Last 6 months
                                </label>
                            </li>
                            <li className="modal__radio-li">
                                <input
                                    type="radio"
                                    className="checkbox-input"
                                    id="year"
                                    value={Time.YEAR}
                                    checked={selectedOption === Time.YEAR}
                                    onChange={() =>
                                        handleOptionChange(Time.YEAR)
                                    }
                                />
                                <span
                                    className="checkbox-custom"
                                    id="charging_history_filter_1yr"
                                ></span>
                                <label
                                    className="modal__radio-label"
                                    htmlFor="year"
                                    id="charging_history_filter_1yr_label"
                                >
                                    Last year
                                </label>
                            </li>
                            <li className="modal__radio-li">
                                <input
                                    type="radio"
                                    className="checkbox-input"
                                    id="total"
                                    value={Time.TOTAL}
                                    checked={selectedOption === Time.TOTAL}
                                    onChange={() =>
                                        handleOptionChange(Time.TOTAL)
                                    }
                                />
                                <span
                                    className="checkbox-custom"
                                    id="charging_history_filter_total"
                                ></span>
                                <label
                                    className="modal__radio-label"
                                    htmlFor="total"
                                    id="charging_history_filter_total_text"
                                >
                                    All time
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
