import { colors, sizes } from '../global/variables';
import { ReactComponent as Error } from '../assets/icons/error.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/CloseIcon.svg';

import { RemoteErrorTypes } from '../utils/enums';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    errorType: RemoteErrorTypes | null | undefined;
}

const ErrorModal: React.FC<ModalProps> = ({ isOpen, onClose, errorType }) => {
    const [title, setTitle] = useState('Unexpected');
    const [message, setMessage] = useState(
        `Try another station or contact support`
    );

    useEffect(() => {
        switch (errorType) {
            case RemoteErrorTypes.ALREADY_CHARGING:
                setTitle('Session already started');
                setMessage('You already have a charge in progress');
                break;
            case RemoteErrorTypes.STATION_IN_USE:
                setTitle('In use');
                setMessage(
                    'This station has another charge in progress. Try another station'
                );
                break;
            case RemoteErrorTypes.ASSIGNED_PARKING:
                setTitle('Assigned station');
                setMessage(
                    'This is a private station. Contact the property manager for access'
                );
                break;
            case RemoteErrorTypes.UNAVAILABLE:
                setTitle('Station unavailable');
                setMessage('Try another station or contact support');
                break;
            case RemoteErrorTypes.EV_NOT_DETECTED:
                setTitle('Vehicle not detected');
                setMessage('Plug-in before trying again');
                break;
            case RemoteErrorTypes.INVALID_STATION_CODE:
                setTitle('Station not ready yet');
                setMessage(
                    'Sorry, but this charging station is not active yet. Please contact us for assistance'
                );
                break;
            case RemoteErrorTypes.PAYMENT_FAILURE:
            case RemoteErrorTypes.PAYMENT_METHOD_DECLINED:
            case RemoteErrorTypes.PAYMENT_METHOD_DECLINED_FUNDS:
            case RemoteErrorTypes.PAYMENT_METHOD_DECLINED_UPDATE:
                setTitle('Payment failure');
                setMessage(
                    'Please update your payment information to start your charge'
                );
                break;
        }
    }, [errorType]);

    return (
        <>
            {isOpen && (
                <div className="modal" id="error_modal">
                    <div
                        className="modal__background"
                        onClick={onClose}
                        id="error_modal_background"
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content-error"
                        id="error_modal_close_content"
                    >
                        <div
                            className="modal__close-container"
                            id="error_modal_close_container"
                        >
                            <div
                                className="modal__close"
                                onClick={onClose}
                                id="error_modal_close"
                            >
                                <CloseIcon
                                    fill={colors.inactiveWhite}
                                    id="error_modal_close_icon"
                                />
                            </div>
                        </div>
                        <Error className="modal__icon" id="error_modal_icon" />
                        <p className="modal__title" id="error_modal_title">
                            {title}
                        </p>
                        <p className="modal__message" id="error_modal_message">
                            {message}
                        </p>
                    </motion.div>
                    <div className="modal__content--inset" />
                </div>
            )}
        </>
    );
};

export default ErrorModal;
