const colors = {
    inactiveWhite: '#9A9DA0',
    activeWhite: '#F0F0F0',
    navColor: '#1C1F20',
    inactiveButton: '#DBDFE4',
    activeButton: '#144BFF',
    white: '#FFF',
    black: '#000',
};

const sizes = {
    small: 16,
    medium: 20,
    large: 30,
    navHeight: 60,
};

const sizesLineHeight = {
    small: 20,
    medium: 26,
    large: 36,
};

const fonts = {
    regular: 'Lufga',
    bold: 'LufgaMedium',
};

export { colors, sizes, sizesLineHeight, fonts };
