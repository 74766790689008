import { motion } from 'framer-motion';
import { setupBiometrics } from '../utils/webview/messages';
import PrimaryButton from './PrimaryButton';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const BiometricsModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    const setup = () => {
        setupBiometrics();
        onClose();
    };

    return (
        <>
            {isOpen && (
                <div
                    className="modal"
                    style={{ textAlign: 'left' }}
                    id="biometrics_modal"
                >
                    <div
                        className="modal__background"
                        onClick={onClose}
                        id="biometrics_modal_background"
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content modal__content--light"
                        id="biometrics_modal_content"
                    >
                        <div
                            className="modal__close-container"
                            id="biometrics_modal_close_container"
                        >
                            <p
                                className="modal__close"
                                onClick={onClose}
                                id="biometrics_modal_close"
                            >
                                &times;
                            </p>
                        </div>
                        <p className="modal__title" id="biometrics_modal_title">
                            Enable biometric authentication
                        </p>
                        <p
                            style={{ marginBottom: 16 }}
                            id="biometrics_modal_description"
                        >
                            Log in to your Chargie account using your phone's
                            biometric credentials
                        </p>
                        <PrimaryButton
                            title="Enable"
                            onPress={setup}
                            id="biometrics_modal"
                        />
                        <button
                            className="button--transparent button--transparent-black"
                            onClick={onClose}
                            id="biometrics_modal_skip_button"
                        >
                            Skip
                        </button>
                    </motion.div>
                </div>
            )}
        </>
    );
};
export default BiometricsModal;
