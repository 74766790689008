import { AxiosError, isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectAppleAuthExists,
    selectBiometricsExists,
    selectBiometricsType,
    selectDeviceToken,
    selectGooglePlayServicesExists,
    selectRefreshToken,
    setShowNavigator,
} from '../../appSlice';
import { ReactComponent as AppleIcon } from '../../assets/icons/apple.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { ReactComponent as FaceIdIcon } from '../../assets/icons/faceid.svg';
import { ReactComponent as FingerprintIcon } from '../../assets/icons/fingerprint.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg';
import { ReactComponent as HidePasswordIcon } from '../../assets/icons/hidePassword.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/RedError.svg';
import { ReactComponent as ShowPasswordIcon } from '../../assets/icons/showPassword.svg';
import { colors, sizes } from '../../global/variables';
import { generateHeaders } from '../../utils/api';
import { axios } from '../../utils/axios';
import { SecureStorageKeys } from '../../utils/enums';
import {
    appleSignIn,
    googleSignIn,
    requestBiometricsLogin,
    saveData,
    setStatusBarLight,
} from '../../utils/webview/messages';
import { selectLoginError, setLoginError } from './slice';

interface LoginFormValues {
    email: string;
    password: string;
}

const LoginPage = () => {
    const dispatch = useAppDispatch();
    const refreshToken = useAppSelector(selectRefreshToken);
    const deviceToken = useAppSelector(selectDeviceToken);
    const biometricsType = useAppSelector(selectBiometricsType);
    const biometricsExists = useAppSelector(selectBiometricsExists);
    const googlePlayServicesExists = useAppSelector(
        selectGooglePlayServicesExists
    );
    const appleAuthExists = useAppSelector(selectAppleAuthExists);
    // Use redux to capture SSO errors too
    const loginError = useAppSelector(selectLoginError);
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        if (location.state?.promptBiometrics === true && biometricsExists) {
            requestBiometricsLogin();
        }
    }, [biometricsExists, location.state?.promptBiometrics]);

    useEffect(() => {
        dispatch(setShowNavigator(false));
        setStatusBarLight(true);
    }, [dispatch]);

    useEffect(() => {
        if (refreshToken) navigate('/', { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshToken]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormValues>({
        defaultValues: { email: location.state?.email || '' },
    });
    const onSubmit = async (data: LoginFormValues) => {
        try {
            dispatch(setLoginError(null));
            setLoading(true);
            const result = await axios.post(
                `${process.env.REACT_APP_AUTH_ENDPOINT}/login`,
                {
                    ...data,
                    // Or undefined for simulators, otherwise null is passed and errors
                    device_token: deviceToken || undefined,
                    realm: 'pcse',
                },
                { headers: await generateHeaders() }
            );
            if (isAxiosError(result.data?.axiosError))
                throw result.data.axiosError;
            saveData(SecureStorageKeys.ACCESS_TOKEN, result.data.access_token);
            saveData(
                SecureStorageKeys.REFRESH_TOKEN,
                result.data.refresh_token
            );
        } catch (e) {
            let message = 'Something went wrong. Please try again.';
            if (isAxiosError(e)) {
                const axiosError = e as AxiosError<any>;
                if (axiosError.response?.status === 401) {
                    message = 'Invalid credentials. Please try again.';
                }
            }
            dispatch(setLoginError(message));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="page page--fullscreen" id="login_page">
            <div className="inset__header" />
            <div
                className="page__close"
                onClick={() => navigate('/', { replace: true })}
                id="login_page_back"
            >
                <CloseIcon
                    fill={colors.inactiveWhite}
                    id="login_page_back_icon"
                />
            </div>
            <h1
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'end',
                    paddingBottom: 50,
                }}
                id="login_page_header"
            >
                Welcome back
            </h1>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <input
                            className="input"
                            type="email"
                            id="email"
                            placeholder="Email"
                            {...register('email', {
                                required: 'Email is required',
                            })}
                        />
                        {biometricsExists && biometricsType && (
                            <div
                                className="input__icon"
                                onClick={requestBiometricsLogin}
                                id="login_page_face_id"
                            >
                                {biometricsType === 'FaceID' ? (
                                    <FaceIdIcon
                                        width={sizes.medium}
                                        height={sizes.medium}
                                        id="login_page_face_id_icon"
                                    />
                                ) : (
                                    <FingerprintIcon
                                        width={sizes.medium}
                                        height={sizes.medium}
                                        id="login_page_fingerprint_icon"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    {errors.email && (
                        <span className="form__error">
                            {errors.email.message}
                        </span>
                    )}
                </div>
                <div>
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <input
                            className="input"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            placeholder="Password"
                            {...register('password', {
                                required: 'Password is required',
                            })}
                        />
                        <div
                            className="input__icon"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? (
                                <HidePasswordIcon
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            ) : (
                                <ShowPasswordIcon
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            )}
                        </div>
                    </div>
                    {errors.password && (
                        <span className="form__error">
                            {errors.password.message}
                        </span>
                    )}
                    {loginError && (
                        <div className="radio-error" style={{ marginTop: 5 }}>
                            <ErrorIcon
                                className="radio-error-icon"
                                id="login_page_error_icon"
                            />
                            {loginError}
                        </div>
                    )}
                    <p style={{ marginTop: 5, textAlign: 'right' }}>
                        <a
                            className="link"
                            href="/forgot-password"
                            id="login_page_forgot_password"
                        >
                            Forgot Password?
                        </a>
                    </p>
                </div>
                <button
                    type="submit"
                    className="button"
                    disabled={loading}
                    id="login_page_button"
                >
                    Log in
                </button>
            </form>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 2,
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 10,
                    }}
                >
                    {googlePlayServicesExists &&
                        process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                            <button
                                className="button button--sso"
                                type="button"
                                onClick={googleSignIn}
                                id="login_page_google_button"
                            >
                                <GoogleIcon
                                    id="login_page_google_icon"
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            </button>
                        )}
                    {appleAuthExists &&
                        process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                            <button
                                className="button button--sso"
                                type="button"
                                onClick={appleSignIn}
                                id="login_page_apple_login"
                            >
                                <AppleIcon
                                    id="login_page_apple_icon"
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            </button>
                        )}
                </div>
                <p style={{ textAlign: 'center', fontSize: 16 }}>
                    Don't have an account?{' '}
                    <Link
                        className="link"
                        style={{ fontSize: 16 }}
                        to="/signup"
                        id="login_page_signup"
                    >
                        Sign up
                    </Link>
                    .
                </p>
            </div>
        </div>
    );
};

export default LoginPage;
