import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    selectAccessToken,
    selectRefreshToken,
    setScrollPosition,
} from '../../../appSlice';
import ChargingHistoryItem from '../../../containers/ChargingHistoryPage/ChargingHistoryItem';
import {
    selectChargingHistory,
    setChargingHistory,
} from '../../../containers/HomePage/slice';
import { Time } from '../../../utils/enums';
import withTokenWatcher from '../../HOC/withTokenWatcher';

const getRecentTransactionsUser = loader(
    '../../../containers/ChargingHistoryPage/getRecentTransactionsForUser.graphql'
);

interface ChargingHistoryProps {
    home?: boolean;
}

const ChargingHistory: React.FC<ChargingHistoryProps> = ({ home }) => {
    const dispatch = useAppDispatch();
    const accessToken = useAppSelector(selectAccessToken);
    const refreshToken = useAppSelector(selectRefreshToken);
    const [getRecentTransactions, { error, data }] = useLazyQuery(
        getRecentTransactionsUser,
        {
            fetchPolicy: 'no-cache',
        }
    );
    const [ocppSessions, setOcppSessions] = useState<any[]>([]);
    const [time, setTime] = useState<Time>(Time.MOSTRECENT);
    const [totalKwh, setTotalKwh] = useState<any>(0);
    const savedChargingHistory = useAppSelector(selectChargingHistory);

    const navigate = useNavigate();

    const timeFrames = [
        { value: Time.MOSTRECENT, label: 'Most recent' },
        { value: Time.THIRTYDAYS, label: 'Last 30 days' },
        { value: Time.SIXMONTHS, label: 'Last 6 months' },
        { value: Time.YEAR, label: 'Last year' },
        { value: Time.TOTAL, label: 'All time' },
    ];

    useEffect(() => {
        if (accessToken) getRecentTransactions();
    }, [accessToken, getRecentTransactions]);

    const calculateTotalKwh = (filteredSessions: any[]) => {
        let total = 0;
        filteredSessions.map((ocppSession: any) => (total += ocppSession.wh));
        setTotalKwh(total / 1000);
    };
    useEffect(() => {
        if (data?.recentTransactionsForUser?.length && refreshToken) {
            setOcppSessions(data.recentTransactionsForUser);
            dispatch(setChargingHistory(data.recentTransactionsForUser));
        } else if (savedChargingHistory) {
            setOcppSessions(savedChargingHistory);
        } else {
            setOcppSessions([]);
        }
    }, [data, refreshToken, savedChargingHistory]);

    const filteredTransactions = useMemo(() => {
        if (!ocppSessions) return [];

        return ocppSessions.slice(0, 10);
    }, [ocppSessions]);

    useEffect(() => {
        calculateTotalKwh(filteredTransactions);
    }, [time, filteredTransactions]);

    const handleClick = () => {
        dispatch(
            setScrollPosition(
                document.documentElement.getElementsByClassName('content')[0]
                    .scrollTop || 0
            )
        );
        navigate('/chargingHistory', { state: { slide: true } });
    };

    return (
        <div>
            <div className="subcontainer" id="charging_history__subcontainer">
                <div
                    className="filterContainer"
                    id="charging_history__filter_container"
                >
                    {ocppSessions.length === 0 ? (
                        <span
                            className="filterText"
                            id="charging_history__filter_empty"
                        >
                            {timeFrames[0].label}
                        </span>
                    ) : (
                        <></>
                    )}
                    {ocppSessions.length > 0 ? (
                        <span
                            className="filterText"
                            id="charging_history__filter_text"
                        >
                            {
                                timeFrames.find((frame) => frame.value === time)
                                    ?.label
                            }
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="chargingHistoryBarContainer__metrics">
                    <span className="sessionsNumber">
                        {filteredTransactions.length || 0}
                    </span>
                    <span className="kwhNumber">
                        {totalKwh > 0 ? totalKwh.toFixed(0) : 0}
                    </span>
                </div>
                <div className="chargingHistoryBarContainer">
                    <hr className="sessionsLine" />
                    <hr className="kwhLine" />
                </div>
                <div
                    className="chargingHistoryBarContainer"
                    style={{ textAlign: 'right' }}
                >
                    <span className="sessionsNumber__text">SESSIONS</span>
                    <span className="kwhNumber__text">KWH</span>
                </div>
                <div
                    id="charging_history_click_handler"
                    onClick={() =>
                        home && ocppSessions.length > 0 ? handleClick() : null
                    }
                >
                    {filteredTransactions
                        .slice(0, 3)
                        .map((ocppSession: any, index: number) => (
                            <Fragment key={ocppSession.id}>
                                <ChargingHistoryItem
                                    key={ocppSession.id}
                                    ocppSession={ocppSession}
                                />
                                {index < 2 && <hr className="fragmentHr" />}
                            </Fragment>
                        ))}
                    {!refreshToken && (
                        <div className="chargingHistoryLoggedOutContainer">
                            <p className="chargingHistoryLoggedOutText">
                                Keep track of charging history
                            </p>
                            <button
                                className="button--secondary"
                                id="charging_history__login"
                                onClick={() =>
                                    navigate('/login', {
                                        state: { slide: true },
                                    })
                                }
                            >
                                Log in
                            </button>
                        </div>
                    )}
                    {refreshToken && home && ocppSessions.length === 0 ? (
                        <p className="chargingHistoryNoSessionsText">
                            No sessions yet
                        </p>
                    ) : (
                        <></>
                    )}
                    {ocppSessions.length > 0 ? (
                        <p className="chargingHistory--link"> View more</p>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withTokenWatcher(ChargingHistory);
