import { store } from '../app/store';

export const numberToDollarFormat = (number: number): string => {
    if (!number) {
        number = 0;
    }
    return number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};

export const calculateTransactionFee = (number: number): number => {
    return number * 0.035 + 0.35;
};

export const formatDateLong = (dateItem: Date): string => {
    const date = new Date(dateItem);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
};

export const formatToDoubleDigit = (num: number): string => {
    return num.toString().padStart(2, '0');
};

export const isiOS = (): boolean => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const formatDuration = (duration: number) => {
    let seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    const hoursStr = hours < 10 ? '0' + hours : hours;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const secondsStr = seconds < 10 ? '0' + seconds : seconds;

    return hoursStr + ':' + minutesStr + ':' + secondsStr;
};

export const roundDown = (num: number): number => {
    return Math.floor(num * 100) / 100;
};

export const mapLink = (address: string): string => {
    const platform = store.getState().app.platformOs;
    let addressPrefix = 'https://maps.google.com';
    if (platform === 'ios') addressPrefix = 'maps:0,0';
    else if (platform === 'android') addressPrefix = 'geo:0,0';
    return addressPrefix + '?q=' + address;
};
