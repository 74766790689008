import * as React from 'react';
// import { Linking, View } from 'react-native'
// import { css } from '@emotion/native'

// import { DefaultScrollableView } from '~/containers/View'
// import { Link } from '~/components/Link'
import {
    Link,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { motion } from 'framer-motion';
import { kill } from 'process';

interface AnswerList {
    topic?: string;
    content?: string;
    ordered_content?: { id: string; content: string }[];
}

interface FAQ {
    id: number;
    question: string;
    answer?: string;
    link?: string;
    email_answer?: string;
    email_answer_2?: string;
    link2?: string;
    email_answer_3?: string;
    answer_list?: AnswerList[];
}

const faqs: FAQ[] = [
    {
        id: 1,
        question: 'What are the different charging statuses?',
        answer_list: [
            {
                topic: 'Preparing: ',
                content:
                    'Station is waiting. Scan the QR code or enter the 5-digit station ID to start a charge. "Preparing" also displays if the station is waiting for a vehicle to plug in.',
            },
            {
                topic: 'Charging: ',
                content: 'Vehicle is receiving power.',
            },
            {
                topic: 'Idle: ',
                ordered_content: [
                    {
                        id: '1',
                        content:
                            'Vehicle is not pulling power - it is fully charged or has reached a pre-set charge limit.',
                    },
                    {
                        id: '2',
                        content:
                            'Vehicle has a schedule and is waiting. A charge will start when the schedule begins.',
                    },
                    {
                        id: '3',
                        content:
                            'Vehicle charge-port is locked. Temporarily unlock vehicle and charge-port to allow the charge to start.',
                    },
                ],
            },
            {
                topic: 'Finishing: ',
                content: 'Charge is stopped or vehicle is being unplugged.',
            },
        ],
    },
    {
        id: 2,
        question: "What if I'm in a location with poor or no signal?",
        answer_list: [
            {
                ordered_content: [
                    {
                        id: '1',
                        content: 'Plug in vehicle',
                    },
                    {
                        id: '2',
                        content: 'Ensure station displays "Preparing"',
                    },
                    {
                        id: '3',
                        content:
                            "Scan QR code and keep the app open until you're back online",
                    },
                ],
            },
            {
                content:
                    'Alternatively, enter the 5-digit Station ID once you are online.',
            },
        ],
    },
    {
        id: 3,
        question: 'How do I start a charging session on a Level 2 station?',
        answer: 'Plug in the vehicle and scan the QR code (or enter the 5-digit Chargie ID) on the station. If a station has an RFID reader, tap the RFID tag.',
    },
    {
        id: 4,
        question: 'How do I end a charge on a Level 2 station?',
        answer: 'Unplug the vehicle or end the charge remotely through the app. If the charge is ended remotely, any penalty fees still apply until the vehicle is unplugged.',
    },
    {
        id: 5,
        question: 'How do I start a charging session on a Level 3 station?',
        answer: 'Plug in the vehicle and provide a payment method. Step-by-step instructions are on the screen and payment terminal.',
    },
    {
        id: 6,
        question: 'How do I end a charge on a Level 3 station?',
        answer_list: [
            {
                content:
                    'Step-by-step instructions are on the screen and payment terminal. The experience may vary if using an adaptor.',
                ordered_content: [
                    {
                        id: '1',
                        content: 'Press "Unlock" on the station',
                    },
                    {
                        id: '2',
                        content:
                            'Tap, swipe, or insert the previous payment method',
                    },
                    {
                        id: '3',
                        content:
                            'Press "Stop" on the station (any penalty fees still apply until the vehicle is unplugged).',
                    },
                ],
            },
        ],
    },
    {
        id: 7,
        question: 'What is Chargie Pro?',
        answer: 'It is a subscription plan ($15/mo) required at select apartment/multifamily locations. All other stations may be accessed without a subscription.',
    },
    {
        id: 8,
        question: 'How do I get an RFID tag?',
        answer: "RFID tags are provided for select locations. Ask your property manager if they're available.",
    },
    {
        id: 9,
        question: 'How much does it cost to charge?',
        answer: 'Rates vary by location and are set by the property owner.',
    },
    {
        id: 10,
        question: 'How do I pay for charging?',
        answer: 'Add a payment method in the app under “Payment” in settings and preload funds. An auto-reload occurs when the balance falls below $0 to ensure your vehicle charges without interruption. Edit the auto-reload amount any time.',
    },
    {
        id: 11,
        question: 'How do I find a station?',
        answer: "To determine if a private location offers our stations, contact the property. We're working to make all our public charging locations available soon. Stay tuned!",
    },
    {
        id: 12,
        question: 'Which EVs are compatible with Chargie charging stations?',
        answer_list: [
            {
                content:
                    'Our Level 2 stations have J1772 (J) plugs and are compatible with all North American vehicles. Our Level 3 fast chargers have both CSS and CHAdeMO plugs.',
            },
            {
                topic: 'Tesla Instructions',
            },
            {
                content:
                    'L2 stations: Use the J plug adapter that comes with the vehicle',
            },
            {
                content:
                    'L3 stations: Use a CSS or CHAdeMO adapter. These are not included with the vehicle.',
            },
        ],
    },
    {
        id: 13,
        question: 'Which plug types are available on the Level 3 stations?',
        answer: 'Our dual-port Level 3 fast-charging stations are equipped with both a CSS and CHAdeMO plug. If you drive a Tesla, an adapter is required.',
    },
    {
        id: 14,
        question: 'Do I need to reserve a charger in advance?',
        answer: 'No reservations necessary. However, stations in some locations may be assigned to specific residents.',
    },
    {
        id: 15,
        question: 'How do I delete my Chargie account?',
        answer_list: [
            {
                content:
                    'Navigate to Settings in the app. Tap Account, then tap "Delete Account" to proceed. Deleting your account ends any active sessions and permanently removes your profile, transaction history, and all other personal information.',
            },
            {
                content:
                    'Remaining account balances are refunded to the original payment method in 3-5 business days. To create another account in the future, you may register with the same email address.',
            },
        ],
    },
    {
        id: 16,
        question: 'How do I contact support?',
        answer: 'Visit the Support page within the app to get in touch or chat with our support team.',
    },
];

const App: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <motion.div
            id="fag_page"
            className="container--navless"
            initial={
                location.state?.slide === true
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="fag_page_header">
                <div className="header__content" id="fag_page_content">
                    <div
                        id="fag_page_back"
                        className="header__back"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <BackIcon id="fag_page_back_icon"></BackIcon>
                    </div>
                    FAQs
                </div>
            </div>
            <div className="content">
                {faqs.map((faq) => (
                    <div key={faq.id}>
                        <div className="page-header">{faq.question}</div>
                        {faq.answer && <div>{faq.answer}</div>}
                        {faq.link && (
                            <div>
                                {faq.email_answer}{' '}
                                <a
                                    className="link link--blue link--initial-size"
                                    href="mailto:support@chargie.com"
                                >
                                    {faq.link}
                                </a>
                                {faq.email_answer_2}
                            </div>
                        )}
                        {faq.link2 && (
                            <div>
                                <a
                                    className="link link--blue link--initial-size"
                                    href="mailto:support@chargie.com"
                                >
                                    {faq.link2}
                                </a>
                                {faq.email_answer_2}
                            </div>
                        )}
                        {faq.answer_list && faq.answer_list.length > 0 && (
                            <div className="page-text">
                                {faq.answer_list.map((a_list) => (
                                    <div key={a_list.topic}>
                                        {(a_list.topic || a_list.content) && (
                                            <div>
                                                {a_list.topic && (
                                                    <div>{a_list.topic}</div>
                                                )}
                                                {a_list.content && (
                                                    <div>{a_list.content}</div>
                                                )}
                                            </div>
                                        )}
                                        {a_list.ordered_content &&
                                            a_list.ordered_content.map((li) => (
                                                <div key={li.id}>
                                                    {li.id}.{li.content}
                                                </div>
                                            ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </motion.div>
    );
};

export default App;
