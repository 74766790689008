import { ReactComponent as Checkmark } from '../assets/checkmark.svg';
import { RadioOptions } from './RadioButtons';

interface RadioProps {
    options: RadioOptions[];
    selectedValue: string;
    onChange: (value: string) => void;
}

const RadioButtons: React.FC<RadioProps> = ({
    options,
    selectedValue,
    onChange,
}) => {
    const handleChange = (value: string) => {
        onChange(value);
    };

    return (
        <div className="radio-list">
            {options.map((option, index) => (
                <label
                    key={index}
                    className="radio-item"
                    id={option.value + '_radio_list_label'}
                >
                    <input
                        type="radio"
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={() => handleChange(option.value)}
                        className="payment-radio-input"
                        id={option.value + '_radio_list_input'}
                    />
                    <span
                        className="payment-list-item"
                        id={option.value + '_radio_list_text'}
                    ></span>

                    {selectedValue === option.value && (
                        <div className="payment-list-item-checked" />
                    )}
                    <div className="radio-text">
                        <div className="">{option.label}</div>
                    </div>
                </label>
            ))}
        </div>
    );
};

export default RadioButtons;
