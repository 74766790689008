import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import {
    selectAccessToken,
    selectBiometricsExists,
    selectBiometricsType,
    selectEmail,
    selectFirstName,
    selectHistory,
    selectIsSubscribed,
    selectLastName,
    setFirstName,
    setLastName,
} from '../../appSlice';
import { ReactComponent as Arrow } from '../../assets/RightArrow.svg';
import {
    deleteBiometrics,
    logout,
    setupBiometrics,
} from '../../utils/webview/messages';
import { motion } from 'framer-motion';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/Edit.svg';

import { useForm } from 'react-hook-form';
import { store } from '../../app/store';
import PrimaryButton from '../../components/PrimaryButton';

const deleteAccountMutation = loader('./deleteAccount.graphql');

interface NameFormValues {
    name: string;
}

const DeletePage = () => {
    const accessToken = useAppSelector(selectAccessToken);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const [showAdmin, setShowAdmin] = useState(false);
    const [hasDiscounts, setHasDiscounts] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const history = useAppSelector(selectHistory);

    const navigate = useNavigate();

    const [deleteAccount, { data, error, loading }] = useMutation(
        deleteAccountMutation
    );

    useEffect(() => {
        if (data) {
            logout();
            navigate('/', { replace: true });
        } else if (error) {
            // alert(error.message);
        }
    }, [data, error, navigate]);

    useEffect(() => {
        try {
            if (accessToken) {
                const decoded = jwtDecode<{ sub: string; role: string }>(
                    accessToken
                );
                setShowAdmin(decoded.role === 'Admin');
            }
        } catch {}
    }, [accessToken]);

    const handleInputClick = async () => {
        setIsOpen(true);
        await deleteAccount();
    };

    return (
        <motion.div
            className="container"
            id="delete_page"
            initial={
                history.length > 0 && history[1] === '/account'
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="delete_page_header">
                <div
                    className="header__back"
                    onClick={() => {
                        navigate(-1);
                    }}
                    id="delete_page_back"
                >
                    <BackIcon id="delete_page_icon"></BackIcon>
                </div>
                <div id="delete_page_title" className="header__content">
                    DELETE ACCOUNT
                </div>
            </div>
            <div className="page">
                <div className="page-header">Are you sure?</div>
                <div className="page-text">
                    Deleting your account will end any active sessions,
                    permanently remove your profile, transaction history, and
                    all other associated information.
                </div>
                <div className="page-text" style={{ marginBottom: 60 }}>
                    If applicable, any remaining account balance will be
                    refunded to the original payment method in 3-5 business
                    days.
                </div>

                <PrimaryButton
                    id="delete_page_confirm"
                    title="Delete account"
                    onPress={handleInputClick}
                />
            </div>
            {isOpen && (
                <div className="modal">
                    <div
                        className="modal__background"
                        id="delete_page_modal_background"
                    >
                        <div className="full-modal">
                            Please do not close the app as we process your
                            request
                            <div className="lg-loader" />
                        </div>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default DeletePage;
