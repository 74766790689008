import React from 'react';
import Station from '../../models/Station';

interface PricingTitleProps {
    station: Station | undefined;
}

const PricingTitle: React.FC<PricingTitleProps> = ({ station }) => {
    return (
        <div className="pricing__current-price">
            <p className="pricing__price-title">Station {station?.qrCode}</p>
            <p className="pricing__details">
                7.68 kW | Load share {station?.clusterCount}:1
            </p>
        </div>
    );
};

export default PricingTitle;
