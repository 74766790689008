import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { extractPropertyData } from '../../models/PropertyMap';
import Station from '../../models/Station';
import Pricing from '../../models/Pricing';
import formatNumberAsCurrency from '../../utils/formatNumberAsCurrency';
import { motion } from 'framer-motion';
import { logToNative } from '../../utils/webview/messages';
import { mapLink } from '../../utils/utility';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    propertyId: string;
}

interface PricingDetails {
    available: number;
    total: number;
    minPrice: number;
    maxPrice: number;
    policy: string;
}

const getProperty = loader('./getProperty.graphql');

const MapModal: React.FC<ModalProps> = ({ isOpen, onClose, propertyId }) => {
    const [titleL2, setTitleL2] = useState('');
    const [titleL3, setTitleL3] = useState('');
    const [address, setAddress] = useState('');

    const [privateStationCount, setPrivateStationCount] = useState('');
    const [privateStationPrice, setPrivateStationPrice] = useState('');
    const [privateSub, setPrivateSub] = useState(false);

    const [publicStationCount, setPublicStationCount] = useState('');
    const [publicStationPrice, setPublicStationPrice] = useState('');
    const [publicSub, setPublicSub] = useState(false);

    const {
        error: getPropertyError,
        data: getPropertyData,
        loading: getPropertyLoading,
    } = useQuery(getProperty, {
        variables: {
            filter: {
                id: propertyId,
            },
            stationFilter: {
                allowPublic: true,
                allowPrivate: true,
                allowLvl2: true,
                allowLvl3: true,
            },
        },
        skip: !propertyId,
        fetchPolicy: 'cache-and-network',
    });

    const resetAll = () => {
        setTitleL2('');
        setTitleL3('');
        setPublicStationCount('');
        setPublicStationPrice('');
        setPrivateStationCount('');
        setPrivateStationPrice('');
        setPrivateSub(false);
        setPublicSub(false);
    };

    const checkAvailable = (station: Station) => {
        if (station.lastCommunication) {
            const now = new Date();
            const lastMessage = new Date(station.lastCommunication);
            const difference = Math.abs(now.getTime() - lastMessage.getTime());
            // checks if status is available & last pint time was less than 3 minutes
            if (
                station.ocppStatus.toLowerCase() === 'available' &&
                difference < 180000
            )
                return 1;
        }
        return 0;
    };

    const calcPricing = (pricing: Pricing, details: PricingDetails) => {
        if (details.policy === 'mixed') return;
        if (!pricing) return;
        const currentPricing = pricing.policy.toLowerCase();
        if (details.policy === '')
            details.policy = currentPricing === 'tou' ? 'kwh' : currentPricing;
        else if (
            (details.policy === 'hour' && currentPricing !== 'hour') ||
            (details.policy === 'kwh' && currentPricing === 'hour')
        )
            details.policy = 'mixed';

        if (currentPricing === 'tou' && pricing.jsonData) {
            pricing.jsonData.policy.map((item) =>
                item.phases.map((ph) => {
                    if (details.minPrice === -1 || details.minPrice > ph.rate)
                        details.minPrice = ph.rate;
                    if (details.maxPrice < ph.rate) details.maxPrice = ph.rate;
                    return 0;
                })
            );
        } else if (pricing.rate || pricing.rate === 0) {
            if (details.minPrice === -1 || details.minPrice > pricing.rate)
                details.minPrice = pricing.rate;
            if (details.maxPrice < pricing.rate)
                details.maxPrice = pricing.rate;
        }
    };

    const calcTotal = (
        details: PricingDetails,
        setCountMessage: React.Dispatch<React.SetStateAction<string>>,
        setPriceMessage: React.Dispatch<React.SetStateAction<string>>
    ) => {
        if (details.total > 0) {
            setCountMessage(
                `${details.available} of ${details.total} available`
            );
            if (details.policy === 'mixed') setPriceMessage('Prices may vary');
            else {
                if (details.minPrice === -1) details.minPrice = 0;
                if (details.minPrice === details.maxPrice) {
                    setPriceMessage(
                        `${formatNumberAsCurrency(details.minPrice)} / ${details.policy === 'kwh' ? 'kWh' : 'Hour'}`
                    );
                } else {
                    setPriceMessage(
                        `${formatNumberAsCurrency(details.minPrice)} - ${formatNumberAsCurrency(details.maxPrice)} / ${details.policy === 'kwh' ? 'kWh' : 'Hour'}`
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (getPropertyData) {
            resetAll();
            const property = extractPropertyData(getPropertyData);
            setAddress(property.address1);
            let countL2 = 0;
            let countL3 = 0;

            const publicPricing = {
                available: 0,
                total: 0,
                minPrice: -1,
                maxPrice: 0,
                policy: '',
            };

            const privatePricing = {
                available: 0,
                total: 0,
                minPrice: -1,
                maxPrice: 0,
                policy: '',
            };
            // Calculates pricing based off of property or org prices
            if (property.pricing) {
                calcPricing(property.pricing, publicPricing);
                calcPricing(property.pricing, privatePricing);
            } else if (property.inheritedPricingFrom) {
                calcPricing(property.inheritedPricingFrom, publicPricing);
                calcPricing(property.inheritedPricingFrom, privatePricing);
            }
            // Calculates pricing from each station
            property.stations.map((station) => {
                if (station.itemStatus === 'ACTIVE') {
                    if (station.stationLevel === 3) {
                        countL3 += 1;
                    } else {
                        countL2 += 1;
                    }
                    const pricing =
                        station.pricing || station.inheritedPricingFrom;

                    if (
                        station.stationType === null ||
                        (station.stationType &&
                            station.stationType.toLowerCase() !== 'public')
                    ) {
                        privatePricing.total += 1;
                        privatePricing.available += checkAvailable(station);
                        if (pricing) calcPricing(pricing, privatePricing);
                        if (station.isSubscription) setPrivateSub(true);
                    } else {
                        publicPricing.total += 1;
                        publicPricing.available += checkAvailable(station);
                        if (pricing) calcPricing(pricing, publicPricing);
                        if (station.isSubscription) setPublicSub(true);
                    }
                }
                return 0;
            });
            if (countL2 > 0) setTitleL2(`${countL2} Level 2 • 7.68 kW Max`);
            if (countL3 > 0) setTitleL3(`${countL3} Level 3 • 50 kW Max`);

            calcTotal(
                privatePricing,
                setPrivateStationCount,
                setPrivateStationPrice
            );
            calcTotal(
                publicPricing,
                setPublicStationCount,
                setPublicStationPrice
            );
        } else if (getPropertyError) {
            logToNative('could not get property data');
        }
    }, [propertyId, getPropertyData, getPropertyError]);

    return (
        <>
            {isOpen && (
                <motion.div
                    initial={{ top: '100%' }}
                    animate={{ top: 'auto' }}
                    className="modal modal--clear"
                    id="map_modal"
                >
                    <div className="modal__map">
                        <div className="modal__close-container">
                            <p
                                className="modal__close"
                                onClick={onClose}
                                id="map_modal_close"
                            >
                                &times;
                            </p>
                        </div>
                        {getPropertyLoading && (
                            <p className="modal__map--title">Loading...</p>
                        )}
                        {!getPropertyLoading && (
                            <div>
                                {titleL2 !== '' && (
                                    <p className="modal__map--title">
                                        {titleL2}
                                    </p>
                                )}
                                {titleL3 !== '' && (
                                    <p className="modal__map--title">
                                        {titleL3}
                                    </p>
                                )}
                                <a
                                    href={mapLink(encodeURI(address))}
                                    className="modal__map--subtitle"
                                >
                                    {address}
                                </a>

                                <div>
                                    {publicStationCount !== '' && (
                                        <div className="modal__map--pricing">
                                            <div className="modal__map--header">
                                                <div className="modal__map--public-header">
                                                    Public
                                                </div>
                                                {publicSub && (
                                                    <div className="modal__map--subscription-header">
                                                        Subscription Required
                                                    </div>
                                                )}
                                            </div>
                                            <div className="modal__map--available">
                                                {publicStationCount}
                                            </div>
                                            <div className="modal__map--pricing">
                                                {publicStationPrice}
                                            </div>
                                        </div>
                                    )}
                                    {privateStationCount !== '' && (
                                        <div className="modal__map--pricing">
                                            <div className="modal__map--header">
                                                <div className="modal__map--private-header">
                                                    Private
                                                </div>
                                                {privateSub && (
                                                    <div className="modal__map--subscription-header">
                                                        Subscription Required
                                                    </div>
                                                )}
                                            </div>
                                            <div className="modal__map--available">
                                                {privateStationCount}
                                            </div>
                                            <div className="modal__map--pricing">
                                                {privateStationPrice}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="modal__map--disclaimer">
                            * Rates set by property. Penalty and parking fees
                            may apply.
                        </div>
                    </div>
                    <div className="modal__content--inset--map" />
                </motion.div>
            )}
        </>
    );
};

export default MapModal;
