import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

// Axios instance with a 5 minute cache
export const longAxios = setupCache(Axios.create(), {
    headerInterpreter: () => 1000 * 60 * 5,
    methods: ['get', 'head', 'post'],
});

const newAxiosInstance = Axios.create();
// Intercept the error and embed the error in the response for typescript syntax validation
newAxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        error.response.data.axiosError = error;
        return Promise.resolve(error.response);
    }
);

// Axios instance with a 5 second cache, use for most calls
export const axios = setupCache(newAxiosInstance, {
    ttl: 5000,
    interpretHeader: false,
    methods: ['get', 'head', 'post'],
    cachePredicate: {
        statusCheck: () => true,
    },
    staleIfError: 5000,
});

export const getTemporaryToken = async () =>
    longAxios.post<{ access_token: string }>(
        `${process.env.REACT_APP_AUTH_ENDPOINT}/mobile/token`
    );
export const refreshAccessToken = async (
    access_token: string,
    refresh_token: string,
    headers: {
        [key: string]: string | null | undefined;
    }
) =>
    longAxios.post<{ access_token: string }>(
        `${process.env.REACT_APP_AUTH_ENDPOINT}/refresh`,
        {
            access_token,
            refresh_token,
        },
        { headers }
    );
