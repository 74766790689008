import React from 'react';
import lazyImage from '../../assets/sloth.png'

const App:React.FC = () => {
  return (
    <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}} className='container'>
        <img style={{width: 150, height: 150}}src={lazyImage} alt = "why didn't you load the sloth?"/>
       <div style={{fontSize: 20, padding: 30, textAlign: 'center'}}>We're sorry, this page is still under construction</div>
    </div>
  );
}

export default App;
