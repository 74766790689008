import React, { useState } from 'react';
import PhoneInput, {
    Country,
    isPossiblePhoneNumber,
} from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { setContactInformation } from '../../guestSlice';
import PrimaryButton from '../PrimaryButton';

const availableCountries: Array<Country> = ['US', 'CA', 'MX'];

const GuestContact: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [value, setValue] = useState<string>();
    const [validNumber, setValidNumber] = useState(false);
    const [showBubble, setShowBubble] = useState(false);

    const validatePhoneNumber = (input: any) => {
        try {
            setValue(input);
            if (isPossiblePhoneNumber(input)) {
                setValidNumber(true);
                dispatch(setContactInformation(input));
            } else {
                setValidNumber(false);
            }
        } catch (e: any) {
            // do nothing
        }
    };

    const handleSubmit = () => {
        navigate('/guest/payment', { state: { slide: true } });
    };

    const goBack = () => {
        navigate('/guest/checkout');
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const clickedElement = event.target as HTMLElement;
        if (showBubble) {
            setShowBubble(false);
        }
    };

    return (
        <div className="guest-pricing-content" onClick={handleClick}>
            <div className="pricing-details-main-content">
                <div className="pricing__current-price">
                    <p
                        className="pricing__price-title"
                        id="guest_contact__title"
                    >
                        Contact information
                    </p>
                </div>
                <div className="guestCheckout__contact">
                    <PhoneInput
                        className=""
                        value={value}
                        countries={availableCountries}
                        defaultCountry={'US'}
                        id="phone"
                        placeholder="Enter phone number"
                        addInternationalOption={false}
                        onChange={validatePhoneNumber}
                        style={{
                            borderColor: validNumber ? 'green' : 'red',
                        }}
                    />
                </div>
                <div
                    className="guestCheckout__contact-modal"
                    onClick={() => setShowBubble(true)}
                    id="guest_contact__contact_modal"
                >
                    <p
                        className="bubble-click"
                        id="guest_contact__help_question"
                    >
                        Why do we need this?
                    </p>
                    {showBubble && (
                        <div className="bubble" id="guest_contact__bubble">
                            Your number is used for text updates and receipts.
                        </div>
                    )}
                </div>
            </div>
            <div className="modal__button">
                <PrimaryButton
                    title={'Proceed to payment'}
                    onPress={handleSubmit}
                    disabled={!validNumber}
                    id="guest_contact__payment_button"
                />
                <button
                    className="payment__back-button"
                    onClick={() => goBack()}
                    id="guest_contact__back_button"
                >
                    Back
                </button>
            </div>
        </div>
    );
};

export default GuestContact;
