import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { motion } from 'framer-motion';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { ReactComponent as ButtonBadge } from '../../assets/Button-badge.svg';

import SettingsButton, {
    SettingsConfig,
} from '../../components/SettingsButton';
import { colors } from '../../global/variables';
import PrimaryButton from '../../components/PrimaryButton';
import { hideScanner } from '../../utils/webview/messages';
import { useAppSelector } from '../../app/hooks';
import {
    selectHistory,
    selectRfidError,
    setDesiredRFID,
    setRfidError,
} from '../../appSlice';
import { store } from '../../app/store';
import { logEvent } from 'firebase/analytics';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { analytics, firebaseSessionId } from '../../utils/firebase';

const fetchRfidQuery = loader('./fetchRFID.gql');
const removeRfidMutation = loader('./removeRfid.gql');
const reactivateRfidMutation = loader('./reactivateRfid.gql');

const RfidPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    // const [errorOpen, setErrorOpen] = useState(false);
    const [rfid, setRfid] = useState('');
    const [rfidOptions, setRfidOptions] = useState<SettingsConfig[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    const history = useAppSelector(selectHistory);
    const errorOpen = useAppSelector(selectRfidError);
    const tryDeleteRfid = (val: string) => {
        setRfid(val);
        setIsOpen(true);
    };

    const {
        error: rfidError,
        data: rfidData,
        refetch: refetchRfid,
    } = useQuery(fetchRfidQuery, {
        fetchPolicy: 'no-cache',
    });

    const [
        removeRfid,
        {
            data: removeRfidData,
            error: removeRfidError,
            loading: removeRfidLoading,
        },
    ] = useMutation(removeRfidMutation);

    const [
        reactivate,
        {
            data: reactivateData,
            error: reactivateError,
            loading: reactiveLoading,
        },
    ] = useMutation(reactivateRfidMutation);

    useEffect(() => {
        hideScanner();
        if (rfidData && rfidData.loggedInUserRFIDS) {
            const rfidArr: SettingsConfig[] = [];
            rfidData.loggedInUserRFIDS.map((item: any) => {
                const rfidEle: SettingsConfig = {
                    value: item.rawRfid,
                    text:
                        item.itemStatus === 'ACTIVE' ? 'Delete' : 'Reactivate',
                    callback:
                        item.itemStatus === 'ACTIVE'
                            ? () => deleteRfid(item.id)
                            : () => reactivateRfid(item.id),
                };
                rfidArr.push(rfidEle);
                return 0;
            });
            setRfidOptions(rfidArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rfidData]);

    const hideModal = () => {
        setIsOpen(false);
    };

    const deleteRfid = async (val: string) => {
        try {
            await removeRfid({
                variables: { removeRfidId: val },
            });
            await refetchRfid();
        } catch (error: any) {
            const currentDate = new Date();
            const token = store.getState().app.accessToken;
            logEvent(analytics, 'MA_4.0_delete_rfid_error', {
                time: currentDate.toLocaleString(),
                sessionId: firebaseSessionId,
                userId: jwtDecode<JwtPayload>(token || '').sub,
                rfid_value: val,
                error: error,
            });
        }
    };

    const reactivateRfid = async (val: string) => {
        try {
            await reactivate({
                variables: { reactivateRfidId: val },
            });
            await refetchRfid();
        } catch (error: any) {
            const currentDate = new Date();
            const token = store.getState().app.accessToken;
            logEvent(analytics, 'MA_4.0_reactivate_rfid_error', {
                time: currentDate.toLocaleString(),
                sessionId: firebaseSessionId,
                userId: jwtDecode<JwtPayload>(token || '').sub,
                rfid_value: val,
                error: error,
            });
        }
    };

    const closeErrorModal = () => {
        store.dispatch(setRfidError(false));
        store.dispatch(setDesiredRFID(undefined));
    };

    return (
        <motion.div
            className="container"
            initial={
                history.length > 0 && history[1] === '/account'
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
            id="rfid_page"
        >
            <div className="header" id="rfid_page_header">
                <div
                    className="header__back"
                    onClick={() => {
                        closeErrorModal();
                        navigate(-1);
                    }}
                    id="rfid_page_back"
                >
                    <BackIcon id="rfid_page_back_icon"></BackIcon>
                </div>
                <div className="header__content" id="rfid_page_header_content">
                    MANAGE RFID
                </div>
            </div>
            <div className="page">
                <div
                    className="title"
                    style={{ marginTop: 20 }}
                    id="rfid_page_title"
                >
                    RFID tags
                </div>
                <SettingsButton options={rfidOptions} />
                <div className="button-badge">
                    <ButtonBadge
                        id="rfid_page_button_badge"
                        className="button-badge"
                        onClick={() => navigate('/scanrfid')}
                    />
                </div>
            </div>
            {errorOpen && (
                <div className="modal" id="rfid_page_modal">
                    <div
                        id="rfid_page_modal_background"
                        className="modal__background"
                        onClick={() => {
                            closeErrorModal();
                        }}
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content"
                        id="rfid_page_modal_content"
                    >
                        <div className="modal__close-container">
                            <div
                                id="rfid_page_modal_close"
                                className="modal__close"
                                onClick={() => closeErrorModal()}
                            >
                                <CloseIcon
                                    fill={colors.inactiveWhite}
                                    id="rfid_page_modal_close_icon"
                                />
                            </div>
                        </div>
                        <div
                            className="large bold"
                            style={{ marginBottom: 20 }}
                        >
                            Request failed
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            Looks like we had trouble processing this request.
                            Please try again. If the issue persists, contact
                            support.
                        </div>
                        <PrimaryButton
                            title="Got it"
                            onPress={() => closeErrorModal()}
                            id="rfid_page_close_error_modal"
                        />
                    </motion.div>
                    <div className="modal__content--inset" />
                </div>
            )}
            {isOpen && (
                <div className="modal">
                    <div
                        className="modal__background"
                        onClick={hideModal}
                        id="rfid_page_modal_delete_background"
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content"
                        id="rfid_page_modal_delete"
                    >
                        <div className="modal__close-container">
                            <div
                                className="modal__close"
                                onClick={hideModal}
                                id="rfid_page_modal_delete_close"
                            >
                                <CloseIcon
                                    fill={colors.inactiveWhite}
                                    id="rfid_page_modal_delete_close_icon"
                                />
                            </div>
                        </div>
                        <div className="large">Delete RFID</div>
                        <div style={{ marginBottom: 20 }}>
                            Are you sure you want to delete RFID {rfid}?
                        </div>
                        <PrimaryButton
                            title="Yes, delete"
                            onPress={() => deleteRfid(rfid)}
                            id="rfid_page_modal_delete_confirm"
                        />
                        <button
                            style={{ marginTop: 10 }}
                            className="tertiaryButton"
                            onClick={hideModal}
                            id="rfid_page_modal_delete_cancel"
                        >
                            Go back
                        </button>
                    </motion.div>
                    <div className="modal__content--inset" />
                </div>
            )}
        </motion.div>
    );
};

export default RfidPage;
