import Pricing from './Pricing';
import Station from './Station';

export type Properties = {
    id: string;
    name: string;
    address1: string;
    latitude: number;
    longitude: number;
    stations: [Station];
};

type GetPropertyMapDataResponse = {
    properties: Properties[];
};

export const extractPropertiesData = (data: GetPropertyMapDataResponse) => {
    return data && data.properties;
};

export type Property = {
    id: string;
    name: string;
    address1: string;
    pricing: Pricing;
    inheritedPricingFrom: Pricing;
    stations: [Station];
};

type GetPropertyResponse = {
    property: Property;
};

export const extractPropertyData = (data: GetPropertyResponse) => {
    return data && data.property;
};
