import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Toggle from '../../components/Toggle';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    publicFilter: boolean;
    privateFilter: boolean;
    lvl2Filter: boolean;
    lvl3Filter: boolean;
    onOptionChange: (option: string) => void;
}

const MapFilter: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    publicFilter,
    privateFilter,
    lvl2Filter,
    lvl3Filter,
    onOptionChange,
}) => {
    useEffect(() => {}, []);

    return (
        <>
            {isOpen && (
                <motion.div
                    initial={{ top: '100%' }}
                    animate={{ top: 'auto' }}
                    className="modal modal--clear modal__filter"
                    id="map_filter"
                >
                    <div className="modal__map" id="map_filter_container">
                        <div className="modal__close-container">
                            <p
                                className="modal__close"
                                onClick={onClose}
                                id="map_filter_close_icon"
                            >
                                &times;
                            </p>
                        </div>
                        <div>
                            <p className="modal__map--title">Filter</p>
                            <ul className="modal__radio">
                                <li className="modal__radio-li">
                                    <Toggle
                                        id="map_filter_public"
                                        value={publicFilter}
                                        onChange={() =>
                                            onOptionChange('publicAccess')
                                        }
                                    />
                                    <label
                                        id="map_filter_public_label"
                                        className="modal__filter modal__radio-label"
                                        htmlFor="publicAccess"
                                    >
                                        Publicly Accessible
                                    </label>
                                </li>
                                <li className="modal__filter modal__radio-li">
                                    <Toggle
                                        id="map_filter_private"
                                        value={privateFilter}
                                        onChange={() =>
                                            onOptionChange('privateAccess')
                                        }
                                    />
                                    <label
                                        id="map_filter_private_label"
                                        className="modal__filter modal__radio-label"
                                        htmlFor="privateAccess"
                                    >
                                        Private Access
                                    </label>
                                </li>
                                <li className="modal__radio-li">
                                    <Toggle
                                        id="map_filter_l2"
                                        value={lvl2Filter}
                                        onChange={() => onOptionChange('lvl2')}
                                    />
                                    <label
                                        id="map_filter_l2_label"
                                        className="modal__filter modal__radio-label"
                                        htmlFor="lvl2"
                                    >
                                        Level 2 - J1772
                                    </label>
                                </li>
                                <li className="modal__radio-li">
                                    <Toggle
                                        id="map_filter_l3"
                                        value={lvl3Filter}
                                        onChange={() => onOptionChange('lvl3')}
                                    />
                                    <label
                                        id="map_filter_l3_label"
                                        className="modal__filter modal__radio-label"
                                        htmlFor="lvl3"
                                    >
                                        Level 3 - CCS/CHAdeMO
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="modal__content--inset--map" />
                </motion.div>
            )}
        </>
    );
};

export default MapFilter;
