import { colors, sizes } from '../global/variables';
import { ReactComponent as Error } from '../assets/icons/error.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/CloseIcon.svg';
import { ReactComponent as Success } from '../assets/ChargeSuccess.svg';
import { motion } from 'framer-motion';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    success: boolean;
    title: string;
    description: string;
}

const SuccessModal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    success,
    title,
    description,
}) => {
    return (
        <>
            {isOpen && (
                <div className="modal" id="error_modal">
                    <div
                        className="modal__background"
                        onClick={onClose}
                        id="error_modal_background"
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content-error"
                        id="error_modal_close_content"
                    >
                        <div
                            className="modal__close-container"
                            id="error_modal_close_container"
                        >
                            <div
                                className="modal__close"
                                onClick={onClose}
                                id="error_modal_close"
                            >
                                <CloseIcon
                                    fill={colors.inactiveWhite}
                                    id="error_modal_close_icon"
                                />
                            </div>
                        </div>
                        {success === false && (
                            <Error
                                className="modal__icon"
                                id="error_modal_icon"
                            />
                        )}
                        {success === true && (
                            <Success
                                className="modal__icon"
                                id="modal_success_icon"
                            />
                        )}
                        <p className="modal__title" id="error_modal_title">
                            {title}
                        </p>
                        <p className="modal__message" id="error_modal_message">
                            {description}
                        </p>
                    </motion.div>
                    <div className="modal__content--inset" />
                </div>
            )}
        </>
    );
};

export default SuccessModal;
