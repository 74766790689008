import React, { useEffect, useState } from 'react';
import {
    Link,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/RightArrow.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';

import { useAppSelector } from '../../app/hooks';

import { motion } from 'framer-motion';
import SecurityPage from './SecurityPage';
import Biometrics from './Biometrics';
import { store } from '../../app/store';
import { setShowNavigator } from '../../appSlice';

const App: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        store.dispatch(setShowNavigator(false));
    }, []);
    return (
        <motion.div
            className="container"
            initial={
                location.state && location.state.slide === true
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            id="privacy_page"
            animate={{ marginLeft: 0 }}
        >
            <div className="header">
                <div className="header__content">
                    <div
                        className="header__back"
                        onClick={() => {
                            navigate(-1);
                        }}
                        id="privacy_page_back"
                    >
                        <BackIcon id="privacy_page_icon"></BackIcon>
                    </div>
                    PRIVACY & SECURITY
                </div>
            </div>
            <div className="page">
                <Routes>
                    <Route path="/security" element={<SecurityPage />} />
                    <Route path="/biometrics" element={<Biometrics />} />
                </Routes>
            </div>
        </motion.div>
    );
};

export default App;
