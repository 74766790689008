import { ApolloProvider } from '@apollo/client';
import React, { Suspense } from 'react';
import './App.css';
import TabNavigator from './components/Navigator';
import './styles/index.scss';
import client from './utils/api';
import AnimatedRoutes from './components/Routes';
import 'react-phone-number-input/style.css';
import { WebSocketProvider } from './components/ChargeProvider';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { firebaseConfig, recordClick } from './utils/firebase';
import ErrorBoundary from './ErrorBoundary';
import { RemoteStartProvider } from './components/RemoteStartProvider';
const App = () => {
    return (
        <Suspense>
            <ErrorBoundary>
                <ApolloProvider client={client}>
                    <div className="main" onClick={recordClick}>
                        <WebSocketProvider>
                            <RemoteStartProvider>
                                <AnimatedRoutes />
                            </RemoteStartProvider>
                        </WebSocketProvider>
                        <TabNavigator />
                    </div>
                </ApolloProvider>
            </ErrorBoundary>
        </Suspense>
    );
};

export default App;
