import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { store } from '../../app/store';
import {
    creditCardCheckout,
    hidePaymentModal,
    logToNative,
    platformPayCheckout,
    setSignupSubscription,
} from '../../utils/webview/messages';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import RadioButtons, { RadioOptions } from '../../components/RadioButtons';
import { Guest } from '../../utils/enums';
import { isiOS } from '../../utils/utility';
import {
    selectAccessToken,
    selectHasPaymentMethod,
    selectModalBackground,
    setIsSubscribed,
    setSubscriptionStarted,
} from '../../appSlice';
import { useAppSelector } from '../../app/hooks';
import { selectPaymentMethod } from '../../guestSlice';
import { useMutation, useQuery } from '@apollo/client';
import { logEvent } from 'firebase/analytics';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { analytics, firebaseSessionId } from '../../utils/firebase';

const createSubscriptionMutation = loader('./createSubscription.gql');
const addPaymentMutation = loader('../PaymentPage/addPaymentMethod.gql');
const getUserDetails = loader('./getUserDetails.gql');

const GuestPricing: React.FC = () => {
    const accessToken = useAppSelector(selectAccessToken);
    const hasPaymentMethod = useAppSelector(selectHasPaymentMethod);
    const navigate = useNavigate();
    const [platformError, setPlatformError] = useState<string | undefined>(
        undefined
    );
    const [subscribeAvailable, setSubscribeAvailable] = useState(true);
    const [ccError, setCCError] = useState<string | undefined>(undefined);
    const modalBackground = useAppSelector(selectModalBackground);
    const paymentMethod = useAppSelector(selectPaymentMethod);
    const [createSubscription, { data, error, loading }] = useMutation(
        createSubscriptionMutation
    );
    const [hasValidCard, setHasValidCard] = useState(false);

    useEffect(() => {
        setSignupSubscription(false);
        store.dispatch(setSubscriptionStarted(false));
    }, []);

    const [
        addPayment,
        {
            data: addPaymentData,
            error: addPaymentError,
            loading: addPaymentLoading,
        },
    ] = useMutation(addPaymentMutation);

    const { data: getUserData } = useQuery(getUserDetails);

    const handleSubmit = async () => {
        setSubscribeAvailable(false);
        try {
            if (accessToken && hasPaymentMethod) {
                await createSubscription().finally(() => {
                    navigate('/', { replace: true });
                    store.dispatch(setIsSubscribed(true));
                });
            } else {
                if (selectedPaymentMethod === Guest.PLATFORM_PAY) {
                    platformPayCheckout();
                    if (paymentMethod) {
                        const paymentRes = await addPayment({
                            variables: {
                                paymentMethodId: paymentMethod,
                            },
                        });
                        if (paymentRes.data.addPaymentMethod)
                            await createSubscription().finally(() => {
                                navigate('/', { replace: true });
                            });
                    } else {
                        setSubscribeAvailable(true);
                        setPlatformError('Payment failed. Please try again.');
                    }
                } else if (selectedPaymentMethod === Guest.CREDIT_CARD) {
                    if (paymentMethod) {
                        const paymentRes = await addPayment({
                            variables: {
                                paymentMethodId: paymentMethod,
                            },
                        });
                        if (paymentRes.data.addPaymentMethod)
                            await createSubscription().finally(() => {
                                navigate('/', { replace: true });
                            });
                    } else {
                        setSubscribeAvailable(true);
                        setCCError('Payment failed. Please try again.');
                    }
                }
            }
        } catch (e) {
            const currentDate = new Date();
            const token = store.getState().app.accessToken;
            logEvent(analytics, 'MA_4.0_map_subscribe_pay_error', {
                time: currentDate.toLocaleString(),
                sessionId: firebaseSessionId,
                userId: jwtDecode<JwtPayload>(token || '').sub,
                error: e,
            });
        }
    };

    useEffect(() => {
        if (getUserData && getUserData.loggedInUser) {
            if (getUserData.loggedInUser.isCardOnFile === true) {
                setHasValidCard(true);
            } else {
                setHasValidCard(false);
            }
        }
    }, [getUserData]);

    const [selectedOption, setSelectedOption] = useState<string>('cardOnFile');
    const options: RadioOptions[] = [
        {
            label: 'Card on file',
            value: 'cardOnFile',
        },
    ];

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
        Guest.PLATFORM_PAY
    );
    const paymentOptions: RadioOptions[] = [
        {
            label: isiOS() ? 'Apple Pay' : 'Google Pay',
            value: Guest.PLATFORM_PAY,
            errorMessage: platformError,
        },
        {
            label: 'Credit card',
            value: Guest.CREDIT_CARD,
            callback: () => creditCardCheckout(),
            errorMessage: ccError,
        },
    ];
    const handlePaymentChange = (value: string) => {
        setSelectedPaymentMethod(value);
    };

    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
    };

    const renderOptions = () => {
        if (accessToken && hasValidCard) {
            return (
                <RadioButtons
                    options={options}
                    selectedValue={selectedOption}
                    onChange={handleOptionChange}
                    inverse={true}
                />
            );
        } else {
            return (
                <RadioButtons
                    options={paymentOptions}
                    selectedValue={selectedPaymentMethod}
                    onChange={handlePaymentChange}
                    inverse={true}
                />
            );
        }
    };

    return (
        <div className="guest-pricing-content" id="subscribe_pay_page">
            <div className="payment__content--right">
                <div
                    className="title black bold"
                    style={{ marginBottom: 40 }}
                    id="subscribe_pay_page_title"
                >
                    Payment
                </div>
                {renderOptions()}
                <div className="purchase-summary black">
                    <p className="purchase-summary-title">PURCHASE SUMMARY</p>
                    <div className="purchase-station">Chargie Pro</div>
                    <div className="purchase-summary-rates">
                        <div>Monthly subscription</div>
                        <div>$15.00</div>
                    </div>
                </div>
                <div className="purchase-total-black black">
                    <div>Total</div>
                    <div>$15.00</div>
                </div>
            </div>
            {modalBackground && (
                <div className="modal">
                    <div
                        className="modal__background"
                        onClick={hidePaymentModal}
                        id="subscribe_pay_page_modal_background"
                    />
                </div>
            )}
            <div className="payment__button-container">
                <PrimaryButton
                    title="Purchase"
                    onPress={handleSubmit}
                    id="subscribe_pay_page_purchase_button"
                    disabled={!subscribeAvailable}
                />
                <button
                    id="subscribe_pay_page_back_button"
                    className="payment__back-button hidden"
                    onClick={() => navigate('/', { replace: true })}
                >
                    Back
                </button>
            </div>
        </div>
    );
};

export default GuestPricing;
