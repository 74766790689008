import React from 'react';
import { useAppSelector } from '../../app/hooks';
import {
    selectBiometricsExists,
    selectBiometricsType,
    selectRefreshToken,
} from '../../appSlice';
import SettingsButton, {
    SettingsConfig,
} from '../../components/SettingsButton';
import Toggle from '../../components/Toggle';
import {
    deleteBiometrics,
    openAppSettings,
    setupBiometrics,
} from '../../utils/webview/messages';

const SecurityPage: React.FC = () => {
    const refreshToken = useAppSelector(selectRefreshToken);
    const biometricsType = useAppSelector(selectBiometricsType);
    const biometricsExists = useAppSelector(selectBiometricsExists);

    const goToAppSettings = () => {
        openAppSettings();
    };

    const privacyOptions: SettingsConfig[] = [
        {
            value: 'Location',
            text: 'Manage',
            callback: () => goToAppSettings(),
        },
        {
            value: 'Notifications',
            text: 'Manage',
            callback: () => goToAppSettings(),
        },
        {
            value: 'Camera',
            text: 'Manage',
            callback: () => goToAppSettings(),
        },
    ];

    if (biometricsType && refreshToken) {
        privacyOptions.push({
            value: biometricsType === 'FaceID' ? 'Face ID' : 'Fingerprint',
            text: (
                <Toggle
                    value={!!biometricsExists}
                    onChange={(value) =>
                        value ? setupBiometrics() : deleteBiometrics()
                    }
                    id="security_page"
                />
            ),
            transparent: true,
        });
    }

    return <SettingsButton options={privacyOptions} />;
};

export default SecurityPage;
