import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './app/store';
import {
    RemoteErrorTypes,
    SecureStorageKeys,
    StartMethod,
} from './utils/enums';
import { logEvent } from 'firebase/analytics';
import { analytics } from './utils/firebase';
import { saveData } from './utils/webview/messages';

export interface FinishedTransaction {
    kwh: number;
    duration: number;
    cost: number;
}

export interface RequestedChargieId {
    chargieId: string;
    startType: StartMethod;
}

export interface AppState {
    accessToken?: string | null;
    refreshToken?: string | null;
    deviceToken?: string | null;
    deviceGuid?: string | null;
    requestedChargieId?: RequestedChargieId | null;
    activeChargieId?: string | null;
    desiredChargieId?: RequestedChargieId | null;
    inactiveChargieId?: string | null;
    queuedChargieId?: string | null;
    errorType?: RemoteErrorTypes | null;
    biometricsType?: string | null;
    biometricsExists?: string | null;
    biometricsRefreshToken?: string | null;
    googlePlayServicesExists?: string | null;
    appleAuthExists?: string | null;
    showNavigator?: boolean;
    currentPage?: string;
    history: string[];
    allowedLocationData?: boolean;
    insetsTop: number;
    insetsBottom: number;
    hasPaymentMethod: boolean;
    isSubscribed: boolean;
    platformPayExists?: string | null;
    platformOs?: string | null;
    version?: string;
    deviceType?: string;
    netInfo?: string;
    carrier?: string;
    chargeAmp: number;
    chargeKwh: number;
    chargeKw: number;
    chargeCost: number;
    chargeStatus: string;
    chargeDuration?: number | null;
    chargeStartTime?: number | null;
    modalBackground: boolean;
    welcomeMessage: string;
    email?: string;
    firstName: string;
    lastName: string;
    desiredRFID?: string;
    latitude?: number;
    longitude?: number;
    finishedTransaction?: FinishedTransaction;
    scrollPosition: number;
    subscriptionStarted: boolean;
    rfidError: boolean;
    changePasswordPrompt?: boolean | null;
    penaltyStation?: boolean | null;
}

const initialState: AppState = {
    accessToken: undefined,
    refreshToken: undefined,
    deviceToken: undefined,
    activeChargieId: undefined,
    desiredChargieId: undefined,
    inactiveChargieId: undefined,
    queuedChargieId: undefined,
    requestedChargieId: undefined,
    errorType: undefined,
    biometricsType: undefined,
    biometricsExists: undefined,
    biometricsRefreshToken: undefined,
    googlePlayServicesExists: undefined,
    appleAuthExists: undefined,
    showNavigator: false,
    currentPage: 'home',
    history: [],
    allowedLocationData: undefined,
    insetsTop: 0,
    insetsBottom: 0,
    hasPaymentMethod: false,
    isSubscribed: false,
    platformPayExists: undefined,
    platformOs: undefined,
    version: undefined,
    chargeAmp: 0,
    chargeKwh: 0,
    chargeKw: 0,
    chargeCost: 0,
    chargeStatus: 'LOADING',
    chargeDuration: null,
    chargeStartTime: null,
    modalBackground: false,
    welcomeMessage: 'Welcome',
    email: undefined,
    firstName: '',
    lastName: '',
    desiredRFID: undefined,
    latitude: undefined,
    longitude: undefined,
    finishedTransaction: undefined,
    scrollPosition: 0,
    subscriptionStarted: false,
    rfidError: false,
    changePasswordPrompt: undefined,
    penaltyStation: undefined,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//     'counter/fetchCount',
//     async (amount: number) => {
//         const response = await fetchCount(amount);
//         // The value we return becomes the `fulfilled` action payload
//         return response.data;
//     }
// );

export const appSlice = createSlice({
    name: 'app',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAccessToken: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.accessToken = action.payload;
        },
        setRefreshToken: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.refreshToken = action.payload;
        },
        setDeviceToken: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.deviceToken = action.payload;
        },
        setDeviceGuid: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.deviceGuid = action.payload;
        },
        setRequestedChargieId: (
            state,
            action: PayloadAction<RequestedChargieId | null | undefined>
        ) => {
            state.requestedChargieId = action.payload;
        },
        setActiveChargieId: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.activeChargieId = action.payload;
        },
        setDesiredChargieId: (
            state,
            action: PayloadAction<RequestedChargieId | null | undefined>
        ) => {
            state.desiredChargieId = action.payload;
        },
        setInactiveChargieId: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.inactiveChargieId = action.payload;
        },
        setQueuedChargieId: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.queuedChargieId = action.payload;
        },
        setErrorType: (
            state,
            action: PayloadAction<RemoteErrorTypes | null | undefined>
        ) => {
            state.errorType = action.payload;
        },
        setBiometricsType: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.biometricsType = action.payload;
        },
        setBiometricsExists: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.biometricsExists = action.payload;
        },
        setBiometricsRefreshToken: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.biometricsRefreshToken = action.payload;
        },
        setGooglePlayServicesExists: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.googlePlayServicesExists = action.payload;
        },
        setAppleAuthExists: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.appleAuthExists = action.payload;
        },
        setShowNavigator: (state, action: PayloadAction<boolean>) => {
            state.showNavigator = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<string>) => {
            state.currentPage = action.payload;
        },
        setHistory: (state, action: PayloadAction<string[]>) => {
            state.history = action.payload;
        },
        setAllowedLocationData: (state, action: PayloadAction<boolean>) => {
            state.allowedLocationData = action.payload;
        },
        setInsetsTop: (state, action: PayloadAction<number>) => {
            state.insetsTop = action.payload;
        },
        setInsetsBottom: (state, action: PayloadAction<number>) => {
            state.insetsBottom = action.payload;
        },
        setHasPaymentMethod: (state, action: PayloadAction<boolean>) => {
            state.hasPaymentMethod = action.payload;
        },
        setIsSubscribed: (state, action: PayloadAction<boolean>) => {
            state.isSubscribed = action.payload;
        },
        setPlatformPayExists: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.platformPayExists = action.payload;
        },
        setPlatformOs: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.platformOs = action.payload?.toString();
        },
        setVersion: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.version = action.payload?.toString();
        },
        setDeviceType: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.deviceType = action.payload?.toString();
        },
        setNetInfo: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.netInfo = action.payload?.toString();
        },
        setCarrier: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.carrier = action.payload?.toString();
        },
        setChargeAmp: (state, action: PayloadAction<number>) => {
            state.chargeAmp = action.payload;
        },
        setChargeKwh: (state, action: PayloadAction<number>) => {
            state.chargeKwh = action.payload;
        },
        setChargeKw: (state, action: PayloadAction<number>) => {
            state.chargeKw = action.payload;
        },
        setChargeCost: (state, action: PayloadAction<number>) => {
            state.chargeCost = action.payload;
        },
        setChargeStatus: (state, action: PayloadAction<string>) => {
            state.chargeStatus = action.payload;
        },
        setChargeDuration: (
            state,
            action: PayloadAction<number | null | undefined>
        ) => {
            state.chargeDuration = action.payload;
        },
        setModalBackground: (state, action: PayloadAction<boolean>) => {
            state.modalBackground = action.payload;
        },
        setWelcomeMessage: (state, action: PayloadAction<string>) => {
            state.welcomeMessage = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setFirstName: (state, action: PayloadAction<string>) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload;
        },
        setDesiredRFID: (state, action: PayloadAction<string | undefined>) => {
            state.desiredRFID = action.payload;
        },
        setLatitude: (state, action: PayloadAction<number | undefined>) => {
            state.latitude = action.payload;
        },
        setLongitude: (state, action: PayloadAction<number | undefined>) => {
            state.longitude = action.payload;
        },
        setFinishedTransaction: (
            state,
            action: PayloadAction<FinishedTransaction | undefined>
        ) => {
            state.finishedTransaction = action.payload;
        },
        setScrollPosition: (state, action: PayloadAction<number>) => {
            state.scrollPosition = action.payload;
        },
        setSubscriptionStarted: (state, action: PayloadAction<boolean>) => {
            state.subscriptionStarted = action.payload;
        },
        setRfidError: (state, action: PayloadAction<boolean>) => {
            state.rfidError = action.payload;
        },
        setChargeStartTime: (
            state,
            action: PayloadAction<number | null | undefined>
        ) => {
            state.chargeStartTime = action.payload;
        },
        setChangePasswordPrompt: (
            state,
            action: PayloadAction<boolean | null | undefined>
        ) => {
            state.changePasswordPrompt = action.payload;
        },
        setPenaltyStation: (
            state,
            action: PayloadAction<boolean | null | undefined>
        ) => {
            state.penaltyStation = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading';
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle';
    //             state.value += action.payload;
    //         })
    //         .addCase(incrementAsync.rejected, (state) => {
    //             state.status = 'failed';
    //         });
    // },
});

export const {
    setAccessToken,
    setRefreshToken,
    setDeviceToken,
    setDeviceGuid,
    setRequestedChargieId,
    setActiveChargieId,
    setDesiredChargieId,
    setInactiveChargieId,
    setQueuedChargieId,
    setErrorType,
    setBiometricsExists,
    setBiometricsType,
    setBiometricsRefreshToken,
    setGooglePlayServicesExists,
    setAppleAuthExists,
    setShowNavigator,
    setCurrentPage,
    setHistory,
    setAllowedLocationData,
    setInsetsTop,
    setInsetsBottom,
    setHasPaymentMethod,
    setIsSubscribed,
    setPlatformPayExists,
    setPlatformOs,
    setChargeAmp,
    setChargeCost,
    setChargeKw,
    setChargeKwh,
    setChargeStatus,
    setChargeDuration,
    setModalBackground,
    setWelcomeMessage,
    setEmail,
    setFirstName,
    setLastName,
    setDesiredRFID,
    setVersion,
    setDeviceType,
    setNetInfo,
    setCarrier,
    setLatitude,
    setLongitude,
    setFinishedTransaction,
    setScrollPosition,
    setSubscriptionStarted,
    setRfidError,
    setChargeStartTime,
    setChangePasswordPrompt,
    setPenaltyStation,
} = appSlice.actions;
export const actions = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAccessToken = (state: RootState) => state.app.accessToken;
export const selectRefreshToken = (state: RootState) => state.app.refreshToken;
export const selectDeviceToken = (state: RootState) => state.app.deviceToken;
export const selectRequestedChargieId = (state: RootState) =>
    state.app.requestedChargieId;
export const selectActiveChargieId = (state: RootState) =>
    state.app.activeChargieId;
export const selectDesiredChargieId = (state: RootState) =>
    state.app.desiredChargieId;
export const selectInactiveChargieId = (state: RootState) =>
    state.app.inactiveChargieId;
export const selectQueuedChargieId = (state: RootState) =>
    state.app.queuedChargieId;
export const selectErrorType = (state: RootState) => state.app.errorType;
export const selectBiometricsType = (state: RootState) =>
    state.app.biometricsType;
export const selectBiometricsExists = (state: RootState) =>
    state.app.biometricsExists;
export const selectBiometricsRefreshToken = (state: RootState) =>
    state.app.biometricsRefreshToken;
export const selectGooglePlayServicesExists = (state: RootState) =>
    state.app.googlePlayServicesExists;
export const selectAppleAuthExists = (state: RootState) =>
    state.app.appleAuthExists;
export const selectShowNavigator = (state: RootState) =>
    state.app.showNavigator;
export const selectCurrentPage = (state: RootState) => state.app.currentPage;
export const selectHistory = (state: RootState) => state.app.history;
export const selectAllowedLocationData = (state: RootState) =>
    state.app.allowedLocationData;
export const selectInsetsTop = (state: RootState) => state.app.insetsTop;
export const selectInsetsBottom = (state: RootState) => state.app.insetsBottom;
export const selectHasPaymentMethod = (state: RootState) =>
    state.app.hasPaymentMethod;
export const selectIsSubscribed = (state: RootState) => state.app.isSubscribed;
export const selectPlatformPayExists = (state: RootState) =>
    state.app.platformPayExists;
export const selectPlatformOs = (state: RootState) => state.app.platformOs;
export const selectChargeAmp = (state: RootState) => state.app.chargeAmp;
export const selectChargeKwh = (state: RootState) => state.app.chargeKwh;
export const selectChargeKw = (state: RootState) => state.app.chargeKw;
export const selectChargeCost = (state: RootState) => state.app.chargeCost;
export const selectChargeStatus = (state: RootState) => state.app.chargeStatus;
export const selectChargeDuration = (state: RootState) =>
    state.app.chargeDuration;
export const selectModalBackground = (state: RootState) =>
    state.app.modalBackground;
export const selectWelcomeMessage = (state: RootState) =>
    state.app.welcomeMessage;
export const selectEmail = (state: RootState) => state.app.email;
export const selectFirstName = (state: RootState) => state.app.firstName;
export const selectLastName = (state: RootState) => state.app.lastName;
export const selectDesiredRFID = (state: RootState) => state.app.desiredRFID;
export const selectLongitude = (state: RootState) => state.app.longitude;
export const selectLatitude = (state: RootState) => state.app.latitude;
export const selectFinishedTransaction = (state: RootState) =>
    state.app.finishedTransaction;
export const selectScrollPosition = (state: RootState) =>
    state.app.scrollPosition;
export const selectSubscriptionStarted = (state: RootState) =>
    state.app.subscriptionStarted;
export const selectRfidError = (state: RootState) => state.app.rfidError;
export const selectChargeStartTime = (state: RootState) =>
    state.app.chargeStartTime;
export const selectChangePasswordPrompt = (state: RootState) =>
    state.app.changePasswordPrompt;
export const selectPenaltyStation = (state: RootState) =>
    state.app.penaltyStation;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: number): AppThunk =>
//         (dispatch, getState) => {
//             const currentValue = selectCount(getState());
//             if (currentValue % 2 === 1) {
//                 dispatch(incrementByAmount(amount));
//             }
//         };

export default appSlice.reducer;
