import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectHistory,
    setDesiredChargieId,
    setShowNavigator,
} from '../../appSlice';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { colors } from '../../global/variables';
import GuestCheckout from './GuestCheckout';
import GuestContact from './GuestContact';
import GuestPayment from './GuestPayment';
import GuestPricing from './GuestPricing';
import GuestSuccess from './GuestSucces';

const GuestWrapper: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const history = useAppSelector(selectHistory);

    const handlePricingDetailsModalToggle = () => {
        dispatch(setDesiredChargieId(null));
        navigate('/', { replace: true });
    };

    useEffect(() => {
        dispatch(setShowNavigator(false));
    });

    return (
        <motion.div
            className="container--full"
            style={{ paddingTop: 0, paddingBottom: 0, overflow: 'hidden' }}
            initial={
                history.length > 0
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div
                className="modal__overlay"
                id="guest_wrapper_modal_overlay"
            ></div>
            <div className="content-page" id="guest_wrapper_content">
                <div className="pricing-close-container">
                    <div
                        id="guest_wrapper_modal_close"
                        className="modal__close"
                        onClick={handlePricingDetailsModalToggle}
                    >
                        <CloseIcon
                            fill={colors.inactiveWhite}
                            id="guest_wrapper_close_icon"
                        />
                    </div>
                </div>
                <div className="pricing-details-content">
                    <div className="modal__gradient"></div>
                    <div className="modal__full-content">
                        <Routes>
                            <Route path="/pricing" element={<GuestPricing />} />
                            <Route
                                path="/checkout"
                                element={<GuestCheckout />}
                            />
                            <Route path="/contact" element={<GuestContact />} />
                            <Route path="/payment" element={<GuestPayment />} />
                            <Route path="/success" element={<GuestSuccess />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default GuestWrapper;
