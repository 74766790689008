import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { loader } from 'graphql.macro';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { ReactComponent as Pin } from '../../assets/Pin.svg';
import Accordion from '../../components/Accordion';
import { mapLink } from '../../utils/utility';

const accountQuery = loader('./getDiscounts.graphql');

interface DiscountObject {
    rate: number;
    name: string;
    address: [string];
}

const DiscountPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [discounts, setDiscounts] = useState<DiscountObject[]>([]);
    const [guestAccess, setGuestAccess] = useState<DiscountObject[]>([]);

    const { error: userError, data: userData } = useQuery(accountQuery);

    useEffect(() => {
        if (userData) {
            if (
                userData.getGroupDiscounts &&
                userData.getGroupDiscounts.length > 0
            ) {
                const discountsArr: DiscountObject[] = [];
                userData.getGroupDiscounts.map((item: any) => {
                    const discount: DiscountObject = {
                        rate: item.discountRate,
                        name: item.domainName,
                        address: item.properties.map((prop: any) => {
                            return (
                                prop.address1 +
                                ', ' +
                                prop.city +
                                ', ' +
                                prop.state +
                                ' ' +
                                prop.zip
                            );
                        }),
                    };
                    discountsArr.push(discount);
                });
                setDiscounts(discountsArr);
            }
            if (
                userData.getGuestAccesses &&
                userData.getGuestAccesses.length > 0
            ) {
                const guestArr: DiscountObject[] = [];
                userData.getGuestAccesses.map((item: any) => {
                    const discount: DiscountObject = {
                        rate: 1,
                        name: 'Location',
                        address: item.properties.map((prop: any) => {
                            return (
                                prop.address1 +
                                ', ' +
                                prop.city +
                                ', ' +
                                prop.state +
                                ' ' +
                                prop.zip
                            );
                        }),
                    };
                    guestArr.push(discount);
                });
                setGuestAccess(guestArr);
            }
        }
    }, [userData]);

    return (
        <motion.div
            className="container"
            id="discount_page"
            initial={
                location.state && location.state.slide === true
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="discount_page_header">
                <div
                    className="header__back"
                    onClick={() => {
                        navigate(-1);
                    }}
                    id="discount_page_back"
                >
                    <BackIcon id="discount_page_back_icon"></BackIcon>
                </div>
                <div className="header__content" id="discount_page_header">
                    DISCOUNTS
                </div>
            </div>
            <div className="page">
                {discounts.length > 0 && (
                    <div className="discount-container">
                        <div className="discount-title">Group discount</div>
                        <div className="discount-description">
                            The discount applies to the charging total,
                            excluding penalty fees
                        </div>
                        {discounts.map((item) => (
                            <Accordion
                                leftTitle={item.name}
                                rightTitle={item.rate * 100 + '% OFF'}
                                id="discount"
                            >
                                <div>
                                    {item.address.map((address) => (
                                        <div className="discount-content">
                                            <Pin
                                                style={{ marginRight: 5 }}
                                                id="discount_page_pin_icon"
                                            />
                                            <a
                                                id="discount_page_address"
                                                href={mapLink(
                                                    encodeURI(address)
                                                )}
                                            >
                                                {address}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </Accordion>
                        ))}
                    </div>
                )}
                {guestAccess.length > 0 && (
                    <div className="discount-container">
                        <div className="discount-title" id="guest_access_title">
                            Guest access
                        </div>
                        <div className="discount-description">
                            The discount applies to the charging total,
                            excluding penalty fees
                        </div>
                        {guestAccess.map((item) => (
                            <Accordion
                                leftTitle={item.name}
                                rightTitle={item.rate * 100 + '% OFF'}
                                id="guest_access"
                            >
                                <div>
                                    {item.address.map((address) => (
                                        <div className="discount-content">
                                            <Pin
                                                style={{ marginRight: 5 }}
                                                id="guest_access_pin_icon"
                                            />
                                            <a
                                                id="guest_access_address"
                                                href={`https://maps.google.com?q=${encodeURI(address)}`}
                                            >
                                                {address}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </Accordion>
                        ))}
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default DiscountPage;
