import { useMutation, useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { loader } from 'graphql.macro';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectAccessToken,
    selectChangePasswordPrompt,
    selectEmail,
    selectFirstName,
    selectHistory,
    selectIsSubscribed,
    selectLastName,
    setChangePasswordPrompt,
    setFirstName,
    setLastName,
    setRfidError,
    setShowNavigator,
} from '../../appSlice';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/Edit.svg';
import { ReactComponent as Arrow } from '../../assets/RightArrow.svg';
import { logout } from '../../utils/webview/messages';

import { useForm } from 'react-hook-form';
import { store } from '../../app/store';
import SuccessModal from '../../components/SuccessModal';

const deleteAccountMutation = loader('./deleteAccount.graphql');
const accountQuery = loader('./getDiscounts.graphql');
const updateUserMutation = loader('./updateUserName.graphql');

interface NameFormValues {
    name: string;
}

const AccountPage = () => {
    const dispatch = useAppDispatch();
    const accessToken = useAppSelector(selectAccessToken);
    const isSubscribed = useAppSelector(selectIsSubscribed);
    const firstName = useAppSelector(selectFirstName);
    const lastName = useAppSelector(selectLastName);
    const email = useAppSelector(selectEmail);
    const [name, setName] = useState(`${firstName} ${lastName}`);
    const [showAdmin, setShowAdmin] = useState(false);
    const [hasDiscounts, setHasDiscounts] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const location = useLocation();
    const history = useAppSelector(selectHistory);
    const [passwordMessage, setPasswordMessage] = useState('');
    const [passwordTitle, setPasswordTitle] = useState('');
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const showChangePasswordPrompt = useAppSelector(selectChangePasswordPrompt);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const [updateUser, { data: userUpdated, error: updateError }] =
        useMutation(updateUserMutation);

    const [deleteAccount, { data, error, loading }] = useMutation(
        deleteAccountMutation
    );

    const { error: userError, data: userData } = useQuery(accountQuery);

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors: formErrors },
    } = useForm<NameFormValues>({
        defaultValues: {
            name: `${firstName} ${lastName}`,
        },
    });

    useEffect(() => {
        store.dispatch(setShowNavigator(false));
        store.dispatch(setRfidError(false));

        if (
            showChangePasswordPrompt === true ||
            showChangePasswordPrompt === false
        ) {
            setShowPasswordModal(true);
            if (showChangePasswordPrompt === true) {
                setPasswordTitle('Success');
                setPasswordMessage(
                    'Your password has been updated successfully'
                );
                setSuccess(true);
            } else if (showChangePasswordPrompt === false) {
                setPasswordTitle('Error');
                setPasswordMessage(
                    'Your password was not updated. Please try again or reach out support for help.'
                );
                setSuccess(false);
            }
        } else {
            setShowPasswordModal(false);
        }
        store.dispatch(setChangePasswordPrompt(undefined));
    }, []);

    const onSubmit = async () => {
        try {
            const [firstPart, ...rest] = name.split(' ');
            const secondPart = rest.join(' ');
            dispatch(setFirstName(firstPart || ''));
            dispatch(setLastName(secondPart || ''));
            await updateUser({
                variables: {
                    user: {
                        nameFirst: firstPart,
                        nameLast: secondPart,
                    },
                },
            });
        } catch {
            // Errors will be caught in the Apollo errors
        }
    };

    useEffect(() => {
        if (
            userData &&
            ((userData.getGroupDiscounts &&
                userData.getGroupDiscounts.length > 0) ||
                (userData.getGuestAccessses &&
                    userData.getGuestAccessses.length > 0))
        ) {
            setHasDiscounts(true);
        }
    }, [userData]);

    useEffect(() => {
        if (data) {
            logout();
            navigate('/', { replace: true });
        } else if (error) {
            // alert(error.message);
        }
    }, [data, error, navigate]);

    useEffect(() => {
        try {
            if (accessToken) {
                const decoded = jwtDecode<{ sub: string; role: string }>(
                    accessToken
                );
                setShowAdmin(decoded.role === 'Admin');
            }
        } catch {}
    }, [accessToken]);

    const closeModal = () => {
        setShowPasswordModal(false);
    };

    const handleInputClick = () => {
        if (showSave) {
            //Submit
            setShowSave(false);
            onSubmit();
        } else {
            setShowSave(true);
            setTimeout(() => {
                if (inputRef.current) inputRef.current.focus();
            }, 100);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        setName(input);
    };

    return (
        <motion.div
            className="container--navless"
            id="account_page"
            initial={
                history.length > 0 && history[1] === '/settings'
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="account_page_header">
                <div
                    className="header__back"
                    id="account_page_back"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <BackIcon id="account_page_back_icon"></BackIcon>
                </div>
                <div className="header__content" id="account_page_title">
                    ACCOUNT
                </div>
            </div>
            <div
                className="content"
                style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
                id="account_page_content"
            >
                <div className="account-header">
                    <div className="account-header-details">
                        <div className="account-header-title">Name</div>
                        <form
                            className="name-form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <input
                                className="account-input"
                                type="text"
                                id="name"
                                {...register('name')}
                                value={name}
                                disabled={!showSave}
                                ref={inputRef}
                                onChange={handleInputChange}
                            />
                        </form>
                    </div>
                    <div
                        className="account-header-action"
                        onClick={handleInputClick}
                        id="account_page_header_action"
                    >
                        {showSave && <div id="account_page_save">Save</div>}
                        {!showSave && <EditIcon id="account_page_edit_icon" />}
                    </div>
                </div>
                <div className="account-header">
                    <div className="account-header-details">
                        <div className="account-header-title" id="email">
                            Email
                        </div>
                        <input
                            className="account-input"
                            type="text"
                            id="account_page_email_input"
                            value={email}
                            disabled={true}
                        />
                    </div>
                </div>
                {isSubscribed && (
                    <button
                        id="account_page_subscription_button"
                        className="settings__button"
                        onClick={() =>
                            navigate('/subscription', {
                                state: { slide: true },
                            })
                        }
                    >
                        <div id="account_page_subscription_text">
                            Subscription plan
                        </div>
                        <Arrow id="account_page_subscription_icon" />
                    </button>
                )}
                {hasDiscounts && (
                    <button
                        id="account_page_discount_button"
                        className="settings__button"
                        onClick={() =>
                            navigate('/discount', { state: { slide: true } })
                        }
                    >
                        <div id="account_page_discount_text">Discounts</div>
                        <Arrow id="account_page_discount_icon" />
                    </button>
                )}
                <button
                    id="account_page_rfid_button"
                    className="settings__button"
                    onClick={() =>
                        navigate('/rfid', {
                            state: { error: false, slide: true },
                        })
                    }
                >
                    <div id="account_page_rfid_text">Manage RFIDs</div>
                    <Arrow id="account_page_rfid__icon" />
                </button>
                <button
                    id="account_page_password_button"
                    className="settings__button"
                    onClick={() =>
                        navigate('/password', { state: { slide: true } })
                    }
                >
                    <div id="account_page_password_text">Change password</div>
                    <Arrow id="account_page_password_icon" />
                </button>
                <button
                    id="account_page_delete_button"
                    className="settings__button"
                    onClick={() =>
                        navigate('/delete', { state: { slide: true } })
                    }
                >
                    <div id="account_page_delete_text">Delete account</div>
                    <Arrow id="account_page_delete_icon" />
                </button>
                {showAdmin && (
                    <button
                        id="account_page_admin_button"
                        className="settings__button"
                        onClick={() =>
                            navigate('/admin', { state: { slide: true } })
                        }
                    >
                        <div id="account_page_admin_text">Admin</div>
                        <Arrow id="account_page_admin_arrow" />
                    </button>
                )}
            </div>
            <SuccessModal
                isOpen={showPasswordModal}
                onClose={closeModal}
                success={success}
                title={passwordTitle}
                description={passwordMessage}
            />
        </motion.div>
    );
};

export default AccountPage;
