import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './app/store';
import router from './utils/router';
import { RemoteErrorTypes } from './utils/enums';
import { StartData } from './models/Station';

export interface GuestState {
    contactInformation?: string | null;
    startData?: StartData | null;
    paymentMethod?: string | null;
    creditCard?: string | null;
    // activeChargieId?: string | null;
    // desiredChargieId?: string | null;
    // inactiveChargieId?: string | null;
    // errorType?: REMOTE_ERROR_TYPES | null;
    // biometricsType?: string | null;
    // biometricsExists?: string | null;
    // biometricsRefreshToken?: string | null;
    // googlePlayServicesExists?: string | null;
    // showNavigator?: boolean;
    // currentPage?: string;
}

const initialState: GuestState = {
    contactInformation: undefined,
    startData: undefined,
    paymentMethod: undefined,
    creditCard: undefined,
    // activeChargieId: undefined,
    // desiredChargieId: undefined,
    // inactiveChargieId: undefined,
    // errorType: undefined,
    // biometricsType: undefined,
    // biometricsExists: undefined,
    // biometricsRefreshToken: undefined,
    // googlePlayServicesExists: undefined,
    // showNavigator: true,
    // currentPage: 'home',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//     'counter/fetchCount',
//     async (amount: number) => {
//         const response = await fetchCount(amount);
//         // The value we return becomes the `fulfilled` action payload
//         return response.data;
//     }
// );

export const guestSlice = createSlice({
    name: 'guest',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setContactInformation: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.contactInformation = action.payload;
        },
        setStartData: (
            state,
            action: PayloadAction<StartData | null | undefined>
        ) => {
            state.startData = action.payload;
        },
        setPaymentMethod: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.paymentMethod = action.payload;
        },
        setCreditCard: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.creditCard = action.payload;
        },
    },
});

export const {
    setContactInformation,
    setStartData,
    setPaymentMethod,
    setCreditCard,
    // setActiveChargieId,
    // setDesiredChargieId,
    // setInactiveChargieId,
    // setErrorType,
    // setBiometricsExists,
    // setBiometricsType,
    // setBiometricsRefreshToken,
    // setGooglePlayServicesExists,
    // setShowNavigator,
    // setCurrentPage,
} = guestSlice.actions;
export const actions = guestSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectContactInformation = (state: RootState) =>
    state.guest.contactInformation;
export const selectStartData = (state: RootState) => state.guest.startData;
export const selectPaymentMethod = (state: RootState) =>
    state.guest.paymentMethod;
export const selectCreditCard = (state: RootState) => state.guest.creditCard;

export default guestSlice.reducer;
