import { useAppSelector } from '../../app/hooks';
import { selectAccessToken } from '../../appSlice';

const withTokenWatcher = <P extends object>(
    WrappedComponent: React.ComponentType<P>
): React.FC<P> => {
    const TokenWatcher: React.FC<P> = (props) => {
        // Use useSelector to access accessToken from the Redux store
        const accessToken = useAppSelector(selectAccessToken);

        // Use accessToken as the key to force rerender
        return <WrappedComponent key={accessToken} {...props} />;
    };

    return TokenWatcher;
};

export default withTokenWatcher;
