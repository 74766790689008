import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { store } from '../app/store';
import { JwtPayload, jwtDecode } from 'jwt-decode';

export const firebaseConfig = {
    apiKey: 'AIzaSyDG4bZRsrM8FumIqVI-8GVFdDZK07SfsCk',
    authDomain: 'mobile-app-6b960.firebaseapp.com',
    projectId: 'mobile-app-6b960',
    storageBucket: 'mobile-app-6b960.appspot.com',
    messagingSenderId: '913170279727',
    appId: '1:913170279727:web:d0ae01e3c7cfa385a345da',
    measurementId: 'G-YT6Q4XN7D8',
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const recordClick = (event: React.MouseEvent) => {
    const date = new Date();
    const target = event.target as HTMLElement;
    const className = target.className;
    const token = store.getState().app.accessToken;
    try {
        logEvent(analytics, 'MA_4.0_click', {
            class: className,
            date: date.toLocaleString(),
            sessionId: firebaseSessionId,
            userId: jwtDecode<JwtPayload>(token || '').sub,
            id: target.id && target.id !== null ? target.id : className,
        });
    } catch {
        logEvent(analytics, 'MA_4.0_click', {
            class: className,
            date: date.toLocaleString(),
            sessionId: firebaseSessionId,
            id: target.id && target.id !== null ? target.id : className,
        });
    }
};

const generateRandomString = (): string => {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const firebaseSessionId = generateRandomString();
