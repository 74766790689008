import { useQuery } from '@apollo/client';
import { subDays } from 'date-fns';
import { motion } from 'framer-motion';
import { loader } from 'graphql.macro';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { setShowNavigator } from '../../appSlice';
import { ReactComponent as Arrow } from '../../assets/icons/accordionArrow.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { Time } from '../../utils/enums';
import Modal from './ChargingHistoryFilter';
import ChargingHistoryItem from './ChargingHistoryItem';

const getRecentTransactionsUser = loader(
    './getRecentTransactionsForUser.graphql'
);

const ChargingHistoryPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { error, data } = useQuery(getRecentTransactionsUser, {
        fetchPolicy: 'cache-and-network',
    });
    const [ocppSessions, setOcppSessions] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [time, setTime] = useState<Time>(Time.MOSTRECENT);
    const [totalKwh, setTotalKwh] = useState<any>(0);

    const navigate = useNavigate();

    const timeFrames = [
        { value: Time.MOSTRECENT, label: 'Most recent' },
        { value: Time.THIRTYDAYS, label: 'Last 30 days' },
        { value: Time.SIXMONTHS, label: 'Last 6 months' },
        { value: Time.YEAR, label: 'Last year' },
        { value: Time.TOTAL, label: 'All time' },
    ];

    const handleModalClose = (selectedOptions: Time) => {
        setTime(selectedOptions);
        setIsModalOpen(false); // Close the modal
    };

    const calculateTotalKwh = (filteredSessions: any[]) => {
        let total = 0;
        filteredSessions.map((ocppSession: any) => (total += ocppSession.wh));
        setTotalKwh(total / 1000);
    };
    useEffect(() => {
        dispatch(setShowNavigator(false));
        if (data) {
            setOcppSessions(data.recentTransactionsForUser);
            // setOcppSessions([])
        } else {
            setOcppSessions([]);
        }
    }, [data]);

    const filteredTransactions = useMemo(() => {
        if (!ocppSessions) return [];
        switch (time) {
            case Time.MOSTRECENT: {
                return ocppSessions.slice(0, 10);
            }
            case Time.THIRTYDAYS: {
                return ocppSessions.filter(
                    (item: any) =>
                        new Date(item.startedAt).getTime() >
                        subDays(new Date(), 30).getTime()
                );
            }
            case Time.SIXMONTHS: {
                return ocppSessions.filter(
                    (item: any) =>
                        new Date(item.startedAt).getTime() >
                        subDays(new Date(), 180).getTime()
                );
            }
            case Time.YEAR: {
                return ocppSessions.filter(
                    (item: any) =>
                        new Date(item.startedAt).getTime() >
                        subDays(new Date(), 365).getTime()
                );
            }
            default: {
                return ocppSessions;
            }
        }
    }, [ocppSessions, time]);

    useEffect(() => {
        calculateTotalKwh(filteredTransactions);
    }, [time, filteredTransactions]);

    return (
        <motion.div
            className={
                isModalOpen
                    ? 'container--navless noScroll'
                    : 'container--navless'
            }
            id="charging_history_page"
            initial={
                location.state && location.state.slide === true
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="charging_history_page_header">
                <div className="header__content">
                    <div
                        id="charging_history_page_back"
                        className="header__back"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <BackIcon id="charging_history_page_icon"></BackIcon>
                    </div>
                    CHARGING HISTORY
                </div>
            </div>

            <div
                className="content"
                style={{ paddingTop: 20 }}
                id="charging_history_page_content"
            >
                <div className="filterContainer">
                    <div
                        className="filterButtonContainer"
                        onClick={() => setIsModalOpen(true)}
                        id="charging_history_page_filter_button"
                    >
                        <span className="filterText">
                            {
                                timeFrames.find((frame) => frame.value === time)
                                    ?.label
                            }
                        </span>

                        <Arrow
                            className="filterContainer__arrow"
                            width={12}
                            height={12}
                            id="charging_history_page_icon"
                        />
                    </div>
                </div>

                <div className="chargingHistoryBarContainer__metrics">
                    <span className="sessionsNumber">
                        {filteredTransactions.length || 0}
                    </span>
                    <span className="kwhNumber">
                        {totalKwh > 0 ? totalKwh.toFixed(0) : 0}
                    </span>
                </div>
                <div className="chargingHistoryBarContainer">
                    <hr className="sessionsLine" />
                    <hr className="kwhLine" />
                </div>
                <div
                    className="chargingHistoryBarContainer"
                    style={{ textAlign: 'right' }}
                >
                    <span className="sessionsNumber__text">SESSIONS</span>
                    <span className="kwhNumber__text">KWH</span>
                </div>
                {filteredTransactions.map((ocppSession: any, index: number) => (
                    <Fragment key={ocppSession.id}>
                        <ChargingHistoryItem
                            key={ocppSession.id}
                            ocppSession={ocppSession}
                        />
                        {index < filteredTransactions.length - 1 && (
                            <hr className="fragmentHr" />
                        )}
                    </Fragment>
                ))}
            </div>
            <div>
                {isModalOpen && (
                    <div className="modal__filter-content">
                        <Modal
                            onClose={handleModalClose}
                            defaultSelectedOption={time}
                        ></Modal>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default ChargingHistoryPage;
