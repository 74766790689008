import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectAccessToken, selectHistory } from '../../appSlice';
import { motion } from 'framer-motion';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { useForm } from 'react-hook-form';
import { store } from '../../app/store';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';
import { colors } from '../../global/variables';
import { formatDateLong } from '../../utils/utility';

const cancelSubscriptionMutation = loader('./cancelSubscription.gql');
const userDetails = loader('./getSubscriptionRenewal.gql');

const SubscriptionPage = () => {
    const [renewalDate, setRenewalDate] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [cancelDate, setCancelDate] = useState<string | null>(null);
    const history = useAppSelector(selectHistory);

    const navigate = useNavigate();
    const [
        cancelSub,
        {
            data: cancelSubData,
            error: cancelSubError,
            loading: cancelSubLoading,
        },
    ] = useMutation(cancelSubscriptionMutation);
    const {
        error: userError,
        data: userData,
        refetch: userDetailFetch,
    } = useQuery(userDetails, { fetchPolicy: 'no-cache' });

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const cancelSubscription = async () => {
        await cancelSub();
        await userDetailFetch();
        setRenewalDate(null);
        setIsOpen(false);
    };

    useEffect(() => {
        if (userData && userData.loggedInUser) {
            if (
                userData.loggedInUser.isSubscribed &&
                userData.loggedInUser.subscriptionRenewalDate
            ) {
                const date = new Date(
                    userData.loggedInUser.subscriptionRenewalDate
                );
                setRenewalDate(formatDateLong(date));
            } else {
                setRenewalDate(null);
            }
            if (userData.loggedInUser.subscriptionCancelDate) {
                const date = new Date(
                    userData.loggedInUser.subscriptionCancelDate
                );
                setCancelDate(formatDateLong(date));
            } else {
                setCancelDate(null);
            }
        }
    }, [userData]);

    return (
        <motion.div
            className="container"
            initial={
                history.length > 0 && history[1] === '/account'
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            id="subscription_page"
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="subscription_page_header">
                <div
                    className="header__back"
                    onClick={() => {
                        navigate(-1);
                    }}
                    id="subscription_page_back"
                >
                    <BackIcon id="subscription_page_back_icon"></BackIcon>
                </div>
                <div
                    className="header__content"
                    id="subscription_page_header_content"
                >
                    SUBSCRIPTIONS
                </div>
            </div>
            <div className="page">
                {renewalDate && (
                    <div className="subscription-content">
                        <div className="subscription-header-content">
                            <div className="xsmall gray-text">Chargie pro</div>
                            <div className="title">Monthly $15.00/mo</div>
                            <div className="xsmall white-text">
                                Access to locations that require a subscription
                            </div>
                        </div>
                        <div className="subscription-header-content">
                            <div className="xsmall gray-text">Renewal</div>
                            <div className="title">{renewalDate}</div>
                            <div className="xsmall white-text">
                                Payment Method: Card on file
                            </div>
                        </div>
                        <SecondaryButton
                            title="Cancel"
                            onPress={toggleModal}
                            disabled={cancelSubLoading}
                            id="subscription_page_cancel_button"
                        />
                    </div>
                )}
                {cancelDate && (
                    <div
                        className="subscription-header-content"
                        style={{ marginTop: 20 }}
                    >
                        <div className="title">
                            Your subscription has been canceled.
                        </div>
                        {cancelDate && (
                            <div className="xsmall white-text">
                                Subscription ends on {cancelDate}.
                            </div>
                        )}
                    </div>
                )}
            </div>
            {isOpen && (
                <div className="modal" id="subscription_page_modal">
                    <div
                        className="modal__background"
                        onClick={toggleModal}
                        id="subscription_page_modal_background"
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content"
                        id="subscription_page_modal_content"
                    >
                        <div className="modal__close-container">
                            <div
                                className="modal__close"
                                onClick={toggleModal}
                                id="subscription_page_modal_close"
                            >
                                <CloseIcon
                                    fill={colors.inactiveWhite}
                                    id="subscription_page_modal_close_icon"
                                />
                            </div>
                        </div>
                        <div
                            className="large"
                            id="subscription_page_cancel_plan"
                        >
                            Cancel plan
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            Are you sure you want to cancel your Chargie Pro
                            subscription?
                        </div>
                        <PrimaryButton
                            id="subscription_page_confirm_cancel"
                            title="Yes, cancel"
                            onPress={cancelSubscription}
                        />
                        <button
                            id="subscription_page_modal_cancel"
                            style={{ marginTop: 10 }}
                            className="tertiaryButton"
                            onClick={toggleModal}
                        >
                            Go back
                        </button>
                    </motion.div>
                    <div className="modal__content--inset" />
                </div>
            )}
        </motion.div>
    );
};

export default SubscriptionPage;
