import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as HidePasswordIcon } from '../../assets/icons/hidePassword.svg';
import { ReactComponent as ShowPasswordIcon } from '../../assets/icons/showPassword.svg';
import withLoggedInRedirect from '../../components/HOC/withLoggedInRedirect';
import { sizes } from '../../global/variables';

interface ResetPasswordFormValues {
    password: string;
}

const resetPasswordMutation = loader('./resetPassword.graphql');
const verifyResetTokenQuery = loader('./verifyResetToken.graphql');

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    let [searchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);

    const [resetPassword, { error, loading }] = useMutation(
        resetPasswordMutation
    );
    const email = params.email || searchParams.get('email');
    const token = params.token || searchParams.get('token');
    const realm =
        searchParams.get('realm') && searchParams.get('realm') !== 'undefined'
            ? searchParams.get('realm')
            : undefined;

    const { data: verifyData, error: verifyError } = useQuery(
        verifyResetTokenQuery,
        {
            variables: {
                email,
                token,
                realm,
            },
        }
    );

    useEffect(() => {
        if (verifyData?.verifyResetToken === false || verifyError) {
            navigate('/reset-password-expired', {
                replace: true,
                state: { email, realm },
            });
        }
    }, [email, navigate, realm, verifyData, verifyError]);

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        getValues,
    } = useForm<ResetPasswordFormValues>();

    const onSubmit = async (data: ResetPasswordFormValues) => {
        try {
            await resetPassword({
                variables: {
                    input: {
                        token,
                        realm,
                        password: getValues().password,
                    },
                },
            });
            navigate('/login', { replace: true });
        } catch {
            // Errors will be caught in the Apollo errors
        }
    };

    return (
        <div className="page page--light" id="reset_password_page">
            <div className="inset__header inset__header--invisible" />
            <div
                className="page__close page__close--black"
                onClick={() => navigate('/', { replace: true })}
                id="reset_password_page_back"
            >
                <p id="reset_password_page_icon">&times;</p>
            </div>
            <h1
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'end',
                    paddingBottom: 50,
                }}
                id="reset_password_page_title"
            >
                Create a new password
            </h1>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <input
                        className="input"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        placeholder="Password"
                        {...register('password', {
                            required: 'Password is required',
                            minLength: {
                                value: 8,
                                message: 'Must have minimum 8 characters',
                            },
                            pattern: {
                                value: /[A-Z]/,
                                message: 'Must have upper case letter',
                            },
                        })}
                    />
                    <div
                        className="input__icon"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <HidePasswordIcon
                                width={sizes.medium}
                                height={sizes.medium}
                            />
                        ) : (
                            <ShowPasswordIcon
                                width={sizes.medium}
                                height={sizes.medium}
                            />
                        )}
                    </div>
                </div>
                {formErrors?.password?.message && (
                    <span className="form__error">
                        {formErrors.password.message}
                    </span>
                )}
                {error?.message && (
                    <span className="form__error">{error.message}</span>
                )}
                <button
                    type="submit"
                    className="button"
                    disabled={loading}
                    id="reset_password_page_button"
                >
                    Reset Password
                </button>
            </form>
            <div style={{ flex: 2 }} />
        </div>
    );
};

export default withLoggedInRedirect(ResetPasswordPage);
