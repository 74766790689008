import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectRefreshToken } from '../../appSlice';
import { ReactComponent as Dot } from '../../assets/icons/dot.svg';
import { selectRecentStations } from '../../containers/HomePage/slice';
import { colors } from '../../global/variables';

import { ReactComponent as RecentIcon } from '../../assets/icons/recentIcon.svg';
import { formatDateLong } from '../../utils/utility';

interface RecentStationsProps {
    onPress: (selection: string) => void;
}

const RecentStations: React.FC<RecentStationsProps> = ({ onPress }) => {
    const refreshToken = useAppSelector(selectRefreshToken);
    const recentList = useAppSelector(selectRecentStations);
    const [listLength, setListLength] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        setListLength(recentList?.length || 0);
    }, [recentList]);

    const scan = () => {
        navigate('/scan');
    };

    const startRecent = (stationQr: string) => {
        onPress(stationQr);
    };

    return (
        <div className="recent" id="recent_stations_container">
            {!refreshToken && (
                <div
                    className="subcontainer recent__empty"
                    id="recent_logged_out"
                >
                    <p
                        className="recent__empty-text-logged-out"
                        id="recent__empty-text-logged-out"
                    >
                        It's easy. Plug-in, then scan to start.
                    </p>
                    <div>
                        <button
                            onClick={scan}
                            className="recent__start"
                            id="recent_start__button"
                        >
                            Scan
                        </button>
                    </div>
                </div>
            )}
            {refreshToken && listLength === 0 && (
                <div
                    className="subcontainer recent__empty-container"
                    id="recent_empty"
                >
                    <p className="recent__empty-text" id="recent_empty__text">
                        No recent stations
                    </p>
                </div>
            )}
            {refreshToken && recentList && recentList.length > 0 && (
                <div className="recent__container">
                    {recentList.map((item, index) => (
                        <div
                            id="recent_list__item"
                            className="subcontainer recent__stations"
                            key={index}
                            onClick={() => startRecent(item.qrCode)}
                        >
                            <RecentIcon />
                            <div
                                className="recent__details"
                                id="recent_list__item__details"
                            >
                                <div
                                    className="recent__title"
                                    id="recent_list__station_code"
                                >
                                    STATION {item.qrCode}
                                </div>
                                <div
                                    className="recent__subtitle"
                                    id="recent_list__item__subtitle"
                                >
                                    {item.address1}{' '}
                                    <Dot
                                        fill={colors.inactiveWhite}
                                        className="recent__dot"
                                    />{' '}
                                    {formatDateLong(item.startedAt)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default RecentStations;
