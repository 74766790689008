export default interface StripeTransaction {
    __typename: string;
    amount: number;
    created: number;
    currency: string;
    customer: string;
    description: string;
    ending_balance: number;
    id: string;
    metadata: MetaData;
    invoice: string | null;
    object: string;
    type: string;
    reason: string;
    status: string | null;
    transactionAmount: number | null;
}

interface MetaData {
    transactionUUID: string;
    source: string | null;
    baseCost?: string;
    fee?: string;
    minFee?: string;
}

type GetStripeTransactionRespose = {
    getAllStripeTransactionsUser: [StripeTransaction];
};

export const extractStripeTransaction = (data: GetStripeTransactionRespose) => {
    return data && data.getAllStripeTransactionsUser;
};
