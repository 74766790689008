import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { ReactComponent as AccountBalance } from '../../assets/AccountBalance.svg';

import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { numberToDollarFormat } from '../../utils/utility';
import { colors } from '../../global/variables';
import StripeTransaction, {
    extractStripeTransaction,
} from '../../models/StripeTransaction';
import { StripeTransactionTypes } from '../../utils/enums';
import { useAppSelector } from '../../app/hooks';
import { selectHistory } from '../../appSlice';

const getUserDetails = loader('./getUserBalance.graphql');
const getStripeTransactions = loader('./getStripeTransactions.graphql');

const App: React.FC = () => {
    const navigate = useNavigate();
    const [accountBalance, setAccountBalance] = useState(0);
    const [transactions, setTransactions] = useState<
        StripeTransaction[] | null
    >(null);
    const location = useLocation();
    const history = useAppSelector(selectHistory);

    const { error: getUserError, data: getUserData } = useQuery(getUserDetails);

    const {
        error: stripeError,
        data: stripeData,
        loading: stripeLoading,
    } = useQuery(getStripeTransactions);

    useEffect(() => {
        if (getUserData) {
            setAccountBalance(getUserData.getStripeCustomer.balance / -100);
        }
    }, [getUserData]);

    useEffect(() => {
        if (stripeData && stripeData.getAllStripeTransactionsUser) {
            setTransactions(extractStripeTransaction(stripeData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripeData]);

    const formatDate = (date: Date) => {
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        })
            .format(date)
            .replace('at', '|');
    };

    const generatePaymentHistoryItem = (item: StripeTransaction) => {
        let type = '';
        const dateString = formatDate(new Date(item.created * 1000));
        if (item.__typename === 'StripeBalanceTransaction') {
            if (item.amount > 0) {
                if (!item.metadata.transactionUUID && !item.invoice) {
                    type = StripeTransactionTypes.DEBIT;
                } else if (item.invoice) {
                    type = StripeTransactionTypes.SUBSCRIPTION;
                } else {
                    type = StripeTransactionTypes.SESSION;
                }
            } else {
                if (!item.metadata.transactionUUID && !item.metadata.source) {
                    type = StripeTransactionTypes.CREDIT;
                } else if (
                    item.metadata.source &&
                    item.metadata.source.includes('Direct')
                ) {
                    type = StripeTransactionTypes.RELOAD;
                } else if (
                    item.metadata.source &&
                    (item.metadata.source.includes('Balance recovery') ||
                        item.metadata.source === 'Session fee')
                ) {
                    return <></>;
                } else {
                    type = StripeTransactionTypes.AUTO;
                }
            }
        } else if (item.__typename === 'StripePaymentIntent') {
            if (item.invoice) {
                type = StripeTransactionTypes.SUBSCRIPTION;
            }
            // else if(item.metadata && item.metadata.source === 'Reload initiated') {
            //     type = StripeTransactionTypes.RELOAD;
            // }
        } else if (
            item.__typename === 'StripeRefund' &&
            item.reason !== null &&
            item.reason !== 'expired_uncaptured_charge'
        ) {
            type = StripeTransactionTypes.REFUND;
        }
        if (type === '') {
            return <></>;
        }
        let params = `tId=${item.metadata.transactionUUID}&type=${type}&total=${item.amount}&date=${dateString}`;
        if (item.ending_balance != null) {
            params = `${params}&eBalance=${item.ending_balance}`;
        }
        if (item.metadata && item.metadata.baseCost && item.metadata.fee) {
            params = `${params}&base=${item.metadata.baseCost}&fee=${item.metadata.fee}`;
        }
        if (item.metadata && item.metadata.minFee) {
            params = `${params}&minFee=${item.metadata.minFee}`;
        }
        return (
            <button
                className="payment-history__item"
                onClick={() =>
                    navigate(`/paymentDetail?${params}`, {
                        state: { slide: true },
                    })
                }
                id="payment_history_item"
            >
                <div
                    className="payment-history__header"
                    id="payment_history_header"
                >
                    <div
                        className="payment-history__type"
                        id="payment_history_header_type"
                    >
                        {type}{' '}
                        {type === StripeTransactionTypes.SUBSCRIPTION && (
                            <span
                                className="payment-history-subscription"
                                id="payment_history_sub"
                            >
                                Monthly
                            </span>
                        )}
                    </div>
                    {item.amount > 0 && (
                        <div id="payment_history_item_amount">
                            {numberToDollarFormat(
                                item.transactionAmount ||
                                    (item.amount / 100) * -1
                            )}
                        </div>
                    )}
                    {item.amount <= 0 && (
                        <div id="payment_history_item_amount_2">
                            {numberToDollarFormat((item.amount / 100) * -1)}
                        </div>
                    )}
                </div>
                <div className="payment-history__sub" id="payment_history_date">
                    {dateString}
                </div>
            </button>
        );
    };

    return (
        <div
            className={
                history.length > 0 && history[1] === '/payment'
                    ? 'container--navless slide-in-right'
                    : 'container--navless'
            }
        >
            <div className="header">
                <div className="header__content">
                    <div
                        id="payment_history_back"
                        className="header__back"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <BackIcon id="payment_history_back_icon"></BackIcon>
                    </div>
                    PAYMENT HISTORY
                </div>
            </div>
            <div className="content" id="payment_history_content">
                <div className="account-balance-container">
                    <div
                        className="account-balance"
                        id="payment_history_account_balance"
                    >
                        <AccountBalance
                            stroke={colors.white}
                            style={{ marginRight: 5 }}
                        />
                        {accountBalance !== 0
                            ? numberToDollarFormat(accountBalance)
                            : numberToDollarFormat(0)}
                    </div>
                    Account balance
                </div>
                {!stripeData && (
                    <div className="loader-container">
                        <div className="loader" />
                    </div>
                )}
                {stripeData && (
                    <div className="payment-history-transactions">
                        {transactions?.map((item) =>
                            generatePaymentHistoryItem(item)
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default App;
