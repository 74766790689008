import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { ReactComponent as HidePasswordIcon } from '../../assets/icons/hidePassword.svg';
import { ReactComponent as ShowPasswordIcon } from '../../assets/icons/showPassword.svg';
import { sizes } from '../../global/variables';
import {
    selectAccessToken,
    selectHistory,
    setChangePasswordPrompt,
} from '../../appSlice';
import { motion } from 'framer-motion';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';

import { useForm } from 'react-hook-form';
import { store } from '../../app/store';

interface PasswordFormValues {
    oldPass: string;
    newPass: string;
    newPass2: string;
}

const updatePasswordMutation = loader('./changePassword.gql');

const PasswordPage = () => {
    const accessToken = useAppSelector(selectAccessToken);
    const location = useLocation();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPassword2, setShowNewPassword2] = useState(false);
    const history = useAppSelector(selectHistory);

    const navigate = useNavigate();

    const [changePassword, { data, error, loading }] = useMutation(
        updatePasswordMutation
    );

    const onSubmit = async (data: PasswordFormValues) => {
        try {
            const res = await changePassword({
                variables: {
                    input: {
                        newPassword: data.newPass,
                        oldPassword: data.oldPass,
                    },
                },
            });
            if (res && res.data) {
                store.dispatch(setChangePasswordPrompt(true));
                navigate(-1);
            }
        } catch {
            store.dispatch(setChangePasswordPrompt(false));

            // Errors will be caught in the Apollo errors
            navigate(-1);
        }
    };

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors: formErrors },
    } = useForm<PasswordFormValues>({
        defaultValues: {
            newPass: '',
            oldPass: '',
        },
    });

    return (
        <motion.div
            id="password_page"
            className="container"
            initial={
                history.length > 0 && history[1] === '/account'
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="password_page_header">
                <div
                    className="header__back"
                    onClick={() => {
                        navigate(-1);
                    }}
                    id="password_page_back"
                >
                    <BackIcon id="password_page_back_icon"></BackIcon>
                </div>
                <div className="header__content" id="password_page_header">
                    PASSWORD
                </div>
            </div>
            <div className="page">
                <div className="page-header" style={{ marginBottom: 20 }}>
                    Change password
                </div>

                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <input
                            className="input"
                            type={showOldPassword ? 'text' : 'password'}
                            id="oldPass"
                            placeholder="Old password"
                            {...register('oldPass', {
                                required: 'Required',
                            })}
                        />

                        <div
                            className="input__icon"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                            {showOldPassword ? (
                                <HidePasswordIcon
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            ) : (
                                <ShowPasswordIcon
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        {error && (
                            <span className="form__error">{error.message}</span>
                        )}
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <input
                            className="input"
                            type={showNewPassword ? 'text' : 'password'}
                            id="newPass"
                            placeholder="New password"
                            {...register('newPass', {
                                required: 'Required',
                                minLength: {
                                    value: 8,
                                    message: 'Must have minimum 8 characters',
                                },
                                pattern: {
                                    value: /[A-Z]/,
                                    message: 'Must have upper case letter',
                                },
                            })}
                        />
                        <div
                            className="input__icon"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                            {showNewPassword ? (
                                <HidePasswordIcon
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            ) : (
                                <ShowPasswordIcon
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        {formErrors?.newPass?.message && (
                            <span className="form__error">
                                {formErrors.newPass.message}
                            </span>
                        )}
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <input
                            className="input"
                            type={showNewPassword2 ? 'text' : 'password'}
                            id="newPass2"
                            placeholder="Confirm password"
                            {...register('newPass2', {
                                required: 'Required',
                                minLength: {
                                    value: 8,
                                    message: 'Must have minimum 8 characters',
                                },
                                pattern: {
                                    value: /[A-Z]/,
                                    message: 'Must have upper case letter',
                                },
                                validate: (value, values) =>
                                    value === values.newPass ||
                                    "Passwords don't match",
                            })}
                        />
                        <div
                            className="input__icon"
                            onClick={() =>
                                setShowNewPassword2(!showNewPassword2)
                            }
                        >
                            {showNewPassword2 ? (
                                <HidePasswordIcon
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            ) : (
                                <ShowPasswordIcon
                                    width={sizes.medium}
                                    height={sizes.medium}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        {formErrors?.newPass2?.message && (
                            <span className="form__error">
                                {formErrors.newPass2.message}
                            </span>
                        )}
                    </div>
                    <button
                        type="submit"
                        className="button"
                        disabled={loading}
                        id="password_page_button"
                    >
                        Save changes
                    </button>
                </form>
            </div>
        </motion.div>
    );
};

export default PasswordPage;
