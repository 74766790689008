import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AccountPage from '../containers/AccountPage';
import AdminPage from '../containers/AdminPage';
import ChargingHistoryPage from '../containers/ChargingHistoryPage';
import CreateAccountEmailPage from '../containers/CreateAccountEmailPage';
import CreatePasswordPage from '../containers/CreatePasswordPage';
import DeletePage from '../containers/DeletePage';
import DiscountPage from '../containers/DiscountPage';
import ErrorPage from '../containers/ErrorPage';
import ForceUpdatePage from '../containers/ForceUpdatePage';
import ForgotPasswordEmailPage from '../containers/ForgotPasswordEmailPage';
import ForgotPasswordPage from '../containers/ForgotPasswordPage';
import HomePage from '../containers/HomePage';
import LoginPage from '../containers/LoginPage';
import MapPage from '../containers/MapPage';
import PasswordPage from '../containers/PasswordPage';
import PayPage from '../containers/PayPage';
import PaymentDetailPage from '../containers/PaymentDetailPage';
import PaymentHistoryPage from '../containers/PaymentHistoryPage';
import PaymentPage from '../containers/PaymentPage';
import PricingDetailsPage from '../containers/PricingDetailsPage';
import PrivacyPage from '../containers/PrivacyPage';
import ResetPasswordExpiredPage from '../containers/ResetPasswordExpiredPage';
import ResetPasswordPage from '../containers/ResetPasswordPage';
import RfidPage from '../containers/RfidPage';
import ScanPage from '../containers/ScanPage';
import ScanRfidPage from '../containers/ScanRfidPage';
import SessionPage from '../containers/SessionPage';
import SettingsPage from '../containers/SettingsPage';
import SignupPage from '../containers/SignupPage';
import SubscribePage from '../containers/SubscribePage';
import SubscriptionPage from '../containers/SubscriptionPage';
import SupportPage from '../containers/SupportPage';
import ContactPage from '../containers/SupportPage/ContactPage';
import FAQsPage from '../containers/SupportPage/FAQsPage';
import GuestWrapper from './PricingDetails/GuestWrapper';
import { logEvent } from 'firebase/analytics';
import { useAppSelector } from '../app/hooks';
import { store } from '../app/store';
import { selectHistory, setHistory } from '../appSlice';
import { analytics, firebaseSessionId } from '../utils/firebase';
import { jwtDecode, JwtPayload } from 'jwt-decode';

const AnimatedRoutes: React.FC = () => {
    const location = useLocation();
    const history = useAppSelector(selectHistory);
    useEffect(() => {
        const currentHistory = [location.pathname, ...history.slice(0, 9)];
        const currentDate = new Date();
        store.dispatch(setHistory(currentHistory));
        const token = store.getState().app.accessToken;
        try {
            logEvent(analytics, 'MA_4.0_page', {
                history: currentHistory,
                time: currentDate.toLocaleString(),
                sessionId: firebaseSessionId,
                userId: jwtDecode<JwtPayload>(token || '').sub,
            });
        } catch {
            logEvent(analytics, 'MA_4.0_page', {
                history: currentHistory,
                time: currentDate.toLocaleString(),
                sessionId: firebaseSessionId,
            });
        }
    }, [location]);

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route
                    path="/create-account-email"
                    element={<CreateAccountEmailPage />}
                />
                <Route
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                />
                <Route
                    path="/forgot-password-email"
                    element={<ForgotPasswordEmailPage />}
                />
                <Route
                    path="reset-password-expired"
                    element={<ResetPasswordExpiredPage />}
                />
                <Route path="/" element={<HomePage />} />
                <Route path="/session" element={<SessionPage />} />
                <Route path="/force-update" element={<ForceUpdatePage />} />
                <Route path="/pricing" element={<PricingDetailsPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/404" element={<ErrorPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/scan" element={<ScanPage />} />
                <Route path="/map" element={<MapPage />} />
                <Route
                    path="/charginghistory"
                    element={<ChargingHistoryPage />}
                />
                <Route path="/guest" element={<GuestWrapper />}></Route>
                <Route path="/guest/*" element={<GuestWrapper />}></Route>
                <Route path="/confirmUser" element={<CreatePasswordPage />} />

                <Route path="/pay" element={<PayPage />} />
                <Route path="/payment" element={<PaymentPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/privacy/*" element={<PrivacyPage />} />
                <Route path="/support" element={<SupportPage />} />
                <Route path="/support/*" element={<SupportPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/faqs" element={<FAQsPage />} />
                {/* <Route exact path="/register" name="Register Page" element={<Register />} />
    <Route exact path="/404" name="Page 404" element={<Page404 />} />
    <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
                {/* <Route path="*" element={<Layout />} /> */}
                <Route
                    path="/paymentHistory"
                    element={<PaymentHistoryPage />}
                />
                <Route path="/paymentDetail" element={<PaymentDetailPage />} />
                <Route
                    path="/paymentDetail/*"
                    element={<PaymentDetailPage />}
                />
                <Route path="/discount" element={<DiscountPage />} />
                <Route path="/delete" element={<DeletePage />} />
                <Route path="/subscription" element={<SubscriptionPage />} />
                <Route path="/rfid" element={<RfidPage />} />
                <Route path="/password" element={<PasswordPage />} />
                <Route path="/scanrfid" element={<ScanRfidPage />} />
                <Route path="/subscribe" element={<SubscribePage />} />
                <Route path="/subscribe/*" element={<SubscribePage />} />
                {/* Deep Links */}
                <Route
                    path="/email/confirm/:email/:token"
                    element={<CreatePasswordPage />}
                />
                <Route
                    path="/password/lost/:token/:email"
                    element={<ResetPasswordPage />}
                />
                <Route path="/station/:station" element={<ScanPage />} />
                <Route path="/station/:station/*" element={<ScanPage />} />
                <Route path="/quickCharge/:station" element={<ScanPage />} />
                <Route path="/quickCharge/:station/*" element={<ScanPage />} />
                {/* End Deep Links */}
            </Routes>
        </AnimatePresence>
    );
};

export default AnimatedRoutes;
