import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectActiveChargieId,
    selectRequestedChargieId,
    setActiveChargieId,
    setRequestedChargieId,
} from '../../appSlice';
import PrimaryButton from '../../components/PrimaryButton';
import '../../global/fonts/fonts.css';
import { extractStationDetails, StartData } from '../../models/Station';
import router from '../../utils/router';
import { hideScanner, saveData } from '../../utils/webview/messages';
import { SecureStorageKeys } from '../../utils/enums';

const getStationPricing = loader('./getStationPricing.graphql');
const remoteStartMutation = loader('./remoteStart.graphql');

const App = () => {
    const dispatch = useAppDispatch();
    const activeChargieId = useAppSelector(selectActiveChargieId);
    const [stationData, setStationData] = useState<StartData | null>(null);
    const requestedChargieId = useAppSelector(selectRequestedChargieId);

    const [
        remoteStart,
        {
            data: remoteStartData,
            error: remoteStartError,
            loading: remoteStartLoading,
        },
    ] = useMutation(remoteStartMutation);

    const { error, data } = useQuery(getStationPricing, {
        variables: { qrCode: activeChargieId },
    });

    useEffect(() => {
        if (data) {
            const startData = extractStationDetails(data);
            setStationData(startData);
            hideScanner();
        } else if (error) {
            dispatch(setRequestedChargieId(null));
            dispatch(setActiveChargieId(null));
            saveData(SecureStorageKeys.ACTIVE_CHARGIE_ID, '');

            router.navigate('/', { replace: true });
        }
    }, [data, error]);

    const handleSubmit = async () => {
        try {
            await remoteStart({
                variables: {
                    qrCode: activeChargieId,
                    method: requestedChargieId?.startType,
                },
            });
        } catch (e) {
            // alert(e);
        }
    };

    if (stationData) {
        return (
            <div className="container" id="pricing_details_page">
                <div>
                    <div>
                        <div id="pricing_details_page_title">
                            Pricing details
                        </div>
                        <div>{stationData.station.qrCode}</div>
                        <div>
                            $
                            {
                                (
                                    stationData?.station.pricing ||
                                    stationData?.station.inheritedPricingFrom
                                )?.rate
                            }
                            /
                            {
                                (
                                    stationData?.station.pricing ||
                                    stationData?.station.inheritedPricingFrom
                                )?.policy
                            }
                        </div>
                    </div>
                    <PrimaryButton
                        title="Start"
                        onPress={handleSubmit}
                        id="pricing_details_page_button"
                    />
                </div>
            </div>
        );
    }
    return (
        <div className="container">
            <div>
                <div>Pricing details</div>
                <div>{activeChargieId}</div>
                <div>Fetching</div>
            </div>
        </div>
    );
};

export default App;
