import React, { useEffect, useState } from 'react';
import { store } from '../../app/store';
import {
    hidePaymentModal,
    logToNative,
    setSignupSubscription,
} from '../../utils/webview/messages';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import RadioButtons, { RadioOptions } from '../../components/RadioButtons';
import { useAppSelector } from '../../app/hooks';
import { selectAccessToken, setSubscriptionStarted } from '../../appSlice';

const GuestPricing: React.FC = () => {
    const accessToken = useAppSelector(selectAccessToken);
    const [buttonText, setButtonText] = useState('Sign up');
    const [title, setTitle] = useState('Sign up to start charging');
    const [description, setDescription] = useState(
        'An account and monthly subscription are required at this location.'
    );

    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (accessToken) {
            navigate('/subscribe/pay');
        } else {
            store.dispatch(setSubscriptionStarted(true));
            setSignupSubscription(true);
            navigate('/signup');
        }
    };

    const [selectedOption, setSelectedOption] = useState<string>('chargiePro');
    const options: RadioOptions[] = [
        {
            label: 'Chargie Pro',
            labelSub: '$15.00',
            value: 'chargiePro',
            subText: 'Billed monthly. Cancel anytime.',
        },
    ];

    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
    };

    useEffect(() => {
        hidePaymentModal();
        if (accessToken) {
            setTitle('Subscription access required');
            setDescription(
                'A monthly subscription is required to access stations at this location.'
            );
        }
    }, [accessToken]);

    return (
        <div className="guest-pricing-content" id="subscribe_start_page">
            <div className="payment__content--right">
                <div
                    className="large black bold"
                    style={{ marginBottom: 20 }}
                    id="subscribe_start_page_title"
                >
                    {title}
                </div>
                <div
                    className="black"
                    style={{ marginBottom: 40 }}
                    id="subscribe_start_page_description"
                >
                    {description}
                </div>

                <RadioButtons
                    options={options}
                    selectedValue={selectedOption}
                    onChange={handleOptionChange}
                    inverse={true}
                />
            </div>
            <div className="payment__button-container">
                <PrimaryButton
                    title={buttonText}
                    onPress={handleSubmit}
                    id="subscribe_start_page_submit"
                />
                <button
                    id="subscribe_start_page_back_button"
                    className="payment__back-button hidden"
                    onClick={() => navigate('/', { replace: true })}
                >
                    Back
                </button>
            </div>
        </div>
    );
};

export default GuestPricing;
